import { Box, Button, Fade, Spacer, Text } from '@chakra-ui/react';
import { faArrowLeft, faArrowRight, faExpand, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { FocusQuestion } from '@sparx/api/apis/sparx/science/packages/v1/insights';
import {
  ISteps,
  newInput,
  rehydrateStepAnswer,
  SparxQuestion,
  useLayoutSteps,
} from '@sparx/question';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSparxQuestionColours } from 'views/task/ActivityDelivery';

const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: HTMLDivElement) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

const defaultScale = 2;

interface FocusSkillContainerProps {
  question: FocusQuestion | undefined;
  topic?: Topic;
  index: number;
  showAnswers?: boolean;
  onToggleAnswer: () => void;
  onCloseOverlay?: () => void;
  onOpenLarge?: () => void;
  nextQuestion?: () => void;
  prevQuestion?: () => void;
}

export const FocusSkillContainer = ({
  question,
  topic,
  index,
  showAnswers,
  onToggleAnswer,
  onCloseOverlay,
  onOpenLarge,
  nextQuestion,
  prevQuestion,
}: FocusSkillContainerProps) => {
  const { sendEvent } = useClientEvent();
  const ref = useRef<HTMLDivElement>(null);
  const colours = useSparxQuestionColours();
  const isLarge = onCloseOverlay !== undefined;

  const [scale, setScale] = useState(defaultScale);
  const [settled, setSettled] = useState(false);
  useEffect(() => {
    const callback = () => {
      if (ref.current) {
        if (isOverflown(ref.current) && scale > 0.75) {
          setScale(scale - 0.1);
          setSettled(false);
        } else {
          setSettled(true);
        }
      }
    };
    const timeout = setTimeout(callback, 20);
    return () => clearTimeout(timeout);
  }, [ref.current, settled, scale, setScale, setSettled]);

  // Check when the user resizes the window
  useEffect(() => {
    const callback = debounce(() => {
      setSettled(false);
      setScale(defaultScale);
    }, 100);
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [setScale, setSettled]);

  const steps = useLayoutSteps(question?.layoutJson);
  const input = useMemo(() => {
    try {
      // Parse the steps again here to get a fresh copy of the input
      const steps = JSON.parse(question?.layoutJson || '{}') as ISteps;
      if (showAnswers) rehydrateStepAnswer(steps[0].input, question?.correctAnswer || {});
      return steps[0].input;
    } catch (e) {
      console.error('Failed to parse question layout', question);
      return newInput();
    }
  }, [steps, question, showAnswers]);

  return (
    <>
      <Box bg="blue.800" px={2} py={2} fontWeight="bold" display="flex" alignItems="center">
        <Text color="white" pl={2}>
          {index}. {topic?.displayName || '?'}
        </Text>
        <Spacer />
        {onCloseOverlay && (
          <Button
            onClick={onCloseOverlay}
            my={-1}
            rightIcon={<FontAwesomeIcon icon={faTimes} />}
            size="sm"
          >
            Close
          </Button>
        )}
      </Box>
      <Box
        bg="white"
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={isLarge ? 8 : 5}
        ref={ref}
        overflowX="auto"
        overflowY={settled ? 'auto' : 'hidden'}
        position="relative"
        zIndex={4}
      >
        <FixedCenterLoading active={!settled} />
        <Box opacity={settled ? 1 : 0} margin="auto">
          {steps.length > 0 && steps[0] ? (
            <SparxQuestion
              font={{ fontSize: scale * 17 }}
              layout={steps[0].layout}
              input={input}
              setInput={() => undefined}
              colours={colours}
              readOnly={true}
              insightsMode="presentation"
              gapEvaluations={showAnswers ? question?.correctAnswerGapEvaluations : undefined}
              shuffleSeed="noshuffle"
              getUploadedAsset={uploadedAssetProvider}
              getAssetUrl={getAssetUrl}
              sendAnalyticEvent={(action, labels) =>
                sendEvent({ category: 'question', action }, labels)
              }
            />
          ) : (
            <>Error loading question</>
          )}
        </Box>
      </Box>
      <Box
        p={isLarge ? 2 : 1}
        borderTopWidth="1px"
        borderTopColor="gray.200"
        bg="white"
        display="flex"
        justifyContent="space-between"
      >
        {!onOpenLarge && prevQuestion && (
          <Button
            size={isLarge ? 'md' : 'sm'}
            onClick={prevQuestion}
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          >
            Previous question
          </Button>
        )}
        <Button size={isLarge ? 'md' : 'sm'} onClick={onToggleAnswer}>
          {showAnswers ? 'Hide' : 'Show'} answer
        </Button>
        {onOpenLarge && (
          <Button size="sm" onClick={onOpenLarge} leftIcon={<FontAwesomeIcon icon={faExpand} />}>
            Expand question
          </Button>
        )}
        {!onOpenLarge && nextQuestion && (
          <Button
            size={isLarge ? 'md' : 'sm'}
            onClick={nextQuestion}
            rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
          >
            Next question
          </Button>
        )}
      </Box>
    </>
  );
};

const FixedCenterLoading = ({ active }: { active?: boolean }) => (
  <Fade in={active} unmountOnExit={true}>
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="white"
    >
      <LargeLoading />
    </Box>
  </Fade>
);
