import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Spacer,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Evaluation,
  GetPackageAnswerHistoryResponse_ActivityWithAnswer,
} from '@sparx/api/apis/sparx/science/packages/v1/activity';
import { TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import * as annotations from '@sparx/packageactivity/src/annotations';
import {
  hasInput,
  ISteps,
  QuestionMode,
  rehydrateStepAnswer,
  SparxQuestion,
} from '@sparx/question';
import { useClientEvent } from 'components/ClientEventProvider';
import { MultiStepWrapper } from 'components/multistep/MultiStepWrapper';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import { format, formatDistanceToNow } from 'date-fns';
import deepcopy from 'deepcopy';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { getQuestionMarkingMode } from 'utils/question';
import { useSparxQuestionColours } from 'views/task/ActivityDelivery';
import { SupportMaterial } from 'views/task/SupportMaterial';

import { useTaskItemPanelContext } from './TaskItemPanelContext';

interface ActivityResultProps {
  activityWithAnswer: GetPackageAnswerHistoryResponse_ActivityWithAnswer;
  index: number;
  first?: boolean;
  mode?: QuestionMode;
  header?: React.ReactNode;
}

export const ActivityResult = ({
  activityWithAnswer,
  index,
  first,
  mode,
  header,
  children,
}: PropsWithChildren<ActivityResultProps>) => {
  const { showCorrect } = useTaskItemPanelContext();

  const activity = activityWithAnswer.activity!;
  const skillActivity = activity.state?.skillActivity;

  const isTalkAndLearn = activity.annotations['tal'] === 'is';

  let color = 'gray.400';
  let icon = faSpinner;
  switch (skillActivity?.evaluation?.status) {
    case TaskItem_Status.CORRECT:
    case TaskItem_Status.PENDING_CORRECT:
      color = 'green.400';
      icon = faCheck;
      break;
    case TaskItem_Status.SKIPPED:
    case TaskItem_Status.INCORRECT:
      color = 'red.400';
      icon = faTimes;
      break;
  }

  const timeTaken = Math.round(
    activityWithAnswer.questionTimeSeconds + activityWithAnswer.supportTimeSeconds,
  );
  const minutes = Math.floor(timeTaken / 60);
  const timeTakenString =
    minutes > 0
      ? `${minutes} minute${minutes > 1 ? 's' : ''}`
      : `${timeTaken} second${timeTaken > 1 ? 's' : ''}`;

  const activityEnded = Boolean(activity.endTimestamp);

  const actAnns = activityWithAnswer.activity?.annotations || {};

  const attemptText = annotations.isSupport(actAnns)
    ? annotations.isMultiStep(actAnns)
      ? `Follow up`
      : `Follow up - Attempt ${index - 1}`
    : annotations.hasSupport(actAnns)
      ? annotations.isSecondChance(actAnns)
        ? `Second chance`
        : 'Original question'
      : `Attempt ${index}`;

  return (
    <Box
      bg="white"
      borderRadius="4px"
      overflow="hidden"
      marginTop={first ? 0 : '3px'}
      flex={index === 1 ? 1 : 0}
    >
      <Box position="sticky" top={0} zIndex={1} marginBottom="-1px">
        <Flex bg="gray.100" py={2} px={4} alignItems="center">
          {header || (
            <>
              <Text
                bg={color}
                color="white"
                fontWeight="bold"
                borderRadius="full"
                px={3}
                py={1}
                transition="background 0.5s ease-in-out"
              >
                {attemptText}
                {activityEnded && (
                  <Text as="span" ml={3}>
                    <FontAwesomeIcon icon={icon} />
                  </Text>
                )}
              </Text>
              <Spacer />
              {activity.endTimestamp && (
                <Text
                  mr={1}
                  color="gray.600"
                  title={format(Timestamp.toDate(activity.endTimestamp), 'PPPppp')}
                >
                  {formatDistanceToNow(Timestamp.toDate(activity.endTimestamp), {
                    addSuffix: true,
                  })}
                </Text>
              )}
            </>
          )}
        </Flex>
        {activityEnded && (
          <Flex
            borderColor="gray.200"
            borderTopWidth={1}
            borderBottomWidth={1}
            py={1}
            px={5}
            fontSize="sm"
            alignItems="center"
            bg={showCorrect ? 'green.50' : 'white'}
          >
            <Text mr={2} color="gray.600" fontSize="lg" lineHeight="1em">
              <FontAwesomeIcon icon={faClock} />
            </Text>
            <Text fontWeight="bold">{timeTakenString}</Text>
          </Flex>
        )}
      </Box>
      {children}
      <Box px={5} py={4}>
        <StepView activityWithAnswer={activityWithAnswer} showCorrect={showCorrect} mode={mode} />
        {activity.state?.skillActivity?.evaluation?.status === TaskItem_Status.SKIPPED && (
          <Tooltip label={`Student clicked "I don't know"`}>
            <Box
              borderRadius="md"
              mt={3}
              px={4}
              py={2}
              borderWidth={2}
              borderColor="red.500"
              fontWeight="bold"
              color="red.600"
              display="inline-block"
              cursor="help"
            >
              I don&apos;t know
            </Box>
          </Tooltip>
        )}
      </Box>
      {isTalkAndLearn && skillActivity?.question?.supportMaterial && (
        <AccordionItem borderTopColor="gray.200" borderTopWidth={1}>
          <AccordionButton
            as="div"
            cursor="pointer"
            color="orange.500"
            bg="orange.100"
            py={2}
            px={5}
          >
            <Box as="span" flex="1" textAlign="left">
              Let&apos;s learn this
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel px={2} py={2} bg="orange.100">
            <Box
              borderColor="orange.200"
              borderWidth={2}
              px={4}
              py={3}
              borderRadius="md"
              bg="white"
            >
              <SupportMaterial
                material={skillActivity.question.supportMaterial}
                fontSize={16}
                inline={true}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      )}
    </Box>
  );
};

export const Unattempted = () => (
  <Box bg="white" borderRadius="4px" flex={1}>
    <Flex
      bg="gray.50"
      py={2}
      px={4}
      borderColor="gray.200"
      borderBottomWidth={1}
      alignItems="center"
    >
      <Text bg="gray.400" color="white" fontWeight="bold" borderRadius="full" px={3} py={1}>
        Unattempted
      </Text>
    </Flex>
    <Box px={5} py={4}>
      <Text color="gray.500">This question has not been attempted yet.</Text>
    </Box>
  </Box>
);

interface StepViewProps {
  activityWithAnswer: GetPackageAnswerHistoryResponse_ActivityWithAnswer;
  showCorrect: boolean;
  mode?: QuestionMode;
}

const StepView = ({ activityWithAnswer, showCorrect, mode }: StepViewProps) => {
  const { sendEvent } = useClientEvent();
  const colours = useSparxQuestionColours();

  const [stepAttempts, setStepAttempts] = useState<Dictionary<number, number>>({});
  const setStepAttempt = (step: number, attempt: number) =>
    setStepAttempts({ ...stepAttempts, [step]: attempt });
  const getStepAttempt = (step: number) => stepAttempts[step] || 0;

  const activity = activityWithAnswer.activity!;
  const isMultiStep = annotations.isMultiStep(activity.annotations);
  const skillActivity = activity.state?.skillActivity;
  const steps = useMemo(() => {
    const questionJSON = skillActivity?.question?.questionJson;
    try {
      const steps = JSON.parse(questionJSON || '') as ISteps;

      return steps
        .map((step, i) => {
          const stepEvaluations = skillActivity?.stepEvaluations[i]?.evaluations;
          const attempts = isMultiStep ? stepEvaluations || [] : [skillActivity?.evaluation];

          const correctAnswer: Evaluation = {
            status: TaskItem_Status.CORRECT,
            submittedAnswer: activityWithAnswer.correctAnswer,
            gapEvaluations: activityWithAnswer.correctAnswerGapEvaluations,
            completed: true,
            marks: 1,
          };

          return { ...step, attempts, correctAnswer, index: i };
        })
        .reverse();
    } catch (e) {
      console.error('Error parsing question JSON', questionJSON, e);
      return undefined;
    }
  }, [activityWithAnswer, isMultiStep, skillActivity]);

  return (
    <>
      {steps?.map(step => {
        const input = deepcopy(step.input);

        const stepAttempt = getStepAttempt(step.index);
        const answer = showCorrect ? step.correctAnswer : step.attempts[stepAttempt];
        const questionMarkingMode = getQuestionMarkingMode(activity, answer);

        const gapEvaluation = answer?.gapEvaluations;
        if (answer?.submittedAnswer) {
          rehydrateStepAnswer(input, answer?.submittedAnswer);
        }

        const showAttemptNavigator = hasInput(input);

        const attemptNavigator = showAttemptNavigator ? (
          <ButtonGroup
            size="xs"
            isAttached
            variant="outline"
            ml={3}
            visibility={showCorrect ? 'hidden' : undefined}
          >
            <IconButton
              bg="white"
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              aria-label="Previous"
              isDisabled={stepAttempt >= step.attempts.length - 1}
              onClick={() => setStepAttempt(step.index, stepAttempt + 1)}
            />
            <Button pointerEvents="none" w={12} bg="gray.50">
              {step.attempts.length - stepAttempt} / {step.attempts.length}
            </Button>
            <IconButton
              bg="white"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              aria-label="Next"
              isDisabled={stepAttempt <= 0}
              onClick={() => setStepAttempt(step.index, stepAttempt - 1)}
            />
          </ButtonGroup>
        ) : undefined;

        const stepLayout = (
          <SparxQuestion
            key={stepAttempt}
            layout={step.layout}
            input={input}
            gapEvaluations={gapEvaluation}
            setInput={() => {
              /* empty */
            }}
            readOnly={true}
            colours={colours}
            font={{ fontSize: 16 }}
            /* The same seed is used in the student view also */
            shuffleSeed={
              annotations.isSecondChance(activity.annotations)
                ? activity.state?.previousActivity?.name || activity.name
                : activity.name
            }
            getUploadedAsset={uploadedAssetProvider}
            getAssetUrl={getAssetUrl}
            mode={mode}
            sendAnalyticEvent={(action, labels) =>
              sendEvent({ category: 'question', action }, labels)
            }
            questionMarkingMode={questionMarkingMode}
          />
        );

        return (
          <React.Fragment key={step.index}>
            {isMultiStep ? (
              <Flex mx={-2}>
                <MultiStepWrapper
                  correct={answer?.status === TaskItem_Status.CORRECT}
                  index={step.index}
                  maxStep={skillActivity?.question?.steps?.maxStep || 0}
                  stepToDisplayNum={skillActivity?.question?.steps?.stepDisplayNumberMap}
                  compact={true}
                  solidLine={step.index > 0}
                  sideComponent={
                    <>
                      {step.attempts.length > 0 ? (
                        attemptNavigator
                      ) : (
                        <Text fontSize="xs" color="gray.400" ml={3} fontWeight="bold">
                          Unattempted
                        </Text>
                      )}
                    </>
                  }
                >
                  {stepLayout}
                </MultiStepWrapper>
              </Flex>
            ) : (
              stepLayout
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
