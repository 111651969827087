import { Task } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { useMemo } from 'react';

export interface FlashCardCounts {
  total: number;
  newCards: number;
  reviewCards: number;
  learnt: number;
  learning: number;
}

export const useFlashCardCounts = (task?: Task): FlashCardCounts | null =>
  useMemo(() => {
    const contents = task?.contents?.taskItems?.[0].contents?.contents;
    if (!contents || contents.oneofKind !== 'rapidFire') {
      return null;
    }
    const total = contents.rapidFire.size;
    const newCards = contents.rapidFire.newCards;

    const correct = task.state?.completion?.progress?.['C'] || 0;
    const cft = task.state?.completion?.progress?.['CFT'] || 0;

    return { total, newCards, reviewCards: total - newCards, learnt: cft, learning: correct - cft };
  }, [task]);
