import { captureMessage } from '@sentry/react';
import {
  RolloverState_ProductRolloverState_RolloverType,
  SchoolCalendar,
  SchoolYear,
} from '@sparx/api/apis/sparx/school/calendar/v4/calendar';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Date as PbDate } from '@sparx/api/google/type/date';
import { FetchQueryOptions, QueryOptions, useQuery } from '@tanstack/react-query';
import { schoolCalendarClient } from 'api';
import { queryClient } from 'api/client';
import { getSchoolID } from 'api/sessions';

export const SchoolCalendarQueryKey = 'GetSchoolCalendar';

export const schoolCalendarQuery: FetchQueryOptions<SchoolCalendar> = {
  queryKey: [SchoolCalendarQueryKey],
  queryFn: async () => {
    return schoolCalendarClient.getSchoolCalendar({
      name: `schools/${await getSchoolID()}/calendar`,
    }).response;
  },
  cacheTime: 900000, // 15 minutes
  staleTime: 300000, // 5 minutes
};

export const useSchoolCalendar = <TData = SchoolCalendar>(opts?: {
  enabled?: boolean;
  suspense?: boolean;
  select?: (data: SchoolCalendar) => TData;
}) => {
  return useQuery({
    ...schoolCalendarQuery,
    ...opts,
  });
};

export const useCurrentSchoolYear = (opts?: { enabled?: boolean; suspense?: boolean }) =>
  useQuery({ ...schoolCalendarQuery, ...opts, select: calendar => calendar.currentYear });

export const useIsInRolloverInterim = () => {
  const { data: schoolYear } = useCurrentSchoolYear({ suspense: true });
  return scienceIsInInterim(schoolYear);
};

const scienceIsInInterim = (schoolYear?: SchoolYear) => {
  if (!schoolYear?.rolloverState) {
    return false;
  }
  const hasRolledOver = Boolean(schoolYear.rolloverState.schoolRolloverTime);
  const { scienceRolloverState } = schoolYear.rolloverState;

  if (scienceRolloverState && scienceRolloverState.product !== Product.SPARX_SCIENCE) {
    // Bad data
    captureMessage(
      `Rollover state product mismatch for ${schoolYear.name}: product specified for scienceRolloverState is ${scienceRolloverState.product}`,
    );
    return false;
  }

  const hadScience =
    scienceRolloverState &&
    scienceRolloverState.rolloverType !==
      RolloverState_ProductRolloverState_RolloverType.ROLLOVER_TYPE_NONE;
  const isComplete = scienceRolloverState?.rolloverCompleteTime !== undefined;

  // Sparx Science is in the interim state if the rollover has occurred, Sparx Science was involved
  // in the rollover and the rollover is not yet complete
  return Boolean(hasRolledOver && hadScience && !isComplete);
};

interface YearDates {
  start: Date;
  end: Date;
  isPrevious: boolean;
}

const schoolYearDates = (
  year: SchoolYear | undefined,
  previous: boolean,
): YearDates | undefined => {
  if (!year) {
    return undefined;
  }
  if (!year.startDate || !year.endDate) {
    return undefined;
  }
  return {
    ...year,
    start: PbDate.toJsDate(year.startDate),
    end: PbDate.toJsDate(year.endDate),
    isPrevious: previous,
  };
};

// fetchInterimAwareYear is only interim aware if the calendar service is enabled
// massaged to not be a hook to be usable in queryFn
export const fetchInterimAwareYear = async (): Promise<YearDates | undefined> => {
  const calendar = await queryClient.fetchQuery(schoolCalendarQuery);
  const isInterim = scienceIsInInterim(calendar.currentYear);
  const previousYear = calendar.previousYears.slice(-1).pop();
  if (isInterim) {
    return schoolYearDates(previousYear, true);
  }
  return schoolYearDates(calendar?.currentYear, false);
};

export const useMostRecentRolloverWasManual = () => {
  const isInRolloverInterim = useIsInRolloverInterim();
  const { data: schoolYear } = useCurrentSchoolYear({ suspense: true });

  const isManual =
    schoolYear?.rolloverState?.scienceRolloverState?.rolloverType ===
    RolloverState_ProductRolloverState_RolloverType.PARTIAL;

  return isInRolloverInterim || isManual;
};

export const useInterimAwareSchoolYear = (opts?: QueryOptions<YearDates | undefined>) =>
  useQuery({
    queryFn: fetchInterimAwareYear,
    queryKey: ['interimAwareSchoolYear'],
    suspense: true,
    ...opts,
  });
