import { RpcError } from '@protobuf-ts/runtime-rpc';
import * as Sentry from '@sentry/react';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { getSchool } from '@sparx/query/schools-service';
import { MutationCache, QueryCache, QueryClient, useQuery } from '@tanstack/react-query';
import { isAnonymousMode } from 'utils/anonymous';
import { uuidRe } from 'utils/uuid';

const isOutOfDateError = (error: unknown) =>
  error instanceof RpcError &&
  error.code === 'FAILED_PRECONDITION' &&
  error.message === 'client out of date';

const clientOutOfDateHandler = (error: unknown) => {
  if (isOutOfDateError(error)) {
    console.log('Received client out of date error');
    outOfDateErrorCallback();
  }
};

const defaultOutOfDateCallback = () => {
  console.error('Received out of date error, but handler is not set');
};
let outOfDateErrorCallback = defaultOutOfDateCallback;
// function to change the out of date error callback
export const setOutOfDateErrorCallback = (handler?: () => void) => {
  if (handler) {
    outOfDateErrorCallback = handler;
  } else {
    outOfDateErrorCallback = defaultOutOfDateCallback;
  }
};

// Replace UUIDs and numbers in a fingerprint with placeholders so that we group similar errors
const normaliseFingerprintString = (fingerprint: string) =>
  fingerprint.replace(uuidRe, '<UUID>').replace(/[0-9]+/g, '<NUM>');
const normaliseFingerprint = (fingerprint: string[]) => fingerprint.map(normaliseFingerprintString);

// React Query client with Sentry error capture
// https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      Sentry.withScope(scope => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint(normaliseFingerprint([query.queryHash]));
        Sentry.captureException(error);
      });
      // Handle out of date error
      clientOutOfDateHandler(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _vars, _ctx, mutation) => {
      Sentry.withScope(scope => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            normaliseFingerprint(
              mutation.options.mutationKey.filter(segment => typeof segment === 'string'),
            ),
          );
        }
        Sentry.captureException(err);
      });
      // Handle out of date error
      clientOutOfDateHandler(err);
    },
  }),
  defaultOptions: {
    queries: {
      // Default retry handler for queries which will not retry if there
      // is a NOT_FOUND or INVALID_ARGUMENT error returned.
      retry: (retry, error) => {
        if (error instanceof RpcError) {
          if (error.code === 'NOT_FOUND' || error.code === 'INVALID_ARGUMENT') {
            return false; // don't retry not found or invalid argument
          }
          if (isOutOfDateError(error)) {
            return false; // don't retry when the client is out of date
          }
        }
        return retry < 3; // allow 3 retries
      },
    },
  },
});

const anonymiseSchool = (school: School): School => {
  school.displayName = 'Demo School';
  return school;
};

queryClient.setQueryDefaults(getSchool.keyPrefix, {
  cacheTime: Infinity,
  staleTime: Infinity,
  select: isAnonymousMode() ? anonymiseSchool : undefined,
});

/**
 * Small utility that acts like useState but stores the value in the QueryClient
 * so that it will persist across page navigations. The values are cached indefinitely.
 * @param key The key to store the value under
 * @param defaultValue The default value to use if the key is not found
 */
export const useQueryState = <T>(key: string, defaultValue: T): [T, (v: T) => void] => {
  const { data } = useQuery({
    queryKey: ['_state', key],
    queryFn: () => defaultValue,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  return [data ?? defaultValue, (value: T) => queryClient.setQueryData(['_state', key], value)];
};

export const setQueryStateValue = (key: string, value: unknown) =>
  queryClient.setQueryData(['_state', key], value);
