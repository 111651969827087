import {
  Box,
  chakra,
  Flex,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { usePageContainerContext } from 'components/PageContainer';
import debounce from 'lodash.debounce';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export interface DataTableProps<Data> extends TableProps {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  defaultSort?: SortingState;
  onRowClick?: (row: Data) => void;
  getRowId?: (row: Data) => string;
  rowIsHighlighted?: (row: Data) => string | undefined;
  noDataRow?: React.ReactNode;
  compact?: boolean;
  // Extra component to add at the top of the table, it will be sticky which and the height is needed to make the actual header sticky.
  extraHeader?: React.ReactNode;
  extraHeaderHeight?: number;
}

interface DataTableColumnMeta<Data extends object> {
  align?: 'left' | 'right' | 'center';
  isNumeric?: boolean;
  isElement?: boolean;
  blockClick?: boolean;
  blockCellClick?: boolean;
  linkTo?: (row: Data) => string | { to: string; options: NavigateOptions } | undefined;
  width?: string | number;
  headerCellProps?: TableColumnHeaderProps;
  bodyCellProps?: TableCellProps;
}

export function DataTable<Data extends object>({
  data,
  columns,
  defaultSort = [],
  getRowId,
  onRowClick,
  rowIsHighlighted,
  noDataRow,
  compact,
  extraHeaderHeight,
  extraHeader,
  className,
  ...tableProps
}: DataTableProps<Data>) {
  const navigate = useNavigate();
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort);
  const table = useReactTable({
    columns,
    data,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: sort => setSorting(sort.length === 0 ? defaultSort : sort),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  const [isSticky, setIsSticky] = React.useState(false);
  if (extraHeaderHeight !== undefined) {
    // increase the header height to account for extra padding we add.
    extraHeaderHeight += 12;
  }

  const tableRef = useRef<HTMLTableElement | null>(null);
  const pageContext = usePageContainerContext();
  useEffect(() => {
    if (pageContext.scrollContainer) {
      const callback = debounce(() => {
        const container = pageContext.scrollContainer?.getBoundingClientRect();
        const table = tableRef.current?.getBoundingClientRect();
        setIsSticky(
          Boolean(container && table && container.top + (extraHeaderHeight || 0) > table.top),
        );
      }, 10);
      pageContext.scrollContainer.addEventListener('scroll', callback);
      return () => pageContext.scrollContainer?.removeEventListener('scroll', callback);
    }
  }, [tableRef, pageContext.scrollContainer, extraHeaderHeight]);

  const roundCorners =
    !pageContext?.isSubContainer && (!isSticky || extraHeaderHeight !== undefined);

  // If we have multiple headers we need to make them all sticky and offset second row by the height of the first row.
  // (This is only supporting up to 2 header rows)
  // The follow handles measuring the height of the first row, as well things which need to be tracked to render the rows.
  const primaryHeaderRef = useRef<HTMLTableCellElement>(null);
  const [primaryHeaderHeight, setPrimaryHeaderHeight] = React.useState(0);
  const drawnColumns: string[] = [];
  let hasSetPrimaryHeaderRef = false;
  useEffect(() => {
    if (table.getHeaderGroups().length > 1 && primaryHeaderRef.current) {
      const measure = () => {
        primaryHeaderRef.current &&
          setPrimaryHeaderHeight(primaryHeaderRef.current.getBoundingClientRect().height);
      };
      measure();
      window.addEventListener('resize', measure);
      return () => window.removeEventListener('resize', measure);
    }
  }, [primaryHeaderRef, table]);

  return (
    <Table
      ref={tableRef}
      backgroundColor="white"
      boxShadow="elevationLow"
      borderRadius="md"
      className={className}
      style={{
        borderCollapse: 'separate',
        borderSpacing: 0,
      }}
      {...tableProps}
    >
      <Thead>
        {extraHeader && (
          <Tr>
            <Td
              colSpan={columns.length}
              p={0}
              overflow="visible"
              position="sticky"
              top={0}
              zIndex={10}
            >
              <Box bg="pageBackground" mx={-2} px={2} pt={3} pb={2} zIndex={10}>
                {extraHeader}
              </Box>
            </Td>
          </Tr>
        )}
        {table.getHeaderGroups().map((headerGroup, hgIdx) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header, i) => {
              // When there are multiple header groups react-tables will add
              // placeholder headers to each group as is expects us to render an empty cell for them
              // instead we want to have headers with no sub headers span multiple rows,
              // so we have to handle things a bit differently.
              // We keep track of which columns we have rendered and set the
              // header rowspan appropiately on the first version of each header.
              // Then some of the styling is adjusted for rows which are subHeaders or have
              // subheaders, inclusing adjusting the top offset for stickiness.
              // We only support 2 haeder rows.
              if (drawnColumns.includes(header.column.id)) {
                return null;
              }
              let rowSpan = 1;
              if (header.isPlaceholder) {
                rowSpan = 2;
              }
              drawnColumns.push(header.column.id);

              const hasSubheaders =
                header.subHeaders.length > 0 && header.subHeaders[0].column.id !== header.column.id;
              const isSubHeader = header.depth > 1;

              // when measuring the height of the first row we only need to measure the first cell that doesn't span multiple rows.
              let theRef: React.RefObject<HTMLTableCellElement> | undefined;
              if (hasSubheaders && !hasSetPrimaryHeaderRef) {
                theRef = primaryHeaderRef;
                hasSetPrimaryHeaderRef = true;
              }

              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: DataTableColumnMeta<Data> | undefined = header.column.columnDef.meta;

              const ContentWrapper =
                typeof header.column.columnDef.header === 'string' ? Text : Box;

              return (
                <Th
                  ref={theRef}
                  key={header.id}
                  onClick={
                    header.column.getCanSort() && !meta?.blockClick
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                  isNumeric={meta?.isNumeric}
                  textTransform="none"
                  fontSize={isSubHeader ? 'xs' : 'sm'}
                  backgroundColor="blue.800"
                  color="white"
                  _hover={
                    header.column.getCanSort()
                      ? {
                          background: 'blue.900',
                          cursor: 'pointer',
                        }
                      : undefined
                  }
                  position="sticky"
                  borderTopLeftRadius={roundCorners && i === 0 ? 'md' : undefined}
                  borderTopRightRadius={
                    roundCorners && hgIdx == 0 && i === headerGroup.headers.length - 1
                      ? 'md'
                      : undefined
                  }
                  borderBottom={hasSubheaders ? 'none' : undefined}
                  textAlign={meta?.align || 'left'}
                  pl={i === 0 ? 4 : 3}
                  pr={3}
                  top={
                    isSubHeader
                      ? `${(extraHeaderHeight || 0) + primaryHeaderHeight}px`
                      : extraHeaderHeight !== undefined
                        ? `${extraHeaderHeight}px`
                        : 0
                  }
                  pt={isSubHeader ? 2 : compact ? 3 : 4}
                  pb={hasSubheaders ? 0 : compact ? 3 : 4}
                  zIndex={10}
                  colSpan={header.colSpan}
                  rowSpan={rowSpan}
                  borderLeftColor="gray.500"
                  borderRightColor="gray.500"
                  {...meta?.headerCellProps}
                  style={{ width: meta?.width }}
                >
                  <Flex align="center">
                    <Box flex={1}>
                      <ContentWrapper flex={1}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </ContentWrapper>
                    </Box>
                    {header.column.getCanSort() && (
                      <chakra.span pl="3" opacity={!header.column.getIsSorted() ? 0.2 : 1}>
                        {header.column.getIsSorted() === 'desc' ? (
                          <FontAwesomeIcon icon={faCaretDown} aria-label="sorted descending" />
                        ) : (
                          <FontAwesomeIcon icon={faCaretUp} aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    )}
                  </Flex>
                  {isSticky && !hasSubheaders && (
                    <HeaderGradient headerProps={meta?.headerCellProps} />
                  )}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map(row => (
          <Tr
            key={row.id}
            onClick={() => onRowClick?.(row.original)}
            _hover={
              onRowClick
                ? {
                    cursor: 'pointer',
                    background: 'gray.50',
                  }
                : {}
            }
            transition="background-color 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)"
            bg={rowIsHighlighted?.(row.original) || 'white'}
          >
            {row.getVisibleCells().map((cell, i) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: DataTableColumnMeta<Data> | undefined = cell.column.columnDef.meta;

              const onClick: React.MouseEventHandler | undefined =
                meta?.blockClick || meta?.blockCellClick
                  ? e => e.stopPropagation()
                  : meta?.linkTo
                    ? e => {
                        if (meta?.linkTo) {
                          e.stopPropagation();
                          const link = meta.linkTo(row.original);
                          if (!link) return;
                          if (typeof link === 'string') {
                            navigate(link);
                          } else {
                            navigate(link.to, link.options);
                          }
                        }
                      }
                    : undefined;

              const linkProps = meta?.linkTo?.(row.original)
                ? {
                    cursor: 'pointer',
                    _hover: {
                      bg: 'gray.50',
                    },
                  }
                : {};

              return (
                <Td
                  key={cell.id}
                  lineHeight={meta?.isElement ? '0px' : undefined}
                  onClick={onClick}
                  isNumeric={meta?.isNumeric}
                  py={compact ? 2 : 3}
                  textAlign={meta?.align || 'left'}
                  pl={i === 0 ? 4 : 3}
                  pr={3}
                  transition="background-color 0.75s cubic-bezier(0.645, 0.045, 0.355, 1)"
                  {...linkProps}
                  {...meta?.bodyCellProps}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
        {noDataRow && table.getRowModel().rows.length === 0 && (
          <Tr>
            <Td colSpan={table.getAllColumns().length} textAlign="center" color="gray.500">
              {noDataRow}
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
}

export const HeaderGradient = ({
  side = 'top',
  headerProps,
}: {
  side?: 'top' | 'bottom';
  headerProps?: TableColumnHeaderProps;
}) => {
  // // If there is a border then we want to stretch the gradient over it
  const leftBorder =
    typeof headerProps?.borderLeftWidth === 'number' ? headerProps.borderLeftWidth : 0;
  const rightBorder =
    typeof headerProps?.borderRightWidth === 'number' ? headerProps?.borderRightWidth : 0;

  return (
    <Box
      height={4}
      position="absolute"
      bottom={side === 'top' ? -4 : 0}
      left={`-${leftBorder}px`}
      right={`-${rightBorder}px`}
      bgGradient={`linear(to-${side === 'top' ? 'b' : 't'}, blackAlpha.400, transparent)`}
      pointerEvents="none"
    />
  );
};
