import { CommonParamKeys } from './context';
import { Procedure } from './procedure';

/**
 * A utility function for creating a hook which artificially allows the caller to pass a `suspense` parameter
 * instead of using the individual hooks which will have to be used in React Query v5. Not recommended for new code.
 */
export const makeOptionalSuspenseQuery =
  <Client, Params, Return>(procedure: Procedure<Client, Params, Return>) =>
  (params: Omit<Params, CommonParamKeys> & { suspense: boolean }) => {
    const queryResult = procedure.useQuery({ ...params, enabled: !params.suspense });
    const suspenseQueryResult = procedure.useSuspenseQuery({ ...params, enabled: params.suspense });
    return params.suspense ? suspenseQueryResult : queryResult;
  };
