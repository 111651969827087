import { Text } from '@chakra-ui/react';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { Navigate, Route } from 'react-router-dom';

import { PageTab } from '.';
import { TabsView } from './components/TabsView';
import TabOverview from './tabs/GettingStartedOverview';
import TabHolidayDates from './tabs/HolidayDates';
import TabImport from './tabs/ImportClasses';
import TabManageStaff from './tabs/ManageStaff';
import TabMIS from './tabs/MIS';
import TabScheduleHomework from './tabs/ScheduleHomework';
import TabSchemesOfLearning from './tabs/SchemesOfLearning';
import TabTraining from './tabs/Training';

const rootPath = '/getting-started';

export const gettingStartedSteps: PageTab[] = [
  TabManageStaff,
  TabTraining,
  TabHolidayDates,
  TabSchemesOfLearning,
  TabMIS,
  TabImport,
  TabScheduleHomework,
];

const allPageTabs = [TabOverview, ...gettingStartedSteps];

const GettingStartedStepsView = () => (
  <PageContainer>
    <PageTitle
      title="Getting started steps"
      subtitle={
        <Text as="span" fontWeight="normal">
          This page is for the <strong>Head of Department</strong> or{' '}
          <strong>Sparx Science Leader</strong>.
          <br />
          Its aim is to guide you through the steps for a successful launch of Sparx Science in your
          school.
        </Text>
      }
    />
    <TabsView tabs={allPageTabs} rootPath={rootPath} isRollover={false} />
  </PageContainer>
);

export const GettingStartedRoute = (
  <Route path={rootPath} element={<GettingStartedStepsView />}>
    <Route index element={<Navigate to={allPageTabs[0].path} replace />} />
    {allPageTabs.map(tab => (
      <Route key={tab.path} path={tab.path} element={<tab.page isRollover={false} />} />
    ))}
  </Route>
);
