import { Text } from '@chakra-ui/react';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { Navigate, Route } from 'react-router-dom';

import { PageTab } from '.';
import { TabsView } from './components/TabsView';
import { useYearToReference } from './hooks';
import TabHolidayDates from './tabs/HolidayDates';
import TabImport from './tabs/ImportClasses';
import TabManageStaff from './tabs/ManageStaff';
import TabMISReset from './tabs/MISReset';
import TabOverview from './tabs/NewYearOverview';
import TabScheduleHomework from './tabs/ScheduleHomework';
import TabSchemesOfLearning from './tabs/SchemesOfLearning';

const rootPath = '/new-year-setup';

export const newYearSetupSteps: PageTab[] = [
  TabManageStaff,
  TabHolidayDates,
  TabSchemesOfLearning,
  TabMISReset,
  TabImport,
  TabScheduleHomework,
];

const allPageTabs = [TabOverview, ...newYearSetupSteps];

const NewYearSetupView = () => {
  const { yearV3: schoolYear } = useYearToReference();

  if (!schoolYear) {
    return null;
  }
  const startYear = schoolYear.start.getUTCFullYear() - 2000;

  return (
    <PageContainer>
      <PageTitle
        title={`Resetting your Sparx Science site ready for ${schoolYear.current ? 'this' : 'the new'} academic year (${startYear}/${startYear + 1})`}
        subtitle={
          <Text as="span" fontWeight="normal">
            This page is for the <strong>Head of Department</strong> or{' '}
            <strong>Sparx Science Leader</strong>.
            <br />
            Its aim is to guide you in getting everything in order for the start of the{' '}
            {!schoolYear.current && 'new'} academic year in Sparx Science.
          </Text>
        }
      />
      <TabsView tabs={allPageTabs} rootPath={rootPath} isRollover={true} />
    </PageContainer>
  );
};

export const NewYearSetupRoute = (
  <Route path={rootPath} element={<NewYearSetupView />}>
    <Route index element={<Navigate to={allPageTabs[0].path} replace />} />
    {allPageTabs.map(tab => (
      <Route key={tab.path} path={tab.path} element={<tab.page isRollover={true} />} />
    ))}
  </Route>
);
