import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { XpAward, XpAward_Reason } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { usePackages } from 'api/packages';
import { useUpdateUserXpState, useUserILTargets } from 'api/xp';
import { PageContainer } from 'components/PageContainer';
import { useXpContext } from 'components/xp/XpManager/context';
import { isFuture, isPast } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useGroupedPackages } from 'views/packagelist/PackageListView';
import { Nudge } from 'views/task/HomeworkComplete/Nudge';
import { Rewards } from 'views/task/HomeworkComplete/Rewards';

export const HomeworkComplete = ({
  pkg,
  onFinish,
}: {
  pkg?: Package;
  onFinish: (replace?: boolean) => void;
}) => {
  const { markAwardsAsShown, showLevelUp } = useXpContext();

  // get bonus xp awards
  const { awards, nudge } = useShouldShowHomeworkComplete();

  // update the userXpState query to include the most recent xpState from the awards
  const updateUserXpState = useUpdateUserXpState();
  useEffect(() => {
    if (awards) updateUserXpState(awards);
  }, [awards, updateUserXpState]);

  // start on the awards page if we have awards, otherwise the nudge page
  const [packageCompletePage, setPackageCompletePage] = useState(
    awards ? 'rewards' : nudge ? 'nudge' : undefined,
  );

  // get the content, and check we have what we need to show it
  let content: JSX.Element | null = null;
  let redirect = false;
  switch (true) {
    case packageCompletePage === 'rewards' && !!pkg && !!awards:
      content = (
        <Rewards
          awards={awards!}
          markAwardsAsShown={markAwardsAsShown}
          onFinish={() => (nudge ? setPackageCompletePage('nudge') : onFinish())}
          showLevelUp={showLevelUp}
          pkg={pkg}
        />
      );
      break;
    case packageCompletePage === 'nudge' && !!nudge:
      content = <Nudge nudge={nudge} onFinish={() => onFinish()} />;
      break;
    default:
      redirect = true;
  }
  // redirect if we cant show the page we want to
  useEffect(() => {
    if (redirect) onFinish(true);
  }, [redirect, onFinish]);

  return <PageContainer maxWidth={'4xl'}>{content}</PageContainer>;
};

// shouldShowHomeworkComplete checks whether we have any homework complete screens to show. These can be:
// - Bonus Xp Awards
// - Nudges to next thing to work on
//  - If we have unfinished homework packages which are less than 6 weeks old (i.e. shown on package list), nudge that
//  - Otherwise if we have an active incomplete IL challenge, nudge that
//  - Otherwise show nothing
const useShouldShowHomeworkComplete = (): {
  awards?: XpAward[];
  nudge?: nudge;
} => {
  const { getAwardsToShow } = useXpContext();
  const awards = useMemo(() => getAwardsToShow(XpAward_Reason.BONUS_XP), [getAwardsToShow]);
  const { data: packages } = usePackages({ suspense: true });
  const { homeworks, demo } = useGroupedPackages(packages);

  // find any shown packages which are incomplete, and sort them
  const unfinishedHomeworks = homeworks
    .concat(demo)
    .filter(p => !p.state?.completionTimestamp && !p.cancelledTime)
    .sort((a, b) => (b.endTimestamp?.seconds || 0) - (a.endTimestamp?.seconds || 0));

  const { data: ilTargets = [] } = useUserILTargets();
  // find any active IL target, where we are between the start and end date, and the target hasn't been completed
  const hasActiveIlTarget = !!ilTargets.filter(
    t => !(isPast(t.endDate) || isFuture(t.startDate)) && t.xpEarned < t.targetXp,
  ).length;

  return {
    awards: awards.length ? awards : undefined,
    nudge:
      unfinishedHomeworks.length > 0
        ? {
            type: 'hw',
            package: unfinishedHomeworks[0],
          }
        : hasActiveIlTarget
          ? { type: 'il' }
          : undefined,
  };
};

export type nudge =
  | {
      type: 'hw';
      package: Package;
    }
  | {
      type: 'il';
    };
