import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import styles from '../question/SparxQuestion.module.css';
import {
  LayoutElementProps,
  usePredictableShuffleContent,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { ICardInput, ISlotElement } from '../question/types';
import { useClickAwayListener } from '../utils/clickaway';
import {
  useGetBaseOffset,
  useKeepElementWithinElement,
} from '../utils/useKeepElementWithinElement';
import { InlineCardElement } from './InlineCardElement';

interface InlineSlotOptionsProps extends LayoutElementProps<ISlotElement> {
  vertical?: boolean;
  setVerticalSlotOptions?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export const InlineSlotOptions = ({
  vertical = false,
  element,
  answerPartIndex,
  parent,
  setVerticalSlotOptions,
}: InlineSlotOptionsProps) => {
  const { setOpenElementRef, openElementRef, scale, input, sendAction, focussedInputRef } =
    useSparxQuestionContext();

  const closeOptions = useCallback(() => {
    // only close the options if this element is the currently open element.
    // This avoids a race condition where, after selecting a new element, that
    // element's options are closed.
    setOpenElementRef(c => (c === element.ref ? '' : c));
  }, [element.ref, setOpenElementRef]);

  useEffect(() => {
    // if the open element ref isnt this element, close the options
    if (focussedInputRef && focussedInputRef !== element.ref) {
      closeOptions();
    }
  }, [closeOptions, element.ref, focussedInputRef]);

  useClickAwayListener(
    closeOptions,
    openElementRef !== '',
    ev => {
      if (!(ev.target instanceof Element)) {
        return true;
      }
      if (ev.target.closest(`[data-slot="${element.ref}"]`)) {
        return false;
      }
      if (ev.target.closest(`[data-slot-options="${element.ref}"]`)) {
        return false;
      }
      return true;
    },
    'mousedown',
  );

  const cardGroup = useMemo(
    () => input.card_groups?.[element.accept || ''],
    [element.accept, input.card_groups],
  );
  const cards = useMemo(() => {
    const cardRefs = cardGroup?.card_refs || [];
    const cardsWithRef: { card: ICardInput; ref: string }[] = [];
    for (const ref of cardRefs) {
      const card = input?.cards?.[ref];
      if (card) cardsWithRef.push({ card, ref });
    }
    return cardsWithRef;
  }, [cardGroup?.card_refs, input?.cards]);
  // Ensure they are shuffled if they need to be
  const inlineCards = usePredictableShuffleContent(
    cards,
    Boolean(cardGroup?.shuffle),
    answerPartIndex?.toString(),
  );

  const slotCardRef = input.slots?.[element.ref]?.card_ref;

  const slotOptionsRef = useRef<HTMLElement | null>(null);

  const getSlotDomElement = useCallback(
    (ref: string) => document.querySelector(`[data-slot="${ref}"]`),
    [],
  );
  const textFieldRef = useRef<Element | null>(getSlotDomElement(element.ref));

  // re-find the dom element whenever the element ref changes
  useEffect(() => {
    textFieldRef.current = getSlotDomElement(element.ref);
  }, [element.ref, getSlotDomElement]);

  const { offset, recalculate } = useKeepElementWithinElement(
    slotOptionsRef.current,
    parent || null,
    useGetBaseOffset(textFieldRef.current, slotOptionsRef.current, parent),
    scale,
    vertical,
  );

  // recalculate the offset whenever the selected input changes
  useEffect(() => {
    recalculate();
  }, [recalculate, element.ref]);

  // get the focusable elements in the slot options
  const focusableElements = useRef<HTMLElement[]>([]);
  focusableElements.current = Array.from(
    slotOptionsRef.current?.querySelectorAll('[tabindex]:not([tabindex="-1"])') || [],
  ).filter(isHTMLElement);

  // move focus into the group on open
  useEffect(() => {
    if (focusableElements.current && focusableElements.current.length > 0) {
      focusableElements.current[0].focus();
    }
  }, [focusableElements.current?.length]);

  // handle tabbing from start and end of group
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Tab' &&
        slotOptionsRef.current &&
        focusableElements.current &&
        focusableElements.current.length > 0
      ) {
        const firstElement = focusableElements.current[0];
        const lastElement = focusableElements.current[focusableElements.current.length - 1];

        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement.focus();
        }
      }
    };
    const aborter = new AbortController();
    document.addEventListener('keydown', handleKeyDown, { signal: aborter.signal });
    return () => {
      aborter.abort();
    };
  }, []);

  // on escape, close the options, return focus to the input
  useEffect(() => {
    const aborter = new AbortController();
    document.addEventListener(
      'keydown',
      event => {
        // find input and focus it
        if (event.key === 'Escape') {
          const input = document.querySelector(`[data-slot="${openElementRef}"]`);
          if (isHTMLElement(input)) {
            input.focus();
            closeOptions();
          }
        }
      },
      { signal: aborter.signal },
    );
    return () => {
      aborter.abort();
    };
  }, [closeOptions, openElementRef]);

  // focusNextInput is used to focus the next input when a option is selected
  const focusNextInput = () => {
    const inputs = document.querySelectorAll(`[data-ref]`);
    const openElementInputs = Array.from(inputs).filter(isHTMLElement);
    let found = false;
    for (let i = 0; i < openElementInputs.length; i++) {
      if (openElementInputs[i].dataset.ref === element.ref) {
        found = true;
        continue;
      }
      if (found) {
        openElementInputs[i].focus();
        break;
      }
    }
  };

  return (
    <div
      className={classNames(styles.InlineSlotOptions, {
        [styles.InlineSlotOptionsVertical]: vertical,
      })}
      data-slot-options={element.ref}
      ref={el => {
        setVerticalSlotOptions?.(el);
        slotOptionsRef.current = el;
      }}
      style={{ left: offset }}
    >
      {inlineCards.map(card => (
        <InlineCardElement
          key={card.ref}
          element={{
            element: 'card',
            content: card.card.content,
            ref: card.ref,
          }}
          containerSlotRef={element.ref}
          onSelect={() => {
            setOpenElementRef('');
            sendAction({
              action: 'drop_card',
              cardRef: card.ref,
              slotRef: slotCardRef === card.ref ? '' : element.ref,
            });
            focusNextInput();
          }}
        />
      ))}
    </div>
  );
};

const isHTMLElement = (el: unknown): el is HTMLElement => el instanceof HTMLElement;
