import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faFlag, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from '../question/SparxQuestion.module.css';
import { useSparxQuestionContext } from '../question/SparxQuestionContext';

type IconType = 'correct' | 'incorrect' | 'markingError' | 'flag';

const iconMap: Record<IconType, IconDefinition> = {
  correct: faCheck,
  incorrect: faTimes,
  markingError: faMinus,
  flag: faFlag,
};
const styleMap: Record<IconType, any> = {
  correct: styles.CorrectIcon,
  incorrect: styles.IncorrectIcon,
  markingError: styles.MarkingErrorIcon,
  flag: styles.FlagIcon,
};

interface CorrectIconProps {
  correct?: boolean;
  inline?: boolean;
  className?: string;
  markingError?: boolean;
  analyticsAnswerType?: string;
  icon?: IconType;
}

export const CorrectIcon = ({
  correct,
  inline,
  className,
  markingError,
  analyticsAnswerType,
  icon,
}: CorrectIconProps) => {
  const { sendAnalyticEvent } = useSparxQuestionContext();

  if (!icon) {
    icon = markingError ? 'markingError' : correct ? 'correct' : 'incorrect';
  }

  const onClick = () => {
    sendAnalyticEvent('click_correct_icon', {
      state: icon || 'unknown',
      answerType: analyticsAnswerType || 'unknown',
    });
  };

  return (
    <div
      className={classNames(className, !inline && styles.CorrectIconFloat, styleMap[icon])}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={iconMap[icon]} fixedWidth={true} />
    </div>
  );
};
