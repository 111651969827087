import { useToast } from '@chakra-ui/react';
import { GetIndependentLearningStateResponse } from '@sparx/api/apis/sparx/science/packages/v1/independentlearning';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Course, CourseTier } from '@sparx/api/apis/sparx/science/schools/settings/v1/settings';
import { useQueryState } from 'api/client';
import { useIndependentLearningUserState } from 'api/independentlearning';
import { useCreateIndependentLearningPackage, usePackages } from 'api/packages';
import { useSession } from 'api/sessions';
import { LargeLoadingWithText } from 'components/loading/LargeLoading';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IndependentLearningContext {
  createTask: (resourceName?: string, displayName?: string, level?: string) => void;
  userState: GetIndependentLearningStateResponse;
  previousPackages: Package[];
  curriculumName: string;
  setCurriculumName: (curriculumName: string) => void;
  defaultLevel: string;
  level: string;
  setLevel: (level: string) => void;
}

const Context = React.createContext<IndependentLearningContext>({} as IndependentLearningContext);
export const useIndependentLearningContext = () => useContext(Context);

export const IndependentLearningContextProvider = ({ children }: PropsWithChildren) => {
  const generatePackage = useCreateIndependentLearningPackage();
  const [generatingDisplayName, setGenerateDisplayName] = useState('');
  const navigate = useNavigate();
  const { data: userState } = useIndependentLearningUserState({
    suspense: true,
  });

  const [curriculumName, setCurriculumName] = useQueryState(
    `independent-learning-selected-curriculum`,
    userState?.curriculumName || 'curriculums/7bad86c6-9afa-11ed-a0de-7b9493235937',
  );

  const { data: { userLevel: userLevel = '2' } = {} } = useSession({ suspense: true });
  const [level, setLevel] = useQueryState('independent-learning-selected-level', userLevel);

  const { data: allPackages = [] } = usePackages({ suspense: true });
  const previousPackages = useMemo(
    () =>
      allPackages
        .filter(p => p.type === 'independentlearning')
        .sort((a, b) => (b.startTimestamp?.seconds || 0) - (a.startTimestamp?.seconds || 0)),
    [allPackages],
  );
  const toast = useToast();

  const createTask = (resourceName?: string, displayName?: string) => {
    generatePackage.mutate(
      { contentResource: resourceName, level: level },
      {
        onError: () => {
          toast({
            title: 'Error',
            description: 'An error occured, please try again or select a different topic.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
          generatePackage.reset();
        },
        onSuccess: data => {
          if ((data.package[0]?.contents?.tasks.length || 0) > 0) {
            const pkg = data.package[0];
            const task = pkg.contents!.tasks[0]!;
            navigate(`/${task.name}`);
          } else {
            generatePackage.reset();
          }
        },
      },
    );
    setGenerateDisplayName(displayName || '');
  };

  // Show spinner while loading, or on success while we wait for the navigate to happen
  if (generatePackage.isLoading || generatePackage.isSuccess) {
    return (
      <LargeLoadingWithText>
        {generatingDisplayName ? (
          <>Building you a task on {generatingDisplayName}...</>
        ) : (
          <>Building your personal practice...</>
        )}
      </LargeLoadingWithText>
    );
  }

  return (
    <Context.Provider
      value={{
        createTask,
        userState: userState || {
          previousTopics: [],
          course: Course.COMBINED,
          tier: CourseTier.LEVEL_FOUNDATION,
          curriculumName: 'curriculums/7bad86c6-9afa-11ed-a0de-7b9493235937',
        },
        previousPackages,
        curriculumName,
        setCurriculumName,
        defaultLevel: userLevel,
        level,
        setLevel,
      }}
    >
      {children}
    </Context.Provider>
  );
};
