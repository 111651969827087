import { Heading, Text } from '@chakra-ui/react';
import { overlayColourOptions } from '@sparx/accessibility';
import { Stack, WarningMessage } from '@sparx/sparx-design/components';
import { useUserType } from 'api/sessions';
import { StudentNavigation } from 'app/StudentNavigation';
import classNames from 'classnames';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { AnimatePresence, motion } from 'framer-motion';
import { useOverlayContext } from 'views/settings/Overlay';

import previewImage from './preview.png';
import styles from './Settings.module.css';

export const StudentSettingsView = () => {
  const { isTeacher } = useUserType();
  const { overlayColour, saveOverlayColour } = useOverlayContext();
  return (
    <StudentNavigation>
      <PageContainer>
        <PageTitle title={'Settings'} />
        <UserSettings
          onUpdate={saveOverlayColour}
          currentValue={overlayColour}
          isTeacher={isTeacher}
        />
      </PageContainer>
    </StudentNavigation>
  );
};

export const UserSettings = ({
  onUpdate,
  currentValue,
  isTeacher,
}: {
  onUpdate: (v: string) => void;
  currentValue: string | undefined;
  isTeacher: boolean;
}) => {
  const showWarning = currentValue !== overlayColourOptions.None;
  return (
    <div className={styles.SettingsContainer}>
      <Heading size="md" mb="2">
        Colour Overlay
      </Heading>
      <Text mb="5">
        You can apply a coloured overlay that may help you{' '}
        {isTeacher ? 'use the system' : 'read the questions'}.
      </Text>
      <div className={styles.GridContainer}>
        <div className={styles.OptionsContainer}>
          <div className={styles.OptionsGrid}>
            {Object.entries(overlayColourOptions).map(([name, value]) => {
              const selected = currentValue === value;
              return (
                <label
                  key={name}
                  htmlFor={name}
                  className={classNames({
                    [styles.ColourOption]: true,
                    [styles.Selected]: selected,
                  })}
                >
                  <div style={{ backgroundColor: value }} className={styles.ColourSwatch} />
                  <Stack dir="horizontal" spacing={1}>
                    <input
                      type="radio"
                      id={name}
                      name="bg-color"
                      onChange={() => {
                        onUpdate(value);
                      }}
                      checked={selected}
                    />
                    <Text>{name}</Text>
                  </Stack>
                </label>
              );
            })}
          </div>
        </div>
        <div className={styles.PreviewContainer}>
          <Heading size="sm" mb="5">
            Preview
          </Heading>
          <img src={previewImage} className={styles.PreviewImage} />
        </div>
        <AnimatePresence>
          {showWarning && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className={styles.WarningContainer}
            >
              <WarningMessage message="If you use a coloured overlay, some images may not appear as originally intended." />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
