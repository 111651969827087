import { Button } from '@sparx/design/components/button/Button';

import Card from './Card';
import styles from './Errors.module.css';

export default function UnknownError({ reset }: { reset?: () => void }) {
  return (
    <Card>
      <p className={styles.Title}>Oops - An error has occurred</p>
      <p className={styles.Content}>We&apos;re really sorry but an error has occurred.</p>
      <p className={styles.Content}>
        Please <strong>refresh your page</strong> {reset !== undefined ? 'or' : 'and'} try again.
      </p>
      {reset && (
        <Button className={styles.Button} variant='primary' onClick={() => reset()} >
          Try again
        </Button>
      )}
    </Card>
  );
}
