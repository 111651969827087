import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { faArrowUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { makeOptionalSuspenseQuery } from '@sparx/query';
import { getSchool } from '@sparx/query/schools-service';
import { useLocalStorage } from '@sparx/react-utils';
import { useGroups, useSortedGroups, useWeeks } from 'api/school';
import { useGroupSettings, useGroupsWithSettings } from 'api/scienceschool';
import { useCurrentStaffUser } from 'api/staff';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useIsLargeScreen } from 'theme/utils';

interface ClassSelectionConfig {
  current: string | undefined;
  link: (groupID: string) => string;
  allowAll?: boolean;
}

interface ClassSelectionContext extends Partial<ClassSelectionConfig> {
  setConfig: (active: ClassSelectionConfig | undefined) => void;
}

const ClassSelectionContext = React.createContext<ClassSelectionContext>({
  setConfig: () => undefined,
});

export const ClassSelectionProvider = ({ children }: PropsWithChildren) => {
  const [config, setConfig] = useState<ClassSelectionConfig | undefined>(undefined);
  return (
    <ClassSelectionContext.Provider value={{ ...config, setConfig: setConfig }}>
      {children}
    </ClassSelectionContext.Provider>
  );
};

const useSchool = makeOptionalSuspenseQuery(getSchool);

export const useClassSelection = (config: ClassSelectionConfig, opts?: { suspense: boolean }) => {
  const navigate = useNavigate();
  const { setConfig } = React.useContext(ClassSelectionContext);

  const suspense = Boolean(opts?.suspense);
  const { data: school } = useSchool({ suspense });
  const { data: groupSchoolman } = useGroups({ suspense });
  const { data: groupSettings } = useGroupSettings({ suspense });
  const groups = useGroupsWithSettings(groupSchoolman, groupSettings);
  const [lastGroup, setLastGroup] = useLocalStorage(`sci/lastgroup/${school?.name}`);

  useEffect(() => {
    // If no current group - temp set to lastGroup. Hook below will redirect
    // to this page
    if (!config.current && lastGroup && !config.allowAll) {
      config.current = lastGroup;
    }

    setConfig(config);
    return () => setConfig(undefined);
  }, [config.current, config.allowAll, lastGroup, setConfig]);

  // If we don't have a student group default to the last one selected
  useEffect(() => {
    if (school && !config.allowAll) {
      if (!config.current && lastGroup) {
        navigate(config.link(lastGroup), { replace: true });
      } else if (config.current && config.current !== lastGroup) {
        setLastGroup(config.current);
      }
    }
  }, [navigate, school, lastGroup, setLastGroup, config]);

  return groups?.find(g => config.current && g.name.endsWith(config.current || ''));
};

export const ClassSelection = () => {
  const { current, link, allowAll } = React.useContext(ClassSelectionContext);
  const active = current !== undefined || link !== undefined;
  const { data: allGroups, isLoading } = useGroups({ suspense: false });
  const { data: staffUser } = useCurrentStaffUser();
  const largeScreen = useIsLargeScreen();

  // These results aren't used but we want to load them early
  useGroupSettings({ suspense: false });
  useWeeks({ suspense: false });

  const selectedGroup = allGroups?.find(g => current && g.name.endsWith(current));
  const groups = useSortedGroups(allGroups || []);

  // Separate the groups to show the users assigned groups first
  const [myGroups, otherGroups] = useMemo(
    () =>
      groups.reduce<[Group[], Group[]]>(
        (acc, group) => {
          if (group.staff.some(s => s.staffID === staffUser?.name.slice('staff/'.length))) {
            acc[0].push(group);
          } else {
            acc[1].push(group);
          }
          return acc;
        },
        [[], []],
      ),
    [groups, staffUser],
  );

  return (
    <AnimatePresence initial={true}>
      {active && (
        <motion.div
          initial={{ opacity: 0, marginLeft: -10 }}
          animate={{ opacity: 1, marginLeft: 0 }}
          exit={{ opacity: 0, marginLeft: -10 }}
          transition={{ type: 'spring', bounce: 0 }}
          style={{ position: 'relative', zIndex: 11 }}
        >
          <Menu>
            <MenuButton
              as={Button}
              zIndex={11}
              variant="outline"
              colorScheme="whiteAlpha"
              color="whiteAlpha.800"
              rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
              isLoading={isLoading}
              size={largeScreen ? 'md' : 'sm'}
            >
              {selectedGroup?.displayName || (allowAll ? 'All classes' : 'Select class')}
            </MenuButton>
            <MenuList py={0} overflow="auto" zIndex={20} maxHeight="50vh">
              {allowAll && (
                <>
                  <MenuItem
                    as={Link}
                    to={link?.('') || ''}
                    borderLeftWidth={4}
                    borderLeftColor={current === '' ? 'blue.500' : 'white'}
                    fontWeight={current === '' ? 'bold' : 'normal'}
                    mt={2}
                  >
                    All classes
                  </MenuItem>
                </>
              )}
              <MenuGroupsList
                title="My Classes:"
                groups={myGroups}
                noGroups={
                  <>
                    No classes assigned
                    <InfoTooltip
                      text={
                        <>
                          You have no classes assigned.
                          <br />
                          You can assign classes in Class Manager.
                        </>
                      }
                    />
                  </>
                }
              />
              <MenuGroupsList title="Other Classes:" groups={otherGroups} noGroups="No classes">
                {allowAll && (
                  <MenuItem
                    as={Link}
                    to={link?.('') || ''}
                    borderLeftWidth={4}
                    borderLeftColor={current === '' ? 'blue.500' : 'white'}
                    fontWeight={current === '' ? 'bold' : 'normal'}
                  >
                    All classes
                  </MenuItem>
                )}
              </MenuGroupsList>
            </MenuList>
          </Menu>

          {!selectedGroup && !allowAll && !isLoading && <SelectGroupPrompt />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const MenuGroupsList = ({
  title,
  groups,
  noGroups,
  children,
}: PropsWithChildren<{
  title: string;
  groups: Group[];
  noGroups: React.ReactNode;
}>) => {
  const { current, link } = React.useContext(ClassSelectionContext);

  return (
    <MenuGroup color="gray.500">
      <MenuGroupTitle text={title} />
      {children}
      {groups?.map(group => {
        const groupID = group.name.split('/studentGroups/')[1];
        return (
          <MenuItem
            as={Link}
            to={link?.(groupID) || ''}
            key={group.name}
            borderLeftWidth={4}
            borderLeftColor={current === groupID ? 'blue.500' : 'white'}
            fontWeight={current === groupID ? 'bold' : 'normal'}
          >
            {group.displayName}
          </MenuItem>
        );
      })}
      {groups.length === 0 && (
        <Text color="gray.400" px={4} py={2}>
          {noGroups}
        </Text>
      )}
    </MenuGroup>
  );
};

// custom group title which is sticky when scrolling
const MenuGroupTitle = ({ text }: { text: string }) => (
  <Text
    color="gray.500"
    fontSize="sm"
    fontWeight="semibold"
    py={2}
    px={4}
    position="sticky"
    top={0}
    bgColor="white"
    borderBottomWidth={1}
  >
    {text}
  </Text>
);

const SelectGroupPrompt = () => (
  <Box position="absolute" top="100%" pt={8} ml={-2} zIndex={5} left="50%" fontSize="xl" w="50vw">
    <Text fontWeight="bold" color="blue.800">
      <FontAwesomeIcon icon={faArrowUp} />
      <Text as="span" ml={3}>
        Please select a class from the menu above.
      </Text>
    </Text>
  </Box>
);
