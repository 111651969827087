import { Box, Flex, FlexProps, Image, Text, VStack } from '@chakra-ui/react';
import { ProgressBar } from '@sparx/sparx-design/components';
import { ILTargetStateWithDates, useUserILTargets } from 'api/xp';
import lightningIcon from 'components/xp/images/lightning_outline.svg';
import greyLightningIcon from 'components/xp/images/lightning_outline_grey.svg';
import xpChallengeIcon from 'components/xp/images/xp_challenge.svg';
import { format, isFuture, isPast } from 'date-fns';
import { PropsWithChildren, useMemo } from 'react';
import { getRoundedPercentage } from 'utils/percentage';

export const ChallengeBanner = () => {
  const { data: ilTargets = [] } = useUserILTargets();

  const currentTargets = useMemo(() => {
    // We want to show any active targets up to and including the first incomplete target.
    const targetsToShow: ILTargetStateWithDates[] = [];
    for (const t of ilTargets) {
      if (isPast(t.endDate) || isFuture(t.startDate)) {
        continue;
      }

      targetsToShow.push(t);
      if (t.xpEarned < t.targetXp) {
        break;
      }
    }
    // We display them so the imcomplete target is first.
    return targetsToShow.reverse();
  }, [ilTargets]);

  if (currentTargets.length === 0) {
    return null;
  }

  return (
    <VStack spacing={6}>
      {currentTargets.map((target, idx) =>
        target.xpEarned >= target.targetXp && idx !== 0 ? (
          <Completed key={idx} target={target} />
        ) : (
          <InProgress key={idx} target={target} />
        ),
      )}
    </VStack>
  );
};

const Container = ({
  children,
  percentage,
  ...props
}: PropsWithChildren<{ percentage: number } & FlexProps>) => (
  <Flex
    borderRadius="lg"
    // TODO: use correct colours
    backgroundColor="gray.100"
    bgGradient={`linear-gradient(90deg, #FBEAB2 0, gray.100 ${percentage > 0 ? 15 + (percentage * 85) / 100 : 0}%)`}
    px={{ base: 6, md: 8 }}
    py={4}
    width="100%"
    color="blue.800"
    position="relative"
    overflow="hidden"
    {...props}
  >
    {children}
  </Flex>
);

const Completed = ({ target }: { target: ILTargetStateWithDates }) => {
  return (
    <Container percentage={100} justifyContent="space-between" alignItems="center">
      <Text fontWeight="bold" fontSize={{ base: 'sm', sm: 'md' }}>
        Well done! You completed the Weekly Challenge for {format(target.endDate, 'EEEE do MMMM')}
      </Text>
      <Image src={lightningIcon} width="30px" />
    </Container>
  );
};

const InProgress = ({ target }: { target: ILTargetStateWithDates }) => {
  const complete = target.xpEarned >= target.targetXp;
  const percentage = getRoundedPercentage((target.xpEarned * 100) / target.targetXp);

  return (
    <Container percentage={percentage} py={6}>
      <Image
        src={xpChallengeIcon}
        w={90}
        filter={'drop-shadow(-3px 3px 5px rgba(0, 0, 0, .2))'}
        mr={4}
        display={{ base: 'none', sm: 'block' }}
      />
      <Flex flexDirection="column" flex={1}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontWeight="bold" fontSize="lg">
              Weekly Challenge
            </Text>
            <Text fontSize="sm">
              {complete
                ? 'Well done! You completed your Independent Learning Challenge for this week.'
                : `Complete tasks below to ${percentage === 0 ? 'start' : 'continue'} your Independent Learning Challenge.`}
            </Text>
          </VStack>
          {/* TODO: button link */}
          {/* <Button>View previous weeks</Button> */}
        </Flex>
        <Flex mt={4} justifyContent="center" alignItems="center">
          <Box
            flex={1}
            position="relative"
            sx={{
              '--colours-progress-gradient-start': 'colors.yellow.500',
              '--colours-progress-gradient-stop': 'colors.yellow.300',
            }}
          >
            <ProgressBar
              percentComplete={percentage}
              showHighlight={true}
              animateScale={false}
              animateFill
            />
            <Box
              position="absolute"
              right="-15px"
              top={0}
              bottom={0}
              display="flex"
              overflow="visible"
              alignItems="center"
            >
              <Image
                src={percentage >= 100 ? lightningIcon : greyLightningIcon}
                width="30px"
                zIndex={2}
              />
            </Box>
          </Box>
          <Text whiteSpace="nowrap" fontWeight="bold" ml={6} fontSize={{ base: 'sm', sm: 'md' }}>
            <Text as="span" color={percentage === 0 ? 'gray.400' : 'yellow.600'}>
              {target.xpEarned}
            </Text>{' '}
            <Text as="span" color="gray.400">
              /
            </Text>{' '}
            {target.targetXp} XP
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};
