import { Text, TextProps } from '@chakra-ui/react';
import { faBookOpen, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'components/tooltip/Tooltip';

export const ILChevron = (props: TextProps) => (
  <Text fontSize="2xs" display="inline-block" as="span" mt={0.5} {...props}>
    <FontAwesomeIcon icon={faChevronRight} />
  </Text>
);

export const SeenInHomework = (props: TextProps) => (
  <Tooltip label="This topic has recently been in your homework." placement="top">
    <Text
      fontSize="xs"
      borderRadius={1000}
      borderColor="gray.500"
      color="chakra-body-text"
      borderWidth={1}
      px={2}
      bgColor="white"
      width={8}
      height={5}
      as="span"
      {...props}
    >
      <FontAwesomeIcon icon={faBookOpen} />
    </Text>
  </Tooltip>
);
