// these need to match the acceptable colours in the server, defined in:
// - science/server/pkg/pkgsrv/user_flags.go
// - sparxweb/handle_update_student_labels.go
export const overlayColourOptions = {
  None: '#FFFFFF',
  Cream: '#FFF1BE',
  Yellow: '#FFF59A',
  Orange: '#FFD8B3',
  Green: '#E9FFAB',
  Blue: '#C9F5FF',
  Purple: '#E5E1FF',
  Pink: '#FFE0F1',
  Red: '#FFC8C8',
};
