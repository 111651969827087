import { Class } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import {
  addedClasses,
  clickedPreviewWithMissingClassChanges,
} from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import {
  useConflictingStudents,
  useFindIdenticalClasses,
  useSparxClassByWondeID,
  useStudentCountAfterResolvingConflicts,
  useTimeVisible,
} from '@sparx/mis-sync-import/src/hooks';
import BackButton from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import { ConfigPageDrawer } from '@sparx/mis-sync-import/src/MisSyncImport/components/ConfigPageDrawer/ConfigPageDrawer';
import { ConflictsModal } from '@sparx/mis-sync-import/src/MisSyncImport/components/ConflictsModal/ConflictsModal';
import { IdenticalClassesModal } from '@sparx/mis-sync-import/src/MisSyncImport/components/IdenticalClassesModal';
import { MISClassesPanel } from '@sparx/mis-sync-import/src/MisSyncImport/components/MISClassesPanel';
import { SchoolGroupsPanel } from '@sparx/mis-sync-import/src/MisSyncImport/components/SchoolGroupsPanel';
import { useSyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import {
  classTypeToSubjectString,
  ConflictingStudent,
  SchoolData,
  SparxClass,
  WondeClass,
  WondeClassDetailsFromSparxClass,
  WondeData,
} from '@sparx/mis-sync-import/src/types';
import {
  DuplicateClassNames,
  getStudentGroupIDFromGroupName,
  getSystemOptions,
  getWondeIDFromExternalID,
  wondeIDOfGroup,
} from '@sparx/mis-sync-import/src/utils';
import { AnnotationKeys } from '@sparx/schoolutils';
import { Button, Tooltip } from '@sparx/sparx-design/components';
import {
  ChevronLeft,
  ChevronRight,
  InfoOutlined,
  TriangleExclamationIcon,
} from '@sparx/sparx-design/icons';
import { useMemo, useState } from 'react';

import { ConflictResolution } from '../context/config/reducer';
import styles from './MisSyncConfigPage.module.css';

interface MisSyncConfigPageProps {
  school: School;
  schoolData: SchoolData;
  misData: WondeData;
  onPreview: (timeVisible: number) => void;
  hasStudentChangesSinceLastSync: boolean;
  selectedClasses: Set<WondeClass>;
  setSelectedClasses: (classes: Set<WondeClass>) => void;
  unsavedChangesCount: number;
  duplicateClassNames: DuplicateClassNames | undefined;
}

const MisSyncConfigPage = ({
  school,
  misData,
  schoolData,
  onPreview,
  hasStudentChangesSinceLastSync,
  selectedClasses,
  setSelectedClasses,
  unsavedChangesCount,
  duplicateClassNames,
}: MisSyncConfigPageProps) => {
  const timeVisible = useTimeVisible();
  const { groupSubject, sendEvent, onBack, sparxStaffFeaturesEnabled, onWhatsChangedClick } =
    useMisSyncContext();
  const { syncConfig } = useSyncConfig();
  const { conflictResolutions } = syncConfig;
  const conflictingStudents = useConflictingStudents();
  const countAfterResolvingConflicts = useStudentCountAfterResolvingConflicts();

  const identicalClasses = useFindIdenticalClasses(selectedClasses);
  const [showIdenticalClassesModal, setShowIdenticalClassesModal] = useState<boolean>(false);

  // Conflicts being shown in the modal for resolving conflicts
  const [conflictsBeingResolved, setConflictsBeingResolved] = useState<
    ConflictingStudent[] | undefined
  >();
  const { dispatch } = useSyncConfig();

  const pushSelectedClasses = () => {
    if (selectedClasses.size === 0) {
      return;
    }
    const newClasses: WondeClass[] = Array.from(selectedClasses.values());
    dispatch({ type: 'add_classes', classes: newClasses });
    setSelectedClasses(new Set());
    sendEvent(addedClasses(newClasses));
  };

  // Create a record of Sparx groups keyed by Wonde Class ID. We use this to quickly see if a Wonde
  // Class already has a Sparx equivalent for the current app subject, in which case we will filter
  // it out of the Wonde Classes displayed in the table.
  const sparxClassByWondeID = useSparxClassByWondeID(schoolData);

  const lastUpdated = school.annotations[AnnotationKeys.WondeLastSuccess];

  const { dataTheme, system } = getSystemOptions(groupSubject);

  // Build an array of current classes and an array of classes that have been removed from Wonde
  const { currentClasses, removedGroupsArray } = syncConfig.existingClasses.reduce<{
    currentClasses: SparxClass[];
    removedGroupsArray: SparxClass[];
  }>(
    (results, c) => {
      // If the class has been removed from Wonde then don't show it
      if (syncConfig.sparxGroupsRemovedFromWonde.find(g => g.externalId === c.externalId)) {
        return results;
      }
      if (syncConfig.classesToRemove.includes(c.name)) {
        results.removedGroupsArray.push(c);
      } else if (c.type === groupSubject) {
        results.currentClasses.push(c);
      }
      return results;
    },
    { currentClasses: [], removedGroupsArray: [] },
  );

  // Create a record of any duplicate class names that have been caused by an update in the schools
  // MIS. If one of the classes gets removed as part of the sync then we can consider it fixed.
  const initialDuplicateClassNames = duplicateClassNames?.duplicates.reduce<Record<string, string>>(
    (obj, dupe) => {
      const wondeIDs = dupe.message.split(',');
      if (
        removedGroupsArray.find(g =>
          wondeIDs.includes(getWondeIDFromExternalID('group', g.externalId) || ''),
        )
      ) {
        return obj;
      }
      wondeIDs.forEach(wondeID => {
        obj[wondeID] = dupe.name;
      });
      return obj;
    },
    {},
  );

  // Lookup of Sparx group studentGroupID to number of students in that group
  const studentGroupToNumberOfStudentsLookup = Object.values(schoolData.students).reduce<
    Record<string, number>
  >((acc, student) => {
    for (const groupID of student.studentGroupIds) {
      acc[groupID] = (acc[groupID] || 0) + 1;
    }
    return acc;
  }, {});

  // Create a Record of Wonde Classes
  const wondeClassIdToClass = useMemo(
    () =>
      misData.wondeClasses.reduce<Record<string, Class>>((acc, c) => ({ ...acc, [c.id]: c }), {}),
    [misData.wondeClasses],
  );

  // Lookup function to get the Wonde Class from a Sparx Class
  const getWondeClassFromSparxClass = (sparxClass: Group) => {
    const wondeGroupID = wondeIDOfGroup(sparxClass);
    return wondeGroupID ? wondeClassIdToClass[wondeGroupID] : undefined;
  };

  // Get Wonde details for a Sparx class. Use this to show the Wonde class details of a Sparx class.
  const getWondeClassDetailsFromSparxClass = (
    sparxClass: Group,
  ): WondeClassDetailsFromSparxClass => {
    const wondeClass = getWondeClassFromSparxClass(sparxClass);
    let sparxStudentsCount = 0;
    const studentGroupID = getStudentGroupIDFromGroupName(sparxClass.name);
    if (studentGroupID) {
      sparxStudentsCount = studentGroupToNumberOfStudentsLookup[studentGroupID] ?? 0;
    }
    // If we can't find a Wonde class then just show the Sparx class details
    if (!wondeClass) {
      return {
        displayName: sparxClass.displayName,
        subject: classTypeToSubjectString[sparxClass.type],
        resolvedStudentsCount: 0,
        wondeStudentsCount: 0,
        sparxStudentsCount,
      };
    }
    return {
      displayName: wondeClass.name,
      subject: misData.wondeSubjects[wondeClass.subjectId]?.name || 'Unknown',
      resolvedStudentsCount: countAfterResolvingConflicts(wondeClass),
      wondeStudentsCount: wondeClass.students.length,
      sparxStudentsCount,
    };
  };

  return (
    <>
      {showIdenticalClassesModal && (
        <IdenticalClassesModal
          onClose={() => setShowIdenticalClassesModal(false)}
          onProceed={() => {
            setShowIdenticalClassesModal(false);
            pushSelectedClasses();
          }}
        />
      )}
      <div className={styles.Content} data-theme={dataTheme}>
        <div className={styles.PageHeader}>
          <div className={styles.TitleContainer}>
            {onBack && (
              <BackButton onClick={onBack} colour="secondary" className={styles.BackButton}>
                <ChevronLeft />
              </BackButton>
            )}
            <div className={styles.TitleTextContainer}>
              <h2 className={styles.Title}>
                {/* We can probably remove this extra text when we do the next feature, it's just a
            placeholder for an easy way to confirm that the features are enabled */}
                Import and Manage Classes {sparxStaffFeaturesEnabled && '(Sparx Staff Mode)'}
              </h2>
              {onWhatsChangedClick && (
                <div onClick={onWhatsChangedClick} className={styles.WhatsChanged}>
                  <InfoOutlined variant="Blue" className={styles.WhatsChangedIcon} />
                  What&apos;s changed?
                </div>
              )}
            </div>
          </div>
          <div className={styles.SyncInfo}>
            {lastUpdated && <p>Last updated {new Date(lastUpdated).toLocaleString()}</p>}
            {hasStudentChangesSinceLastSync && (
              <Tooltip
                position="bottom"
                content={[
                  `Some changes have been made to students in your school's MIS (for example, new students
              may have been added, or students may have moved classes).`,
                  `These changes will sync automatically overnight, but if you want to sync the changes now click
              the "Preview changes" button below.`,
                ]}
              >
                <p className={styles.SyncInfoTooltip}>
                  <TriangleExclamationIcon className={styles.SyncInfoIcon} /> Student changes since
                  last sync
                </p>
              </Tooltip>
            )}
          </div>
        </div>

        <div className={styles.PanelContainer}>
          <MISClassesPanel
            misData={misData}
            schoolData={schoolData}
            selectedClasses={selectedClasses}
            setSelectedClasses={setSelectedClasses}
            sparxClassByWondeID={sparxClassByWondeID}
            existingClasses={syncConfig.existingClasses}
            identicalClasses={identicalClasses}
          />

          <div className={styles.MoveButtonContainer}>
            <Button
              variant="contained"
              colour="custom"
              className={styles.MoveButton}
              isDisabled={selectedClasses.size === 0}
              onClick={() => {
                if (identicalClasses.size > 0) {
                  setShowIdenticalClassesModal(true);
                } else {
                  pushSelectedClasses();
                }
              }}
            >
              <ChevronRight />
            </Button>
          </div>

          <SchoolGroupsPanel
            schoolData={schoolData}
            misData={misData}
            sparxGroupsRemovedFromWonde={syncConfig.sparxGroupsRemovedFromWonde}
            conflictingStudents={conflictingStudents.conflictingStudents}
            conflictResolutions={conflictResolutions}
            setConflictsBeingResolved={conflicts => setConflictsBeingResolved(conflicts)}
            initialDuplicateClassNames={initialDuplicateClassNames || {}}
            currentClasses={currentClasses}
            removedGroupsArray={removedGroupsArray}
            getWondeClassDetailsFromSparxClass={getWondeClassDetailsFromSparxClass}
            studentGroupToNumberOfStudentsLookup={studentGroupToNumberOfStudentsLookup}
          />
        </div>
      </div>

      <ConfigPageDrawer
        system={system}
        onPreview={() => {
          if (selectedClasses.size > 0) {
            sendEvent(clickedPreviewWithMissingClassChanges(timeVisible.getTimeVisible()));
          }
          onPreview(timeVisible.getTimeVisible());
        }}
        classesHaveBeenRemovedFromMIS={syncConfig.sparxGroupsRemovedFromWonde.length > 0}
        onOpenAllConflicts={() =>
          setConflictsBeingResolved(conflictingStudents.conflictingStudents)
        }
        unsavedChangesCount={unsavedChangesCount}
        hasIdenticalClasses={!!identicalClasses.size}
        initialDuplicateClassNames={initialDuplicateClassNames || {}}
        getWondeClassDetailsFromSparxClass={getWondeClassDetailsFromSparxClass}
      />

      <ConflictsModal
        open={conflictsBeingResolved !== undefined}
        setClosed={() => setConflictsBeingResolved(undefined)}
        conflictingStudents={conflictsBeingResolved}
        conflictResolutions={conflictResolutions}
        wondeData={misData}
        onResolveConflicts={(conflictResolutions: ConflictResolution[]) =>
          dispatch({ type: 'resolve_conflicts', conflictResolutions })
        }
        groupSubject={groupSubject}
      />
    </>
  );
};

export default MisSyncConfigPage;
