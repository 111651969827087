// Rounds a percentage to the nearest whole number in the range 0 - 100
// Values (0, 1] will round to 1
// Values [99, 100) will round to 99
export const getRoundedPercentage = (percentage: number) => {
  percentage = Math.min(Math.max(percentage, 0), 100);
  if (percentage > 0 && percentage < 1) percentage = Math.ceil(percentage);
  else if (percentage > 99 && percentage < 100) percentage = Math.floor(percentage);

  return Math.round(percentage);
};
