import { Center, Image, Text } from '@chakra-ui/react';
import logo from 'app/images/science_blue.svg';

export const NoCookies = () => (
  <Center py={16} maxWidth={500} marginX="auto" flexDirection="column">
    <Image src={logo} alt="Sparx Science" height="30px" mt="4px" ml={3} mr={6} />
    <Text fontSize="xl" fontWeight="bold" pt={4}>
      Error
    </Text>
    <Text textAlign="center">It looks like cookies are disabled on your browser.</Text>
    <Text textAlign="center">
      Sparx Science requires some cookies to be able to function, please enable them and then
      refresh the page.
    </Text>
  </Center>
);
