// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/xp/v1/xp.proto" (package "sparx.science.xp.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Xp } from './xp';
import type { GetCurrentUserXpStateResponse } from './xp';
import type { GetCurrentUserXpStateRequest } from './xp';
import type { ListCurrentUserILTargetXpResponse } from './xp';
import type { ListCurrentUserILTargetXpRequest } from './xp';
import type { ListILTargetXpResponse } from './xp';
import type { ListILTargetXpRequest } from './xp';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { BatchGetStudentXpStateResponse } from './xp';
import type { BatchGetStudentXpStateRequest } from './xp';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.xp.v1.Xp
 */
export interface IXpClient {
  /**
   * @generated from protobuf rpc: BatchGetStudentXpState(sparx.science.xp.v1.BatchGetStudentXpStateRequest) returns (sparx.science.xp.v1.BatchGetStudentXpStateResponse);
   */
  batchGetStudentXpState(
    input: BatchGetStudentXpStateRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchGetStudentXpStateRequest, BatchGetStudentXpStateResponse>;
  /**
   * @generated from protobuf rpc: ListILTargetXp(sparx.science.xp.v1.ListILTargetXpRequest) returns (sparx.science.xp.v1.ListILTargetXpResponse);
   */
  listILTargetXp(
    input: ListILTargetXpRequest,
    options?: RpcOptions,
  ): UnaryCall<ListILTargetXpRequest, ListILTargetXpResponse>;
  /**
   * @generated from protobuf rpc: ListCurrentUserILTargetXp(sparx.science.xp.v1.ListCurrentUserILTargetXpRequest) returns (sparx.science.xp.v1.ListCurrentUserILTargetXpResponse);
   */
  listCurrentUserILTargetXp(
    input: ListCurrentUserILTargetXpRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListCurrentUserILTargetXpRequest,
    ListCurrentUserILTargetXpResponse
  >;
  /**
   * @generated from protobuf rpc: GetCurrentUserXpState(sparx.science.xp.v1.GetCurrentUserXpStateRequest) returns (sparx.science.xp.v1.GetCurrentUserXpStateResponse);
   */
  getCurrentUserXpState(
    input: GetCurrentUserXpStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCurrentUserXpStateRequest, GetCurrentUserXpStateResponse>;
}
/**
 * @generated from protobuf service sparx.science.xp.v1.Xp
 */
export class XpClient implements IXpClient, ServiceInfo {
  typeName = Xp.typeName;
  methods = Xp.methods;
  options = Xp.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: BatchGetStudentXpState(sparx.science.xp.v1.BatchGetStudentXpStateRequest) returns (sparx.science.xp.v1.BatchGetStudentXpStateResponse);
   */
  batchGetStudentXpState(
    input: BatchGetStudentXpStateRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchGetStudentXpStateRequest, BatchGetStudentXpStateResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchGetStudentXpStateRequest,
      BatchGetStudentXpStateResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListILTargetXp(sparx.science.xp.v1.ListILTargetXpRequest) returns (sparx.science.xp.v1.ListILTargetXpResponse);
   */
  listILTargetXp(
    input: ListILTargetXpRequest,
    options?: RpcOptions,
  ): UnaryCall<ListILTargetXpRequest, ListILTargetXpResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListILTargetXpRequest, ListILTargetXpResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListCurrentUserILTargetXp(sparx.science.xp.v1.ListCurrentUserILTargetXpRequest) returns (sparx.science.xp.v1.ListCurrentUserILTargetXpResponse);
   */
  listCurrentUserILTargetXp(
    input: ListCurrentUserILTargetXpRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListCurrentUserILTargetXpRequest,
    ListCurrentUserILTargetXpResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListCurrentUserILTargetXpRequest,
      ListCurrentUserILTargetXpResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetCurrentUserXpState(sparx.science.xp.v1.GetCurrentUserXpStateRequest) returns (sparx.science.xp.v1.GetCurrentUserXpStateResponse);
   */
  getCurrentUserXpState(
    input: GetCurrentUserXpStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCurrentUserXpStateRequest, GetCurrentUserXpStateResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetCurrentUserXpStateRequest,
      GetCurrentUserXpStateResponse
    >('unary', this._transport, method, opt, input);
  }
}
