import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PackageSummary } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { Assignment } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { ILTargetState } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { getCompletionSummary } from '@sparx/packageactivity';
import { useAssignments } from 'api/planner';
import { dateInWeek, useWeeks, Week } from 'api/school';
import { useILXpTargetStatesForStudent } from 'api/xp';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useEffect, useMemo, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link } from 'react-router-dom';
import { getRoundedPercentage } from 'utils/percentage';

export const StudentHomeworkHistoryTable = ({
  packages,
  studentID,
}: {
  packages: PackageSummary[] | undefined;
  studentID: string;
}) => {
  const { data: weeks } = useWeeks({ suspense: true });

  const { data: assignments = [] } = useAssignments({ suspense: true });
  const assignmentsByName = useMemo(
    () =>
      assignments.reduce<Record<string, Assignment>>((acc, a) => {
        acc[a.name] = a;
        return acc;
      }, {}),
    [assignments],
  );

  const packageByWeek = useMemo(() => {
    const packageByWeek: Record<number, PackageSummary[]> = {};
    for (const pkg of packages || []) {
      const assignment = assignmentsByName[pkg.assignmentName];
      if (!assignment || !assignment.startTimestamp) {
        continue;
      }
      const startDate = Timestamp.toDate(assignment.startTimestamp);
      const week = weeks?.find(w => dateInWeek(w, startDate));
      if (!week) {
        continue;
      }
      if (!packageByWeek[week.index]) {
        packageByWeek[week.index] = [];
      }
      packageByWeek[week.index].push(pkg);
    }
    return packageByWeek;
  }, [weeks, packages, assignmentsByName]);

  const { data: ilTargets = [] } = useILXpTargetStatesForStudent(studentID, { suspense: true });
  const ilTargetByWeek = useMemo(() => {
    const ilTargetByWeek: Record<number, ILTargetState[]> = {};
    for (const target of ilTargets || []) {
      const assignment = assignmentsByName[target.assignmentName];
      if (!assignment || !assignment.startTimestamp) {
        continue;
      }
      const startDate = Timestamp.toDate(assignment.startTimestamp);
      const week = weeks?.find(w => dateInWeek(w, startDate));
      if (!week) {
        continue;
      }
      if (!ilTargetByWeek[week.index]) {
        ilTargetByWeek[week.index] = [];
      }
      ilTargetByWeek[week.index].push(target);
    }
    return ilTargetByWeek;
  }, [ilTargets, assignmentsByName, weeks]);

  const currentWeekRef = useRef<HTMLTableCellElement | null>(null);
  useEffect(() => {
    if (currentWeekRef.current) {
      currentWeekRef.current?.scrollIntoView({ block: 'end', inline: 'center' });
    }
  }, [currentWeekRef]);

  const getWeekCell = (week: Week) => {
    const assignment = packageByWeek[week.index]?.[0]?.assignmentName.split('/')[1];
    return (
      <Th
        key={week.index}
        bg={week.current ? 'teal.500' : 'gray.50'}
        color={week.current ? 'gray.50' : 'gray.500'}
        ref={week.current ? currentWeekRef : undefined}
        position="relative"
        fontSize="sm"
        p={0}
        minWidth={12}
        h={12}
        textAlign="center"
      >
        {week.index}
        {assignment && (
          <Tooltip
            label={areAllCancelled(packageByWeek[week.index] || []) ? 'Homework cancelled' : ''}
          >
            <Box
              zIndex={1}
              as={Link}
              position="absolute"
              inset="0 -1px -1000px 0"
              to={`/teacher/handin/answers?assignment=${assignment}&user=${studentID}`}
              state={{ back: `/teacher/student/${studentID}/summary` }}
              _hover={{ bg: 'rgba(0, 0, 0, 0.05)' }}
            />
          </Tooltip>
        )}
      </Th>
    );
  };

  return (
    <Box
      overflowX="auto"
      overflowY="hidden"
      mt={5}
      as={ScrollContainer}
      vertical={false}
      hideScrollbars={false}
    >
      <Table>
        <Thead>
          <Tr>
            <Th position="sticky" left={0} bg="gray.50" textAlign="right" zIndex={2}>
              Week
            </Th>
            {weeks?.map(getWeekCell)}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Th position="sticky" left={0} bg="gray.50" textAlign="right" zIndex={2}>
              Completion
            </Th>
            {weeks?.map(week => (
              <CompletionCell key={week.index} summaries={packageByWeek[week.index] || []} />
            ))}
          </Tr>
          <Tr>
            <Th
              position="sticky"
              left={0}
              bg="gray.50"
              textAlign="right"
              whiteSpace="nowrap"
              zIndex={2}
            >
              IL Challenge{' '}
              <InfoTooltip text="Completion of their weekly Independent Learning Challenge" />
            </Th>
            {weeks?.map(week => (
              <ILCompletionCell key={week.index} ilTargets={ilTargetByWeek[week.index] || []} />
            ))}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

const areAllCancelled = (summaries: PackageSummary[]) => {
  if (!summaries.length) return false;
  return summaries.every(s => !!s.cancelledTime);
};

const CompletionCell = ({ summaries }: { summaries: PackageSummary[] }) => {
  if (!summaries.length) {
    return <Td p={0} bg="white" borderLeftWidth={1} borderLeftColor="gray.100" />;
  }

  const notCancelled = summaries.filter(s => !s.cancelledTime);

  const complete = notCancelled.every(s => s.state?.completionTimestamp);
  const completion = Math.round(
    notCancelled
      .map(s => getCompletionSummary(s.state?.completion).percentages.C.roundedPercentage)
      .reduce((a, b) => a + b, 0) / notCancelled.length,
  );

  const allCancelled = notCancelled.length === 0;

  return (
    <Td
      bg="white"
      p={0}
      minWidth={12}
      h={12}
      textAlign="center"
      borderLeftWidth={1}
      borderLeftColor="gray.100"
    >
      {allCancelled ? (
        <Tooltip label="Homework cancelled">
          <Text fontSize="xl" color="red.600">
            <FontAwesomeIcon icon={faBan} size="sm" />
          </Text>
        </Tooltip>
      ) : complete ? (
        <Text fontSize="xl" color="green.500">
          <FontAwesomeIcon icon={faCheck} />
        </Text>
      ) : (
        <Text fontWeight="bold" color="red.600" fontSize="sm">
          {completion}%
        </Text>
      )}
    </Td>
  );
};

const ILCompletionCell = ({ ilTargets }: { ilTargets: ILTargetState[] }) => {
  if (!ilTargets.length) {
    return <Td p={0} bg="white" borderLeftWidth={1} borderLeftColor="gray.100" />;
  }

  const { target, earned } = ilTargets.reduce<{ target: number; earned: number }>(
    (acc, s) => {
      acc.target += s.targetXp;
      acc.earned += s.xpEarned <= s.targetXp ? s.xpEarned : s.targetXp;
      return acc;
    },
    { target: 0, earned: 0 },
  );

  const percentage = getRoundedPercentage((earned * 100) / target);

  return (
    <Td
      bg="white"
      p={0}
      minWidth={12}
      h={12}
      textAlign="center"
      borderLeftWidth={1}
      borderLeftColor="gray.100"
    >
      <Text fontWeight="bold" color={percentage === 0 ? 'gray.400' : 'green.600'} fontSize="sm">
        {percentage}%
      </Text>
    </Td>
  );
};
