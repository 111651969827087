import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { HintType } from '@sparx/api/apis/sparx/hints/v1/hints';
import classNames from 'classnames';
import { forwardRef, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import { HintInfo } from '../components/Hints/types';
import { LayoutElement } from '../elements/LayoutElement';
import { QuestionAction, updateInputWithAction } from '../question/input';
import { debounceCallback } from '../utils/debounce';
import styles from './SparxQuestion.module.css';
import {
  GapEvaluation,
  ImageLoadingState,
  InsightsMode,
  QuestionMarkingMode,
  QuestionMode,
  SparxQuestionContextProvider,
} from './SparxQuestionContext';
import { IElement, IInput } from './types';

export interface QuestionPasteEvent {
  text: string;
  ref: string;
  pasteType: string;
  inputType: string;
}

export interface SparxQuestionColours {
  textColour?: string;
  buttonColor?: string;
  correctBackground?: string;
  correctText?: string;
  incorrectBackground?: string;
  incorrectText?: string;
  filter?: string;
}

export interface SparxQuestionFont {
  fontSize?: number;
  fontFamily?: string;
  katexFontSize?: string;
  lineHeight?: string;
}

export interface MediaInputSettings {
  autoPlay?: boolean;
  autoComplete?: boolean;
  onPlay?: () => void;
}

interface SparxQuestionProps {
  layout: IElement;
  input: IInput;
  setInput: (input: IInput) => void;
  mode?: QuestionMode;
  readOnly?: boolean;
  insightsMode?: InsightsMode; // Displaying in insights needs some minor display changes.
  gapEvaluations?: Record<string, GapEvaluation>;
  shuffleSeed?: string;
  annotations?: Record<string, string>;
  className?: string;
  imageContainerClassName?: string;
  colours?: SparxQuestionColours;
  font?: SparxQuestionFont;
  centered?: boolean;
  keyboardMode?: boolean;
  firstEmptyInputRef?: MutableRefObject<HTMLElement | null>;
  onPaste?: (e: QuestionPasteEvent) => void;
  getUploadedAsset?: (name: string) => React.ReactNode;
  getAssetUrl?: (value: string) => Promise<string>;
  onScaleChange?: (value: number) => void;
  questionMarkingMode?: QuestionMarkingMode;
  firstChanceGapEvaluationsRef?: React.MutableRefObject<Record<string, GapEvaluation> | undefined>;
  sendAnalyticEvent: (action: string, labels?: Record<string, string>) => void;
  imageLoadingCallback?: (src: string, state: ImageLoadingState) => void;
  hintInfo?: HintInfo;
  numHints?: number;
  triggerHintModal?: (hintType: HintType) => void;
  mediaInputSettings?: MediaInputSettings;
  trialTextFieldSpeechToText?: boolean;
}

export const SparxQuestion = ({
  layout,
  input,
  mode = 'combined',
  setInput,
  readOnly,
  insightsMode,
  gapEvaluations,
  shuffleSeed,
  annotations,
  className,
  imageContainerClassName,
  colours,
  font,
  centered,
  keyboardMode,
  firstEmptyInputRef,
  onPaste,
  getUploadedAsset,
  getAssetUrl,
  onScaleChange,
  questionMarkingMode,
  firstChanceGapEvaluationsRef,
  sendAnalyticEvent,
  imageLoadingCallback,
  hintInfo,
  numHints,
  triggerHintModal,
  mediaInputSettings,
  trialTextFieldSpeechToText,
}: SparxQuestionProps) => {
  const [openElementRef, setOpenElementRef] = useState('');
  const [isWaitingForAnimation, setIsWaitingForAnimation] = useState(false);
  const [dragInProgress, setDragInProgress] = useState(false);
  const [recalculateScaleTrigger, setRecalculateScaleTrigger] = useState(false);
  // memoise send action so it can be used in useEffects more easily
  const sendAction = useCallback(
    (action: QuestionAction) => !readOnly && setInput(updateInputWithAction(input, action)),
    [input, readOnly, setInput],
  );

  const handleDragStart = () => {
    setDragInProgress(true);
  };

  const handleDragEnd = (a: DragEndEvent) => {
    setDragInProgress(false);

    if (!a.over) return;

    const cardRef = a.active.id.toString();
    const slotRef = a.over.id.toString();

    // Check that this card can be accepted in that slot
    const slotAccept = a.over.data.current?.accept;
    if (slotAccept) {
      const cardGroup = input.card_groups?.[slotAccept];
      if (!cardGroup?.card_refs.includes(cardRef)) {
        console.log('not accepted');
        return;
      }
    }

    sendAction({
      action: 'drop_card',
      cardRef: cardRef,
      slotRef: slotRef,
    });
  };

  // Focus on the first input element when the question is first rendered, as long as it's not
  // readonly
  const [questionWrapper, setQuestionWrapper] = useState<HTMLElement | null>(null);
  useEffect(() => {
    if (questionWrapper && !readOnly) {
      const first = questionWrapper.querySelectorAll(
        'button, input, textarea, [tabindex]:not([tabindex="-1"])',
      )[0];

      // Select the first element if it is an input field
      if (first instanceof HTMLElement && ['INPUT', 'TEXTAREA'].includes(first.tagName)) {
        first.focus({ preventScroll: true });
      }
    }
  }, [readOnly, questionWrapper]);

  // We had to manually specify the sensors to get DnD working on old Safari.
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
    useSensor(PointerSensor),
  );

  /**
   * scale stores the scale factor which has been applied to the answer. It is set in <AnswerScreen/>
   */
  const [scale, setScale] = useState(1);
  useEffect(() => {
    onScaleChange?.(scale);
  }, [onScaleChange, scale]);

  const layoutMetadata = useLayoutMetadata(layout);

  const nextInputRef = (): string => {
    if (layoutMetadata.orderedInputRefs.length === 0) return '';

    const current = layoutMetadata.orderedInputRefs.indexOf(openElementRef);
    if (current === -1 || current >= layoutMetadata.orderedInputRefs.length - 1) {
      return '';
    }

    return layoutMetadata.orderedInputRefs[current + 1];
  };

  const setOpenElementRefAndFocus = (ref: React.SetStateAction<string>) => {
    const resolvedRef = typeof ref === 'string' ? ref : ref(openElementRef);
    document
      .querySelector<HTMLElement>(`[data-ref="${resolvedRef}"]`)
      ?.focus({ preventScroll: true });
    setOpenElementRef(ref);
  };

  // set the ref to the first empty input
  useEffect(() => {
    if (firstEmptyInputRef === undefined) {
      return;
    }
    for (const ref of layoutMetadata.orderedInputRefs) {
      const choiceInput = input.choices && input.choices[ref];
      if (choiceInput) {
        // don't scroll multiple choice answers into view
        continue;
      }
      const slotInput = input.slots && input.slots[ref];
      if (slotInput?.card_ref !== undefined) {
        // input is a slot and is already filled
        continue;
      }
      const numericInput = input.number_fields && input.number_fields[ref];
      if (numericInput?.value !== undefined) {
        // input is a numeric input and is already filled
        continue;
      }
      const firstEmptyInput = document.querySelector<HTMLElement>(`[data-ref="${ref}"]`);
      firstEmptyInputRef.current = firstEmptyInput;
      break;
    }
  }, [layoutMetadata, input, firstEmptyInputRef]);

  // on mount, and when we get a hint, focus the first empty input
  const hasHint = !!hintInfo;
  useEffect(() => {
    if (!readOnly && hasHint) {
      firstEmptyInputRef?.current?.focus();
    }
  }, [firstEmptyInputRef, hasHint, readOnly]);

  const [focussedInputRef, setFocussedInputRef] = useState<string | undefined>(undefined);

  // add an event listener to track the focussed input
  // we add this to the document rather than the question, so that tabbing out of the question still
  // triggers the update
  useEffect(() => {
    const handler = (e: FocusEvent) => {
      let inputRef: string | undefined;
      if (e.target instanceof HTMLElement) {
        inputRef = e.target.attributes.getNamedItem('data-ref')?.value;
        // if we click the keypad, keep focus on the numeric input
        if (!inputRef) {
          inputRef = e.target
            .closest('[data-numeric-keypad]')
            ?.attributes.getNamedItem('data-numeric-keypad')?.value;
        }

        // force actual focus on the input
        const input = document.querySelector<HTMLElement>(`[data-ref="${inputRef}"]`);
        if (input) {
          input.focus();
        }
      }
      setFocussedInputRef(inputRef);
    };
    document.addEventListener('focusin', handler);
    return () => document.removeEventListener('focusin', handler);
  }, []);

  return (
    <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <SparxQuestionWrapper
        className={classNames(
          className,
          mode === 'question' && layoutMetadata.questionContainsImages && styles.WithImages,
        )}
        colours={colours}
        font={font}
        mode={mode}
        centered={centered}
        ref={setQuestionWrapper}
      >
        <SparxQuestionContextProvider
          value={{
            input,
            sendAction,
            setOpenElementRef: setOpenElementRefAndFocus,
            openElementRef,
            setIsWaitingForAnimation,
            isWaitingForAnimation,
            nextInputRef,
            isSingleNumericInput: layoutMetadata.isSingleNumericInput,
            readOnly,
            insightsMode,
            gapEvaluations,
            shuffleSeed,
            annotations,
            onPaste,
            mode,
            dragInProgress,
            scale,
            setScale,
            recalculateScaleTrigger,
            keyboardMode,
            setRecalculateScaleTrigger: debounceCallback(
              () => setRecalculateScaleTrigger(v => !v),
              20,
            ),
            questionElement: questionWrapper,
            getUploadedAsset,
            getAssetUrl,
            questionMarkingMode,
            firstChanceGapEvaluationsRef,
            sendAnalyticEvent,
            imageLoadingCallback,
            hintInfo,
            numHints,
            triggerHintModal,
            focussedInputRef,
            imageContainerClassName,
            mediaInputSettings,
            trialTextFieldSpeechToText,
          }}
        >
          <LayoutElement element={layout} />
        </SparxQuestionContextProvider>
      </SparxQuestionWrapper>
    </DndContext>
  );
};

interface SparxQuestionWrapperProps {
  className?: string;
  children?: React.ReactNode;
  colours?: SparxQuestionColours;
  font?: SparxQuestionFont;
  mode?: QuestionMode;
  centered?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}

export const SparxQuestionWrapper = forwardRef<HTMLDivElement | null, SparxQuestionWrapperProps>(
  ({ children, className, colours, font, mode, centered, onFocus }, ref) => (
    <div
      className={classNames(
        styles.Question,
        centered && styles.QuestionCentered,
        mode === 'answer' && styles.QuestionAnswerOnly,
        mode === 'question' && styles.QuestionQuestionOnly,
        mode === 'combined' && styles.QuestionCombined,
        className,
      )}
      ref={ref}
      style={
        {
          '--button-colour': colours?.buttonColor || '#4a95ff',
          '--correct-bg-colour': colours?.correctBackground || 'rgb(145 191 128)',
          '--correct-icon-colour': colours?.correctText || 'rgb(84, 175, 167)',
          '--incorrect-bg-colour': colours?.incorrectBackground || 'rgb(234, 91, 123)',
          '--incorrect-icon-colour': colours?.incorrectText || 'rgb(70, 82, 95)',
          '--question-text-colour': colours?.textColour || 'rgb(46 56 77)',
          '--question-font-family': font?.fontFamily || 'inherit',
          '--question-font-size': font?.fontSize,
          '--question-line-height': font?.lineHeight,
          '--katex-font-size': font?.katexFontSize || '1.21em',
          '--question-filter': colours?.filter || 'none',
        } as React.CSSProperties
      }
      onFocus={onFocus}
    >
      {children}
    </div>
  ),
);
SparxQuestionWrapper.displayName = 'SparxQuestionWrapper';

const useLayoutMetadata = (layout: IElement) => {
  return useMemo(() => {
    const metadata: {
      questionContainsImages: boolean;
      orderedInputRefs: string[];
      isSingleNumericInput: boolean;
    } = {
      questionContainsImages: false,
      orderedInputRefs: [],
      isSingleNumericInput: true,
    };

    const process = (el: IElement, isAnswer: boolean) => {
      switch (el.element) {
        case 'group':
          if (el.type.includes('answer')) {
            isAnswer = true;
          }
          el.content?.map(el => process(el, isAnswer));
          break;
        case 'part-group':
          el.content?.map(el => process(el, isAnswer));
          break;
        case 'figure-ref':
        case 'image':
          if (!isAnswer) {
            metadata.questionContainsImages = true;
          }
          break;
        case 'number-field':
        case 'text-field':
        case 'expression-field':
        case 'choice':
        case 'slot':
          metadata.orderedInputRefs.push(el.ref);
          if (metadata.orderedInputRefs.length > 1 || el.element !== 'number-field') {
            metadata.isSingleNumericInput = false;
          }
          break;

        // These cases are purposefully ignored so the default case will warn when we see
        // unknown element types that we may need to add.
        case 'text':
        case 'card':
        case 'media-input':
        case 'templated-content':
          break;
        default:
          console.warn('Found unknown element when generating layout metadata:', el.element);
      }
    };
    process(layout, false);
    return metadata;
  }, [layout]);
};
