import { Stack, StackProps, Text } from '@chakra-ui/react';

export const LevelChip = ({ level, ...props }: { level: string } & StackProps) => {
  return (
    <Stack
      px={1}
      py={1}
      borderRadius={5}
      fontSize="sm"
      fontWeight="bold"
      backgroundColor={`var(--colours-level-badge-${level}-background)`}
      color={'white'}
      textAlign="center"
      flex={'0 0 70px'}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Text>Level {level}</Text>
    </Stack>
  );
};
