// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/packages/v1/planner.proto" (package "sparx.science.packages.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Package } from './package';
import { Package_Contents } from './package';
import { StudentSettings } from '../../schools/settings/v1/settings';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { TaskItem_Contents } from './package';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.science.packages.v1.Assignment
 */
export interface Assignment {
  /**
   * Name of assignment in format assignment/{assignment_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Title of the assignment.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * @generated from protobuf field: string school_name = 4;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.Assignment.Group groups = 5;
   */
  groups: Assignment_Group[];
  /**
   * @generated from protobuf field: repeated string user_ids = 6;
   */
  userIds: string[]; // string type = 3;   // TODO
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Assignment.Spec spec = 7;
   */
  spec?: Assignment_Spec;
  /**
   * Timestamp that this assignment should be set from.
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 8;
   */
  startTimestamp?: Timestamp;
  /**
   * Timestamp that this Assignment is 'due'.
   *
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 9;
   */
  endTimestamp?: Timestamp;
  /**
   * Timestamp that the assignment was generated for students. If the value
   * is null then the assignment has not been generated yet.
   *
   * @generated from protobuf field: google.protobuf.Timestamp generated_timestamp = 10;
   */
  generatedTimestamp?: Timestamp;
  /**
   * Annotations for this assignment.
   *
   * @generated from protobuf field: map<string, string> annotations = 11;
   */
  annotations: {
    [key: string]: string;
  };
  /**
   * When this assignment was cancelled.
   *
   * @generated from protobuf field: google.protobuf.Timestamp cancelled_time = 12;
   */
  cancelledTime?: Timestamp;
  /**
   * Users that were ignored when generating the assignment, along with the reason.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.Assignment.IgnoredUser ignored_users = 13;
   */
  ignoredUsers: Assignment_IgnoredUser[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.Assignment.Group
 */
export interface Assignment_Group {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.Assignment.Spec
 */
export interface Assignment_Spec {
  /**
   * @generated from protobuf oneof: contents
   */
  contents:
    | {
        oneofKind: 'generatedAssignment';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.GeneratedAssignment generated_assignment = 1;
         */
        generatedAssignment: GeneratedAssignment;
      }
    | {
        oneofKind: 'staticAssignment';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.StaticAssignment static_assignment = 2;
         */
        staticAssignment: StaticAssignment;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.Assignment.IgnoredUser
 */
export interface Assignment_IgnoredUser {
  /**
   * ID of the user that was ignored.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * Reason the user was ignored.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Assignment.IgnoredUser.Reason reason = 2;
   */
  reason: Assignment_IgnoredUser_Reason;
}
/**
 * @generated from protobuf enum sparx.science.packages.v1.Assignment.IgnoredUser.Reason
 */
export enum Assignment_IgnoredUser_Reason {
  /**
   * @generated from protobuf enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * The users homework settings is set to off.
   *
   * @generated from protobuf enum value: HOMEWORK_OFF = 1;
   */
  HOMEWORK_OFF = 1,
  /**
   * The user had already had a homework this week.
   *
   * @generated from protobuf enum value: ALREADY_HAD_HOMEWORK = 2;
   */
  ALREADY_HAD_HOMEWORK = 2,
  /**
   * There wasn't enough content to deliver to the user.
   *
   * @generated from protobuf enum value: NO_CONTENT = 3;
   */
  NO_CONTENT = 3,
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GeneratedAssignment
 */
export interface GeneratedAssignment {
  /**
   * Array of topics in this assignment.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.GeneratedAssignmentTopic topics = 1;
   */
  topics: GeneratedAssignmentTopic[];
  /**
   * Target homework length in minutes.
   *
   * @generated from protobuf field: int32 length_minutes = 2;
   */
  lengthMinutes: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GeneratedAssignmentTopic
 */
export interface GeneratedAssignmentTopic {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.StaticAssignment
 */
export interface StaticAssignment {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.StaticAssignment.Task tasks = 1;
   */
  tasks: StaticAssignment_Task[];
  /**
   * @generated from protobuf field: map<string, string> annotations = 2;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.StaticAssignment.TaskItem
 */
export interface StaticAssignment_TaskItem {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.TaskItem.Contents contents = 2;
   */
  contents?: TaskItem_Contents;
  /**
   * @generated from protobuf field: map<string, string> annotations = 3;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.StaticAssignment.Task
 */
export interface StaticAssignment_Task {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.StaticAssignment.TaskItem items = 2;
   */
  items: StaticAssignment_TaskItem[];
  /**
   * @generated from protobuf field: map<string, string> annotations = 3;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.AssignmentSummary
 */
export interface AssignmentSummary {
  /**
   * Name of assignment in format assignment/{assignment_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Completion stats for this assignment.
   *
   * @generated from protobuf field: sparx.science.packages.v1.AssignmentSummary.Completion completion = 2;
   */
  completion?: AssignmentSummary_Completion;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.AssignmentSummary.Completion
 */
export interface AssignmentSummary_Completion {
  /**
   * Total number of students on this assignment.
   *
   * @generated from protobuf field: int32 total_count = 1;
   */
  totalCount: number;
  /**
   * Number of students who have completed the assignment.
   *
   * @generated from protobuf field: int32 complete_count = 2;
   */
  completeCount: number;
  /**
   * Number of students who have started the assignment (includes complete).
   *
   * @generated from protobuf field: int32 started_count = 3;
   */
  startedCount: number;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.LaunchAssignment
 */
export interface LaunchAssignment {
  /**
   * Name of the assignment to launch
   *
   * @generated from protobuf field: string assignment_name = 1;
   */
  assignmentName: string;
  /**
   * Name of the school the assignment is within
   *
   * @generated from protobuf field: string school_name = 4;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListAssignmentsRequest
 */
export interface ListAssignmentsRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf oneof: query
   */
  query:
    | {
        oneofKind: 'lessonId';
        /**
         * When set, return the assignments for a given lesson.
         *
         * @generated from protobuf field: string lesson_id = 2;
         */
        lessonId: string;
      }
    | {
        oneofKind: 'dates';
        /**
         * @generated from protobuf field: sparx.science.packages.v1.ListAssignmentsRequest.DateQuery dates = 3;
         */
        dates: ListAssignmentsRequest_DateQuery;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListAssignmentsRequest.DateQuery
 */
export interface ListAssignmentsRequest_DateQuery {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 1;
   */
  startTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_timestamp = 2;
   */
  endTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListAssignmentsResponse
 */
export interface ListAssignmentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.Assignment assignments = 1;
   */
  assignments: Assignment[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CreateAssignmentRequest
 */
export interface CreateAssignmentRequest {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Assignment assignment = 1;
   */
  assignment?: Assignment;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.UpdateAssignmentRequest
 */
export interface UpdateAssignmentRequest {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Assignment assignment = 1;
   */
  assignment?: Assignment;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.UpdateGeneratedAssignmentRequest
 */
export interface UpdateGeneratedAssignmentRequest {
  /**
   * The name and school_name fields are required on all updates.
   *
   * @generated from protobuf field: sparx.science.packages.v1.Assignment assignment = 1;
   */
  assignment?: Assignment;
  /**
   * The fields to update. Required. Not all fields are allowed.
   * Allowed fields:
   *  - end_timestamp
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.DeleteAssignmentRequest
 */
export interface DeleteAssignmentRequest {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string school_name = 2;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: bool delete_generated = 3;
   */
  deleteGenerated: boolean;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.DeleteAssignmentResponse
 */
export interface DeleteAssignmentResponse {}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackagePreviewRequest
 */
export interface GetPackagePreviewRequest {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Assignment assignment = 1;
   */
  assignment?: Assignment;
  /**
   * @generated from protobuf field: sparx.science.schools.settings.v1.StudentSettings student_settings = 2;
   */
  studentSettings?: StudentSettings;
  /**
   * @generated from protobuf field: string user_id = 3;
   */
  userId: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetPackagePreviewResponse
 */
export interface GetPackagePreviewResponse {
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Package.Contents package_contents = 1;
   */
  packageContents?: Package_Contents;
  /**
   * Additional data for debugging, only output for sparx staff.
   *
   * @generated from protobuf field: sparx.science.packages.v1.PackagePreviewDebugData debug_data = 2;
   */
  debugData?: PackagePreviewDebugData;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.PackagePreviewDebugData
 */
export interface PackagePreviewDebugData {
  /**
   * @generated from protobuf field: map<string, string> package_annotations = 1;
   */
  packageAnnotations: {
    [key: string]: string;
  };
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.PackagePreviewDebugData.TaskItemAnnotations task_item_annotations = 2;
   */
  taskItemAnnotations: PackagePreviewDebugData_TaskItemAnnotations[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.PackagePreviewDebugData.TaskItemAnnotations
 */
export interface PackagePreviewDebugData_TaskItemAnnotations {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: map<string, string> annotations = 2;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListAssignmentPackagesRequest
 */
export interface ListAssignmentPackagesRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: string assignment_name = 2;
   */
  assignmentName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.StudentPackage
 */
export interface StudentPackage {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: sparx.science.packages.v1.Package package = 2;
   */
  package?: Package;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListAssignmentPackagesResponse
 */
export interface ListAssignmentPackagesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.StudentPackage packages = 1;
   */
  packages: StudentPackage[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetAssignmentSummariesRequest
 */
export interface GetAssignmentSummariesRequest {
  /**
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * @generated from protobuf field: repeated string assignment_names = 2;
   */
  assignmentNames: string[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.GetAssignmentSummariesResponse
 */
export interface GetAssignmentSummariesResponse {
  /**
   * @generated from protobuf field: repeated sparx.science.packages.v1.AssignmentSummary summaries = 1;
   */
  summaries: AssignmentSummary[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ConsolidationTopicAction
 */
export interface ConsolidationTopicAction {
  /**
   * Resource name of the topic
   *
   * @generated from protobuf field: string topic_name = 1;
   */
  topicName: string;
  /**
   * The action to apply for this topic.
   *
   * @generated from protobuf field: sparx.science.packages.v1.ConsolidationTopicAction.Action action = 2;
   */
  action: ConsolidationTopicAction_Action;
  /**
   * Timestamp of the action, if not set then the current time will be used.
   *
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf enum sparx.science.packages.v1.ConsolidationTopicAction.Action
 */
export enum ConsolidationTopicAction_Action {
  /**
   * @generated from protobuf enum value: CONSOLIDATION_TOPIC_ACTION_UNSPECIFIED = 0;
   */
  CONSOLIDATION_TOPIC_ACTION_UNSPECIFIED = 0,
  /**
   * Exclude the topic from future consolidation
   *
   * @generated from protobuf enum value: EXCLUDE = 1;
   */
  EXCLUDE = 1,
  /**
   * Include the topic in future consolidation, only valid in update requests.
   * Has no effect if the topic has not previously been excluded.
   *
   * @generated from protobuf enum value: INCLUDE = 2;
   */
  INCLUDE = 2,
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ConsolidationTopicActions
 */
export interface ConsolidationTopicActions {
  /**
   * Resource name of the group.
   *
   * @generated from protobuf field: string group_name = 1;
   */
  groupName: string;
  /**
   * The topic actions applied to the group.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.ConsolidationTopicAction actions = 2;
   */
  actions: ConsolidationTopicAction[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.ListConsolidationTopicActionsRequest
 */
export interface ListConsolidationTopicActionsRequest {
  /**
   * @generated from protobuf field: string group_name = 1;
   */
  groupName: string;
}
/**
 * @generated from protobuf message sparx.science.packages.v1.UpdateConsolidationTopicActionsRequest
 */
export interface UpdateConsolidationTopicActionsRequest {
  /**
   * @generated from protobuf field: string group_name = 1;
   */
  groupName: string;
  /**
   * Actions to merge with the existing actions.
   *
   * @generated from protobuf field: repeated sparx.science.packages.v1.ConsolidationTopicAction changes = 2;
   */
  changes: ConsolidationTopicAction[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CancelAssignmentRequest
 */
export interface CancelAssignmentRequest {
  /**
   * Name of the assignment to cancel
   *
   * @generated from protobuf field: string assignment_name = 1;
   */
  assignmentName: string;
  /**
   * School resource name
   *
   * @generated from protobuf field: string school_name = 2;
   */
  schoolName: string;
  /**
   * Optional list of student ids to cancel the assignment for.
   * If empty the assignment will be cancelled for all users.
   *
   * @generated from protobuf field: repeated string user_ids = 3;
   */
  userIds: string[];
}
/**
 * @generated from protobuf message sparx.science.packages.v1.CancelAssignmentResponse
 */
export interface CancelAssignmentResponse {
  /**
   * The updated assignment
   *
   * @generated from protobuf field: sparx.science.packages.v1.Assignment assignment = 1;
   */
  assignment?: Assignment;
  /**
   * Cancelled package resource names
   *
   * @generated from protobuf field: repeated string package_names = 2;
   */
  packageNames: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Assignment$Type extends MessageType<Assignment> {
  constructor() {
    super('sparx.science.packages.v1.Assignment', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assignment_Group,
      },
      {
        no: 6,
        name: 'user_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'spec', kind: 'message', T: () => Assignment_Spec },
      { no: 8, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 10,
        name: 'generated_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 11,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      { no: 12, name: 'cancelled_time', kind: 'message', T: () => Timestamp },
      {
        no: 13,
        name: 'ignored_users',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assignment_IgnoredUser,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Assignment
 */
export const Assignment = new Assignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Assignment_Group$Type extends MessageType<Assignment_Group> {
  constructor() {
    super('sparx.science.packages.v1.Assignment.Group', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Assignment.Group
 */
export const Assignment_Group = new Assignment_Group$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Assignment_Spec$Type extends MessageType<Assignment_Spec> {
  constructor() {
    super('sparx.science.packages.v1.Assignment.Spec', [
      {
        no: 1,
        name: 'generated_assignment',
        kind: 'message',
        oneof: 'contents',
        T: () => GeneratedAssignment,
      },
      {
        no: 2,
        name: 'static_assignment',
        kind: 'message',
        oneof: 'contents',
        T: () => StaticAssignment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Assignment.Spec
 */
export const Assignment_Spec = new Assignment_Spec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Assignment_IgnoredUser$Type extends MessageType<Assignment_IgnoredUser> {
  constructor() {
    super('sparx.science.packages.v1.Assignment.IgnoredUser', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'reason',
        kind: 'enum',
        T: () => [
          'sparx.science.packages.v1.Assignment.IgnoredUser.Reason',
          Assignment_IgnoredUser_Reason,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.Assignment.IgnoredUser
 */
export const Assignment_IgnoredUser = new Assignment_IgnoredUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratedAssignment$Type extends MessageType<GeneratedAssignment> {
  constructor() {
    super('sparx.science.packages.v1.GeneratedAssignment', [
      {
        no: 1,
        name: 'topics',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GeneratedAssignmentTopic,
      },
      {
        no: 2,
        name: 'length_minutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GeneratedAssignment
 */
export const GeneratedAssignment = new GeneratedAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratedAssignmentTopic$Type extends MessageType<GeneratedAssignmentTopic> {
  constructor() {
    super('sparx.science.packages.v1.GeneratedAssignmentTopic', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GeneratedAssignmentTopic
 */
export const GeneratedAssignmentTopic = new GeneratedAssignmentTopic$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaticAssignment$Type extends MessageType<StaticAssignment> {
  constructor() {
    super('sparx.science.packages.v1.StaticAssignment', [
      {
        no: 1,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaticAssignment_Task,
      },
      {
        no: 2,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.StaticAssignment
 */
export const StaticAssignment = new StaticAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaticAssignment_TaskItem$Type extends MessageType<StaticAssignment_TaskItem> {
  constructor() {
    super('sparx.science.packages.v1.StaticAssignment.TaskItem', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'contents', kind: 'message', T: () => TaskItem_Contents },
      {
        no: 3,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.StaticAssignment.TaskItem
 */
export const StaticAssignment_TaskItem = new StaticAssignment_TaskItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaticAssignment_Task$Type extends MessageType<StaticAssignment_Task> {
  constructor() {
    super('sparx.science.packages.v1.StaticAssignment.Task', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'items',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaticAssignment_TaskItem,
      },
      {
        no: 3,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.StaticAssignment.Task
 */
export const StaticAssignment_Task = new StaticAssignment_Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignmentSummary$Type extends MessageType<AssignmentSummary> {
  constructor() {
    super('sparx.science.packages.v1.AssignmentSummary', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'completion',
        kind: 'message',
        T: () => AssignmentSummary_Completion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.AssignmentSummary
 */
export const AssignmentSummary = new AssignmentSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignmentSummary_Completion$Type extends MessageType<AssignmentSummary_Completion> {
  constructor() {
    super('sparx.science.packages.v1.AssignmentSummary.Completion', [
      { no: 1, name: 'total_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'complete_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'started_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.AssignmentSummary.Completion
 */
export const AssignmentSummary_Completion =
  new AssignmentSummary_Completion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LaunchAssignment$Type extends MessageType<LaunchAssignment> {
  constructor() {
    super('sparx.science.packages.v1.LaunchAssignment', [
      {
        no: 1,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.LaunchAssignment
 */
export const LaunchAssignment = new LaunchAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentsRequest$Type extends MessageType<ListAssignmentsRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListAssignmentsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'lesson_id',
        kind: 'scalar',
        oneof: 'query',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'dates',
        kind: 'message',
        oneof: 'query',
        T: () => ListAssignmentsRequest_DateQuery,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListAssignmentsRequest
 */
export const ListAssignmentsRequest = new ListAssignmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentsRequest_DateQuery$Type extends MessageType<ListAssignmentsRequest_DateQuery> {
  constructor() {
    super('sparx.science.packages.v1.ListAssignmentsRequest.DateQuery', [
      { no: 1, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'end_timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListAssignmentsRequest.DateQuery
 */
export const ListAssignmentsRequest_DateQuery =
  new ListAssignmentsRequest_DateQuery$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentsResponse$Type extends MessageType<ListAssignmentsResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListAssignmentsResponse', [
      {
        no: 1,
        name: 'assignments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assignment,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListAssignmentsResponse
 */
export const ListAssignmentsResponse = new ListAssignmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAssignmentRequest$Type extends MessageType<CreateAssignmentRequest> {
  constructor() {
    super('sparx.science.packages.v1.CreateAssignmentRequest', [
      { no: 1, name: 'assignment', kind: 'message', T: () => Assignment },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CreateAssignmentRequest
 */
export const CreateAssignmentRequest = new CreateAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAssignmentRequest$Type extends MessageType<UpdateAssignmentRequest> {
  constructor() {
    super('sparx.science.packages.v1.UpdateAssignmentRequest', [
      { no: 1, name: 'assignment', kind: 'message', T: () => Assignment },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.UpdateAssignmentRequest
 */
export const UpdateAssignmentRequest = new UpdateAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateGeneratedAssignmentRequest$Type extends MessageType<UpdateGeneratedAssignmentRequest> {
  constructor() {
    super('sparx.science.packages.v1.UpdateGeneratedAssignmentRequest', [
      { no: 1, name: 'assignment', kind: 'message', T: () => Assignment },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.UpdateGeneratedAssignmentRequest
 */
export const UpdateGeneratedAssignmentRequest =
  new UpdateGeneratedAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAssignmentRequest$Type extends MessageType<DeleteAssignmentRequest> {
  constructor() {
    super('sparx.science.packages.v1.DeleteAssignmentRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'delete_generated',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.DeleteAssignmentRequest
 */
export const DeleteAssignmentRequest = new DeleteAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAssignmentResponse$Type extends MessageType<DeleteAssignmentResponse> {
  constructor() {
    super('sparx.science.packages.v1.DeleteAssignmentResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.DeleteAssignmentResponse
 */
export const DeleteAssignmentResponse = new DeleteAssignmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackagePreviewRequest$Type extends MessageType<GetPackagePreviewRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetPackagePreviewRequest', [
      { no: 1, name: 'assignment', kind: 'message', T: () => Assignment },
      {
        no: 2,
        name: 'student_settings',
        kind: 'message',
        T: () => StudentSettings,
      },
      { no: 3, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackagePreviewRequest
 */
export const GetPackagePreviewRequest = new GetPackagePreviewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPackagePreviewResponse$Type extends MessageType<GetPackagePreviewResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetPackagePreviewResponse', [
      {
        no: 1,
        name: 'package_contents',
        kind: 'message',
        T: () => Package_Contents,
      },
      {
        no: 2,
        name: 'debug_data',
        kind: 'message',
        T: () => PackagePreviewDebugData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetPackagePreviewResponse
 */
export const GetPackagePreviewResponse = new GetPackagePreviewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackagePreviewDebugData$Type extends MessageType<PackagePreviewDebugData> {
  constructor() {
    super('sparx.science.packages.v1.PackagePreviewDebugData', [
      {
        no: 1,
        name: 'package_annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
      {
        no: 2,
        name: 'task_item_annotations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PackagePreviewDebugData_TaskItemAnnotations,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.PackagePreviewDebugData
 */
export const PackagePreviewDebugData = new PackagePreviewDebugData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackagePreviewDebugData_TaskItemAnnotations$Type extends MessageType<PackagePreviewDebugData_TaskItemAnnotations> {
  constructor() {
    super(
      'sparx.science.packages.v1.PackagePreviewDebugData.TaskItemAnnotations',
      [
        { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: 'annotations',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.PackagePreviewDebugData.TaskItemAnnotations
 */
export const PackagePreviewDebugData_TaskItemAnnotations =
  new PackagePreviewDebugData_TaskItemAnnotations$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentPackagesRequest$Type extends MessageType<ListAssignmentPackagesRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListAssignmentPackagesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListAssignmentPackagesRequest
 */
export const ListAssignmentPackagesRequest =
  new ListAssignmentPackagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentPackage$Type extends MessageType<StudentPackage> {
  constructor() {
    super('sparx.science.packages.v1.StudentPackage', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'package', kind: 'message', T: () => Package },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.StudentPackage
 */
export const StudentPackage = new StudentPackage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentPackagesResponse$Type extends MessageType<ListAssignmentPackagesResponse> {
  constructor() {
    super('sparx.science.packages.v1.ListAssignmentPackagesResponse', [
      {
        no: 1,
        name: 'packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentPackage,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListAssignmentPackagesResponse
 */
export const ListAssignmentPackagesResponse =
  new ListAssignmentPackagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssignmentSummariesRequest$Type extends MessageType<GetAssignmentSummariesRequest> {
  constructor() {
    super('sparx.science.packages.v1.GetAssignmentSummariesRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'assignment_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetAssignmentSummariesRequest
 */
export const GetAssignmentSummariesRequest =
  new GetAssignmentSummariesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssignmentSummariesResponse$Type extends MessageType<GetAssignmentSummariesResponse> {
  constructor() {
    super('sparx.science.packages.v1.GetAssignmentSummariesResponse', [
      {
        no: 1,
        name: 'summaries',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssignmentSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.GetAssignmentSummariesResponse
 */
export const GetAssignmentSummariesResponse =
  new GetAssignmentSummariesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsolidationTopicAction$Type extends MessageType<ConsolidationTopicAction> {
  constructor() {
    super('sparx.science.packages.v1.ConsolidationTopicAction', [
      { no: 1, name: 'topic_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'action',
        kind: 'enum',
        T: () => [
          'sparx.science.packages.v1.ConsolidationTopicAction.Action',
          ConsolidationTopicAction_Action,
        ],
      },
      { no: 3, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ConsolidationTopicAction
 */
export const ConsolidationTopicAction = new ConsolidationTopicAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsolidationTopicActions$Type extends MessageType<ConsolidationTopicActions> {
  constructor() {
    super('sparx.science.packages.v1.ConsolidationTopicActions', [
      { no: 1, name: 'group_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'actions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ConsolidationTopicAction,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ConsolidationTopicActions
 */
export const ConsolidationTopicActions = new ConsolidationTopicActions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListConsolidationTopicActionsRequest$Type extends MessageType<ListConsolidationTopicActionsRequest> {
  constructor() {
    super('sparx.science.packages.v1.ListConsolidationTopicActionsRequest', [
      { no: 1, name: 'group_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.ListConsolidationTopicActionsRequest
 */
export const ListConsolidationTopicActionsRequest =
  new ListConsolidationTopicActionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateConsolidationTopicActionsRequest$Type extends MessageType<UpdateConsolidationTopicActionsRequest> {
  constructor() {
    super('sparx.science.packages.v1.UpdateConsolidationTopicActionsRequest', [
      { no: 1, name: 'group_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'changes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ConsolidationTopicAction,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.UpdateConsolidationTopicActionsRequest
 */
export const UpdateConsolidationTopicActionsRequest =
  new UpdateConsolidationTopicActionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAssignmentRequest$Type extends MessageType<CancelAssignmentRequest> {
  constructor() {
    super('sparx.science.packages.v1.CancelAssignmentRequest', [
      {
        no: 1,
        name: 'assignment_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'user_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CancelAssignmentRequest
 */
export const CancelAssignmentRequest = new CancelAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAssignmentResponse$Type extends MessageType<CancelAssignmentResponse> {
  constructor() {
    super('sparx.science.packages.v1.CancelAssignmentResponse', [
      { no: 1, name: 'assignment', kind: 'message', T: () => Assignment },
      {
        no: 2,
        name: 'package_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.science.packages.v1.CancelAssignmentResponse
 */
export const CancelAssignmentResponse = new CancelAssignmentResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.science.packages.v1.Planner
 */
export const Planner = new ServiceType('sparx.science.packages.v1.Planner', [
  {
    name: 'ListAssignments',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: ListAssignmentsRequest,
    O: ListAssignmentsResponse,
  },
  {
    name: 'CreateAssignment',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{assignment.school_name}',
      },
    },
    I: CreateAssignmentRequest,
    O: Assignment,
  },
  {
    name: 'UpdateAssignment',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{assignment.school_name}',
      },
    },
    I: UpdateAssignmentRequest,
    O: Assignment,
  },
  {
    name: 'UpdateGeneratedAssignment',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{assignment.school_name}',
      },
    },
    I: UpdateGeneratedAssignmentRequest,
    O: Assignment,
  },
  {
    name: 'DeleteAssignment',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: DeleteAssignmentRequest,
    O: DeleteAssignmentResponse,
  },
  {
    name: 'GetPackagePreview',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{assignment.school_name}',
      },
    },
    I: GetPackagePreviewRequest,
    O: GetPackagePreviewResponse,
  },
  {
    name: 'ListAssignmentPackages',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: ListAssignmentPackagesRequest,
    O: ListAssignmentPackagesResponse,
  },
  {
    name: 'GetAssignmentSummaries',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: GetAssignmentSummariesRequest,
    O: GetAssignmentSummariesResponse,
  },
  {
    name: 'ListConsolidationTopicActions',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:homework',
        domain: '{group_name|parent}',
      },
    },
    I: ListConsolidationTopicActionsRequest,
    O: ConsolidationTopicActions,
  },
  {
    name: 'UpdateConsolidationTopicActions',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{group_name|parent}',
      },
    },
    I: UpdateConsolidationTopicActionsRequest,
    O: ConsolidationTopicActions,
  },
  {
    name: 'CancelAssignment',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:homework',
        domain: '{school_name}',
      },
    },
    I: CancelAssignmentRequest,
    O: CancelAssignmentResponse,
  },
]);
