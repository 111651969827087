import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import { Panel } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import { MISClassesTable } from '@sparx/mis-sync-import/src/MisSyncImport/components/MISClassesPanel/MISClassesTable';
import { estimateYearGroupFromStudents } from '@sparx/mis-sync-import/src/MisSyncImport/components/MISClassesPanel/utils';
import { SchoolData, SparxClass, WondeClass, WondeData } from '@sparx/mis-sync-import/src/types';
import {
  filterGroupBySubject,
  getSystemOptions,
  isGroupExpired,
  parseYearGroup,
  wondeIDOfGroup,
} from '@sparx/mis-sync-import/src/utils';
import { useMemo, useState } from 'react';

import styles from './MISClassesPanel.module.css';

/**
 * Panel for displaying classes from the MIS
 * @param selectedClasses
 * @param setSelectedClasses
 * @param newClasses
 * @param misData
 * @constructor
 */
export const MISClassesPanel = ({
  selectedClasses,
  setSelectedClasses,
  misData,
  sparxClassByWondeID,
  existingClasses,
  schoolData,
  identicalClasses,
}: {
  misData: WondeData;
  schoolData: SchoolData;
  selectedClasses: Set<WondeClass>;
  setSelectedClasses: (selectedClasses: Set<WondeClass>) => void;
  sparxClassByWondeID: Record<string, Group | undefined>;
  existingClasses: SparxClass[];
  identicalClasses: Set<string>;
}) => {
  const { groupSubject, sparxStaffFeaturesEnabled } = useMisSyncContext();
  const [searchQuery, setSearchQuery] = useState('');
  const { system, showAllSubjectsImage } = getSystemOptions(groupSubject);

  const existingClassWondeIDs = useMemo(() => {
    const wondeIDs: Set<string> = new Set<string>();
    for (const existingClass of existingClasses) {
      const wondeID = wondeIDOfGroup(existingClass);
      if (wondeID) {
        wondeIDs.add(wondeID);
      }
    }
    return wondeIDs;
  }, [existingClasses]);

  const groupsAddedInOtherSubjectsByID: Record<string, Group> = useMemo(() => ({}), []);

  // Build up all the active wonde classes in the school that are not already in Sparx for the
  // current subject. We still show classes that are in other subjects but they are disabled with
  // an explan.
  const wondeClasses: WondeClass[] = useMemo(
    () =>
      misData.wondeClasses
        .filter(c => {
          const existingGroup = sparxClassByWondeID[c.id];
          if (
            existingGroup &&
            !isGroupExpired(existingGroup) &&
            existingGroup.type !== groupSubject
          ) {
            groupsAddedInOtherSubjectsByID[c.id] = existingGroup;
          }
          return (
            !existingGroup || existingGroup.type !== groupSubject || isGroupExpired(existingGroup)
          );
        })
        .map((c): WondeClass => {
          const estimatedYearGroup = estimateYearGroupFromStudents(c.students);
          return {
            ...c,
            estimatedYearGroup,
            configuredYearGroupId:
              Object.values(schoolData.yearGroups).find(
                yg => parseYearGroup(yg.name) === estimatedYearGroup,
              )?.yearGroupID || '',
          };
        })
        .sort((a, b) => {
          const nameSort = a.name.localeCompare(b.name, undefined, { numeric: true });
          const subjectA = misData.wondeSubjects[a.subjectId];
          const subjectB = misData.wondeSubjects[b.subjectId];
          if (!subjectA || !subjectB) {
            return nameSort;
          }
          const subjSort = subjectA.name.localeCompare(subjectB.name, undefined, { numeric: true });
          return subjSort || nameSort;
        }),
    [
      misData.wondeClasses,
      misData.wondeSubjects,
      schoolData.yearGroups,
      sparxClassByWondeID,
      groupSubject,
      groupsAddedInOtherSubjectsByID,
    ],
  );

  // Get the classes that are in the group subject.
  const classesInGroupSubject = wondeClasses.reduce<Set<string>>((acc, c) => {
    const isGroupSubject = filterGroupBySubject(groupSubject)(
      misData.wondeSubjects[c.subjectId].name,
    );
    return isGroupSubject ? acc.add(c.id) : acc;
  }, new Set<string>());

  // If there are classes returned from Wonde but none are in the group subject then we should
  // consider the All subjects checkbox to be checked.
  const hasClassesButNoneInGroupSubject =
    classesInGroupSubject.size === 0 && wondeClasses.length > 0;

  const [selectAllSubjects, setSelectAllSubjects] = useState(false);

  const showNoGroupSubjectClassesMessage = hasClassesButNoneInGroupSubject && !selectAllSubjects;

  const onSelectAllSubjects = () => setSelectAllSubjects(prevVal => !prevVal);

  // Filter the Wonde classes based on the search query and the selected subject.
  const filteredWondeClasses = useMemo(() => {
    const searchTerms = searchQuery.toLowerCase().split(' ');
    return wondeClasses.filter(c => {
      if (!selectAllSubjects && !classesInGroupSubject.has(c.id)) {
        // If the All subjects checkbox isn't selected then we need to filter by subject. Get
        // the relevant filter function and call it with the name of the class's subject.
        return false;
      }
      const subjectName = misData.wondeSubjects[c.subjectId].name;
      // If the user has entered a search query then filter the classes by it. All parts of the search query must
      // match either the class name, the subject name or the estimated year group.
      return !(
        searchTerms.length > 0 &&
        !searchTerms.every(
          term =>
            c.name.toLowerCase().includes(term) ||
            subjectName.toLowerCase().includes(term) ||
            String(c.estimatedYearGroup) === term,
        )
      );
    });
  }, [classesInGroupSubject, misData.wondeSubjects, searchQuery, selectAllSubjects, wondeClasses]);

  // Create a record of groups that have been added in other subjects by their display name. This is
  // used to check if a group with the same name has been added to another subject, in which case we
  // want to prevent the user from importing it.
  const groupsAddedInOtherSubjectsByDisplayName = Object.values(sparxClassByWondeID).reduce<
    Record<string, Group>
  >((acc, group) => {
    if (
      group &&
      group.type !== groupSubject &&
      group.type !== StudentGroupType.TUTORGROUP &&
      !isGroupExpired(group)
    ) {
      acc[group.displayName] = group;
    }
    return acc;
  }, {});

  return (
    <Panel
      header="Your school's MIS"
      searchPlaceholder="Search classes, subjects and year groups"
      selectAllSubjects={selectAllSubjects}
      onSelectAllSubjects={onSelectAllSubjects}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      groupSubject={groupSubject}
      className={sparxStaffFeaturesEnabled ? styles.PanelSparxStaffFeatures : undefined}
    >
      {showNoGroupSubjectClassesMessage ? (
        <div className={styles.NoClasses}>
          <b>We cannot find any {system}-related classes in your MIS.</b>
          {showAllSubjectsImage && <img alt="" src={showAllSubjectsImage} />}
          <p>
            You can see and import other subjects by clicking on the &apos;Show all subjects&apos;
            checkbox above.
          </p>
        </div>
      ) : (
        <MISClassesTable
          misData={misData}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
          filteredWondeClasses={filteredWondeClasses}
          existingClassWondeIDs={existingClassWondeIDs}
          identicalClasses={identicalClasses}
          groupsAddedInOtherSubjectsByWondeID={groupsAddedInOtherSubjectsByID}
          groupsAddedInOtherSubjectsByDisplayName={groupsAddedInOtherSubjectsByDisplayName}
        />
      )}
    </Panel>
  );
};
