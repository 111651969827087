// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/science/reports/v1/reportgen.proto" (package "sparx.science.reports.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Reports } from './reportgen';
import type { GetStudentActivityReportResponse } from './reportgen';
import type { GetStudentActivityReportRequest } from './reportgen';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetHomeworkCompletionReportResponse } from './reportgen';
import type { GetHomeworkCompletionReportRequest } from './reportgen';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.science.reports.v1.Reports
 */
export interface IReportsClient {
  /**
   * GetHomeworkCompletionReport generates a report containing
   * homework completion data.
   *
   * @generated from protobuf rpc: GetHomeworkCompletionReport(sparx.science.reports.v1.GetHomeworkCompletionReportRequest) returns (sparx.science.reports.v1.GetHomeworkCompletionReportResponse);
   */
  getHomeworkCompletionReport(
    input: GetHomeworkCompletionReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetHomeworkCompletionReportRequest,
    GetHomeworkCompletionReportResponse
  >;
  /**
   * @generated from protobuf rpc: GetStudentActivityReport(sparx.science.reports.v1.GetStudentActivityReportRequest) returns (sparx.science.reports.v1.GetStudentActivityReportResponse);
   */
  getStudentActivityReport(
    input: GetStudentActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentActivityReportRequest,
    GetStudentActivityReportResponse
  >;
}
/**
 * @generated from protobuf service sparx.science.reports.v1.Reports
 */
export class ReportsClient implements IReportsClient, ServiceInfo {
  typeName = Reports.typeName;
  methods = Reports.methods;
  options = Reports.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetHomeworkCompletionReport generates a report containing
   * homework completion data.
   *
   * @generated from protobuf rpc: GetHomeworkCompletionReport(sparx.science.reports.v1.GetHomeworkCompletionReportRequest) returns (sparx.science.reports.v1.GetHomeworkCompletionReportResponse);
   */
  getHomeworkCompletionReport(
    input: GetHomeworkCompletionReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetHomeworkCompletionReportRequest,
    GetHomeworkCompletionReportResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetHomeworkCompletionReportRequest,
      GetHomeworkCompletionReportResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetStudentActivityReport(sparx.science.reports.v1.GetStudentActivityReportRequest) returns (sparx.science.reports.v1.GetStudentActivityReportResponse);
   */
  getStudentActivityReport(
    input: GetStudentActivityReportRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetStudentActivityReportRequest,
    GetStudentActivityReportResponse
  > {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetStudentActivityReportRequest,
      GetStudentActivityReportResponse
    >('unary', this._transport, method, opt, input);
  }
}
