// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/hints/v1/hints.proto" (package "sparx.hints.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.hints.v1.Hint
 */
export interface Hint {
  /**
   * @generated from protobuf field: sparx.hints.v1.HintType hintType = 1;
   */
  hintType: HintType;
  /**
   * refs to the inputs that this hint applies to
   *
   * @generated from protobuf field: repeated string inputRefs = 2;
   */
  inputRefs: string[];
}
/**
 * @generated from protobuf enum sparx.hints.v1.HintType
 */
export enum HintType {
  /**
   * @generated from protobuf enum value: HINT_UNSPECIFIED = 0;
   */
  HINT_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: HINT_SIMPLIFY_FRACTION = 1;
   */
  HINT_SIMPLIFY_FRACTION = 1,
  /**
   * @generated from protobuf enum value: HINT_BEARINGS = 2;
   */
  HINT_BEARINGS = 2,
  /**
   * @generated from protobuf enum value: HINT_MONEY = 3;
   */
  HINT_MONEY = 3,
  /**
   * @generated from protobuf enum value: HINT_ROUNDING = 4;
   */
  HINT_ROUNDING = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class Hint$Type extends MessageType<Hint> {
  constructor() {
    super('sparx.hints.v1.Hint', [
      {
        no: 1,
        name: 'hintType',
        kind: 'enum',
        T: () => ['sparx.hints.v1.HintType', HintType],
      },
      {
        no: 2,
        name: 'inputRefs',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.hints.v1.Hint
 */
export const Hint = new Hint$Type();
