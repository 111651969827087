import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZoomableDialog } from '@sparx/sparx-design/components';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { useChoiceGroupContext } from '../elements/ChoiceGroupContext';
import { useLinkBoxesContext } from '../question/LinkBoxesContext';
import { useSlideyGroupContext } from '../question/SlideyContext';
import styles from '../question/SparxQuestion.module.css';
import { LayoutElementProps, useSparxQuestionContext } from '../question/SparxQuestionContext';
import { IFigureElement, IImageElement } from '../question/types';
import { useAssetUrl } from '../utils/asset';

const getStaticSource = (src: string) => {
  // These names are asset names embedded in question layouts.
  // Based on file names found in AlgenieSettings repository
  switch (src) {
    case '':
      return null;
    case 'fraction_slot_link':
    case 'fraction_slot_link.png':
      // TODO
      return <div className={styles.Vinculum} role={'separator'} />;
    case 'ordered_slot_link':
    case 'ordered_slot_link.png':
      return (
        <div className={styles.SeparatorIcon} role={'separator'}>
          <FontAwesomeIcon icon={faArrowDown} />
        </div>
      );
    case 'ordered_slot_link_h':
    case 'ordered_slot_link_h.png':
      return (
        <div className={styles.SeparatorIcon} role={'separator'}>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      );
    case 'unordered_slot_link':
    case 'unordered_slot_link.png':
      // Empty image
      return <div />;
  }
  return <ZoomableImage src={src} />;
};

export const ImageElement = ({ element }: LayoutElementProps<IImageElement>) => {
  return getStaticSource(element.src);
};

export const FigureElement = ({ element }: LayoutElementProps<IFigureElement>) => (
  <QuestionImage src={element.figure.image} />
);

export const QuestionImage = ({ src, alt }: { src: string; alt?: string }) => (
  <div className={styles.ImageContainer}>
    <ZoomableImage src={src} alt={alt} />
  </div>
);

const longImageLoadMs = 5000;

export const ImageNode = ({ src, alt }: { src: string; alt?: string }) => {
  const { url, isLoading, isError } = useAssetUrl(src);
  const { sendAnalyticEvent, readOnly, mode, imageLoadingCallback, imageContainerClassName } =
    useSparxQuestionContext();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [loadFailed, setLoadFailed] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const loadStart = useRef(new Date());

  const sendEvent = useCallback(
    (action: string, loadTimeMs: number, status: string) => {
      sendAnalyticEvent(action, {
        src,
        url: url || '',
        load_duration_ms: loadTimeMs.toString(),
        question_read_only: readOnly ? 'true' : 'false',
        question_mode: mode || '',
        status,
      });
    },
    [src, url, readOnly, mode, sendAnalyticEvent],
  );

  // Send a page event if the image fails to load
  const onLoadError = useCallback(() => {
    setLoadFailed(true);
    imageLoadingCallback && imageLoadingCallback(src, 'error');
    const timeToLoad = new Date().getTime() - loadStart.current.getTime();
    sendEvent('image-load-failed', timeToLoad, 'error');
  }, [setLoadFailed, sendEvent, imageLoadingCallback, src]);

  // If it took a long time to load send a page event
  const onLoaded = useCallback(() => {
    setHasLoaded(true);
    imageLoadingCallback && imageLoadingCallback(src, 'loaded');
    const timeToLoad = new Date().getTime() - loadStart.current.getTime();
    if (timeToLoad >= longImageLoadMs) {
      // if long load time, send analytic
      sendEvent('image-long-load', timeToLoad, 'success');
    }
  }, [setHasLoaded, sendEvent, imageLoadingCallback, src]);

  // If the image is taking a long time to load periodically send a page event
  useEffect(() => {
    if (!hasLoaded && !loadFailed && !isError) {
      const interval = setInterval(() => {
        const timeToLoad = new Date().getTime() - loadStart.current.getTime();
        sendEvent('image-long-load', timeToLoad, 'loading');
      }, longImageLoadMs);
      return () => clearInterval(interval);
    }
  }, [hasLoaded, loadFailed, isError, sendEvent]);

  // Reset the state if the source changes
  useEffect(() => {
    setHasLoaded(false);
    setLoadFailed(false);
    setShowLoading(false);
    loadStart.current = new Date();
    imageLoadingCallback && imageLoadingCallback(src, 'loading');
  }, [src, imageLoadingCallback]);

  // Some browsers don't call onLoad if the image is already loaded,
  // so we call it manually in this case.
  useEffect(() => {
    if (imageRef.current?.complete) {
      setHasLoaded(true);
    }
  }, [imageRef]);

  // Only show the loading spinner if the image hasn't loaded after 250ms
  useEffect(() => {
    if (!hasLoaded) {
      const timeout = setTimeout(() => {
        setShowLoading(true);
      }, 250);
      return () => clearTimeout(timeout);
    }
  }, [setShowLoading, hasLoaded]);

  const hasError = isError || loadFailed;
  const loading = !hasError && (!hasLoaded || isLoading);

  return (
    <div className={classNames({ [styles.ImageWrapper]: loading }, imageContainerClassName)}>
      {hasError && <div className={styles.ImageError}>Error loading image</div>}
      {url && (
        <img
          ref={imageRef}
          src={url}
          alt={alt}
          className={styles.Image}
          onLoad={onLoaded}
          onError={onLoadError}
          draggable={false}
        />
      )}
      {showLoading && loading && (
        <div data-image-loading className={styles.ImageLoadingWrapper}>
          <div className={styles.ImageLoading}>
            <LoadingSpinner size="md" />
            <span>Loading image...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export const ZoomableImage = ({ src, alt }: { src: string; alt?: string }) => {
  const { sendAction, input, mode, insightsMode, sendAnalyticEvent } = useSparxQuestionContext();
  const { choiceImages } = useChoiceGroupContext();
  const { slidey } = useSlideyGroupContext();
  const { isLinkBoxes } = useLinkBoxesContext();

  // we dont show the zoom button for slidey answer tiles, link boxes, or in a print view
  if ((mode === 'answer' && slidey) || insightsMode === 'print' || isLinkBoxes) {
    return <ImageNode src={src} alt={alt} />;
  }

  let choiceIndex: number | undefined;
  let choicesJSXElements: { element: ReactNode; onSelect: () => void }[] | undefined;
  let selectedChoice: number | undefined;
  if (choiceImages) {
    choicesJSXElements =
      choiceImages?.length || 0 > 1
        ? choiceImages?.map((image, i) => {
            if (choiceImages[i].images[0].src === src) {
              choiceIndex = i;
            }
            if (input.choices?.[image.ref]?.selected) {
              selectedChoice = i;
            }
            const onSelect = () =>
              sendAction({
                ref: image.ref,
                action: 'set_choice',
              });
            const element = image.images.map(image => (
              <ImageNode key={image.src} src={image.src} />
            ));
            return { element, onSelect };
          })
        : undefined;
  }
  return (
    <div className={styles.ZoomableImageContainer}>
      <ZoomableDialog
        showZoomControls
        choices={choicesJSXElements}
        initialDisplayedChoice={choiceIndex}
        selectedChoice={selectedChoice}
        onZoomClick={() => {
          sendAnalyticEvent('clicked_zoom_image', { src });
        }}
      >
        <ImageNode src={src} alt={alt} />
      </ZoomableDialog>
    </div>
  );
};
