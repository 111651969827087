import { HintType } from '@sparx/api/apis/sparx/hints/v1/hints';
import { Button } from '@sparx/sparx-design/components';
import { VideoIcon } from '@sparx/sparx-design/icons';
import accessibilityStyles from '@sparx/sparx-design/shared-styles/Accessibility.module.css';
import classNames from 'classnames';
import { useAnimate } from 'framer-motion';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { useSparxQuestionContext } from '../../question/SparxQuestionContext';
import { ReactComponent as HintImageSupport } from './HintImageSupport.svg';
import styles from './Hints.module.css';
import { HintInfo } from './types';

export const HintArrow = ({ partHasInput }: { partHasInput: boolean }) => {
  const [shouldShow, setShouldShow] = useState(!partHasInput);
  // once we have input in the part, we should hide the arrow
  useEffect(() => {
    if (partHasInput) {
      setShouldShow(false);
    }
  }, [partHasInput]);
  return (
    <div
      className={classNames({ [styles.HintArrow]: true, [styles.Hidden]: !shouldShow })}
      role="presentation"
    >
      👉
    </div>
  );
};

export const ResetButton = ({
  hintInfo,
  partHasInput,
}: {
  hintInfo: HintInfo | undefined;
  partHasInput: boolean;
}) => {
  return (
    <>
      {hintInfo && (
        <div
          className={classNames({
            [styles.ResetButtonContainer]: true,
            [styles.Hidden]: !partHasInput,
          })}
          data-reset-button
          role="presentation"
        >
          <Button
            variant="text"
            className={styles.ResetButton}
            onMouseDown={() => hintInfo.onReset()}
          >
            reset
          </Button>
        </div>
      )}
    </>
  );
};

export const HintBannerWrapper = ({ children }: { children: ReactNode }) => {
  const { hintInfo } = useSparxQuestionContext();

  return (
    <>
      <HintBanner hintType={hintInfo?.hint.hintType} />
      {children}
    </>
  );
};

const HintBanner = ({ hintType }: { hintType: HintType | undefined }) => {
  const [animationScope, animate] = useAnimate();
  const { numHints } = useSparxQuestionContext();

  useEffect(() => {
    const doAnimate = async () => {
      if (numHints === 1) {
        await animate(animationScope.current, { opacity: [0, 1] }, { duration: 0.4 });
      }
      await animate(
        animationScope.current,
        {
          rotate: [0, 1, -1, 1, 0],
        },
        { duration: 0.3, delay: 0.3 },
      );
    };
    doAnimate();
  }, [numHints, animate, animationScope]);

  const hintContent = useMemo(() => {
    switch (hintType) {
      case HintType.HINT_SIMPLIFY_FRACTION:
        return <SimplifyFractionHintContent />;
      case HintType.HINT_BEARINGS:
        return <BearingHintContent />;
      case HintType.HINT_MONEY:
        return <MoneyHintContent />;
      case HintType.HINT_ROUNDING:
        return <RoundingHintContent />;
      default:
        return null;
    }
  }, [hintType]);

  return (
    <div ref={animationScope} className={styles.HintContainer}>
      <div className={styles.Hint}>{hintContent}</div>
    </div>
  );
};

const SimplifyFractionHintContent = () => {
  const { triggerHintModal, numHints } = useSparxQuestionContext();

  if (numHints === undefined) {
    return null;
  }

  return (
    <>
      {numHints > 1 ? (
        <b>Keep going! You can still simplify this answer. </b>
      ) : (
        <b>Nearly! Fully simplify your answer. </b>
      )}
      {triggerHintModal && (
        <button
          className={classNames(styles.HintModalLink, accessibilityStyles.FocusTarget)}
          onClick={() => triggerHintModal(HintType.HINT_SIMPLIFY_FRACTION)}
        >
          <b>See how</b>
          <VideoIcon />
        </button>
      )}
      <span> Correct your answer below</span>
    </>
  );
};

const BearingHintContent = () => {
  const { triggerHintModal, numHints } = useSparxQuestionContext();

  if (numHints === undefined) {
    return null;
  }

  return (
    <>
      <b>Nearly! Bearings are written with three digits.</b>{' '}
      {triggerHintModal && (
        <button
          className={classNames(styles.HintModalLink, accessibilityStyles.FocusTarget)}
          onClick={() => triggerHintModal(HintType.HINT_BEARINGS)}
        >
          <b>See how </b> <HintImageSupport />
        </button>
      )}
      <span> Correct your answer below</span>
    </>
  );
};

const MoneyHintContent = () => {
  const { triggerHintModal, numHints } = useSparxQuestionContext();

  if (numHints === undefined) {
    return null;
  }

  return (
    <>
      <b>Nearly! Money is written to 2 decimal places.</b>{' '}
      {triggerHintModal && (
        <button
          className={classNames(styles.HintModalLink, accessibilityStyles.FocusTarget)}
          onClick={() => triggerHintModal(HintType.HINT_MONEY)}
        >
          <b>See how </b> <HintImageSupport />
        </button>
      )}
      <span> Correct your answer below</span>
    </>
  );
};

const RoundingHintContent = () => {
  return (
    <>
      <b>Nearly! Check your rounding.</b>
      <span> Correct your answer below</span>
    </>
  );
};
