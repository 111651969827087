import { IWondeSyncClient } from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync.client';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { IGroupsAPIClient } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi.client';
import { IStudentAPIClient } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi.client';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { UserInteractionEventFields } from '@sparx/mis-sync-import/src/types';
import { AnnotationKeys } from '@sparx/schoolutils';
import { createContext, useCallback, useContext } from 'react';

interface Context {
  school: School | undefined;
  reloadSchool: () => void;
  groupsClient: IGroupsAPIClient;
  groupSubject: StudentGroupType;
  wondeSyncClient: IWondeSyncClient;
  studentsClient: IStudentAPIClient;
  debug?: boolean;
  sendEvent?: (evt: UserInteractionEventFields) => void;
  onUnsavedChanges?: (anyChanges: boolean) => void;
  // Function to call when a sync has been completed.
  onSyncComplete?: () => void;

  // Whether the school is configured in admin portal to use legal names rather than preferred names
  useLegalNames?: boolean;
  // Causes a back button to appear in the component
  onBack?: () => void;
  // When provided this will trigger the support centre to appear rather than link the user to the page
  supportCentreTrigger?: () => void;
  // Whether the user has Sparx Staff Features enabled
  sparxStaffFeaturesEnabled: boolean;
  // Whether the school is in interim
  schoolInInterim: boolean;
  // When provided this will trigger the new feature modal to appear when clicked
  onWhatsChangedClick?: () => void;
  // When provided this will navigate the user to the class manager page when clicked
  onClassManagerClick?: () => void;
}

const context = createContext<Context | null>(null);

export const useMisSyncContext = () => {
  const ctx = useContext(context);
  if (ctx === null) {
    throw Error('useMisSyncContext must be used within a MisSyncContextProvider');
  }

  // Wrap the sendEvent function to add debug logs and warn if no event sender is available
  const sendEvent = useCallback(
    (evt: UserInteractionEventFields) => {
      // Warn if no event sender is available
      if (!ctx.sendEvent) {
        if (ctx.debug) {
          console.warn('[analytics] NO EVENT SENDER AVAILABLE');
        }
        return;
      }
      // Send the event
      ctx.sendEvent(evt);
      // Add a debug log for the event
      if (ctx.debug) {
        console.log('[analytics] SENDING EVENT', evt);
      }
    },
    [ctx],
  );

  const useLegalNames = ctx.school?.annotations?.[AnnotationKeys.WondeUseLegalNames] === 'true';

  return {
    ...ctx,
    sendEvent,
    useLegalNames,
  };
};

export const MisSyncContextProvider = context.Provider;
