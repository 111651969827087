import { Center, Link as ChakraLink } from '@chakra-ui/react';
import { useIsInRolloverInterim } from 'api/schoolcalendar';
import { useUserType } from 'api/sessions';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { Warning } from 'components/warning';
import { ComponentType, forwardRef, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface InterimLockPageProps {
  title?: string;
  Container?: React.ElementType;
}

interface InterimLockProps extends PropsWithChildren, InterimLockPageProps {}

/**
 * A component which is displayed on certain pages when a school has started a new academic year
 * but manual rollover is enabled and they haven't chosen to start the new year yet.
 * It's used as a full-page placeholder such as in front of the class import page
 * Should be used in conjunction with the `interimSuppressGroupSelection` option when defining
 * header overrides for a page, to ensure that group menus don't display when this is shown.
 * */
export const InterimLock = ({ title, children, Container = PageContainer }: InterimLockProps) => {
  const isInRolloverInterim = useIsInRolloverInterim();
  const { jamieMode } = useUserType();

  if (isInRolloverInterim && !jamieMode) {
    return <InterimLockPage title={title} Container={Container} />;
  }
  return <>{children}</>;
};

const InterimLockPage = ({ title, Container = PageContainer }: InterimLockPageProps) => (
  <Container>
    {title && <PageTitle title={title} />}
    <Center py={2} display="flex" flexDirection="column">
      <Warning>
        <strong>You&apos;ve started a new academic year in Sparx Science.</strong>
        <br />
        Your <strong>Sparx Leader</strong> or <strong>Head of Department</strong> need to follow our{' '}
        <ChakraLink as={Link} to="/teacher/new-year-setup" textDecoration="underline">
          new year setup guide
        </ChakraLink>{' '}
        to get your Sparx Science site ready.
        <br />
        Once set up has been completed this page will be available again.
      </Warning>
    </Center>
  </Container>
);

/**
 * A higher-order component which renders the passed component unless manual rollover
 * is enabled and the school is in the interim period. In such a case, the InterimLocked
 * component is rendered in its place, with the given props if any.
 */
export const withInterimLocked = <T1, R extends Element>(
  Component: ComponentType<T1>,
  interimProps?: InterimLockPageProps,
) =>
  forwardRef<R, T1>((props, ref) => {
    const isInterim = useIsInRolloverInterim();
    const { sparxFeaturesEnabled } = useUserType();

    if (isInterim && !sparxFeaturesEnabled) {
      return <InterimLockPage {...interimProps} />;
    }
    return <Component {...props} ref={ref} />;
  });
