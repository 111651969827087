import { Box, Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntercom } from '@sparx/intercom';
import { AsyncSelect } from 'chakra-react-select';
import { useSearchResultLogger } from 'components/search/logger';
import { SearchDocument, useSearch } from 'components/search/searchengine';
import { AnimatePresence, motion } from 'framer-motion';
import { SearchResult } from 'minisearch';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsLargeScreen } from 'theme/utils';
import { urlKnowledgeBase } from 'utils/knowledgeBaseArticles';

export interface ScienceSearchResult extends SearchResult, Omit<SearchDocument, 'id'> {}

export const OmniSearch = ({ hidden }: { hidden: boolean }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const { intercomEnabled, showSpace } = useIntercom();
  const largeScreen = useIsLargeScreen();

  // Logger which debounces searches and logs them
  const { onQuery, onSelect } = useSearchResultLogger();

  const engine = useSearch();
  const loadData = useCallback(
    async (query: string) => {
      const result = (await engine).search(query).slice(0, 20);
      onQuery({ query, results: result as ScienceSearchResult[] });
      return result as ScienceSearchResult[];
    },
    [engine, onQuery],
  );

  const searchBox = (
    <AsyncSelect<ScienceSearchResult>
      size={largeScreen ? 'md' : 'sm'}
      placeholder="Search..."
      noOptionsMessage={() => 'No results found'}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: I'm not sure why this type is failing - I'm pretty sure it is correct (and is needed)
      useBasicStyles={true}
      value={null}
      onInputChange={newValue => setQuery(newValue)}
      menuIsOpen={query.length > 0}
      loadOptions={loadData}
      formatOptionLabel={data => <SearchOption data={data} />}
      components={{
        DropdownIndicator: () => (
          <Box pr={3} color="whiteAlpha.800">
            <FontAwesomeIcon icon={faSearch} />
          </Box>
        ),
      }}
      focusBorderColor="teal.300"
      onChange={value => {
        if (value) {
          if (value.url === urlKnowledgeBase && intercomEnabled) {
            showSpace('home');
          } else {
            if (value.url.startsWith('http')) window.open(value.url, '_blank');
            else navigate(value.url);
          }
          onSelect(value);
        }
      }}
      chakraStyles={searchBoxStyles}
      tabSelectsValue={false}
    />
  );

  return (
    <AnimatePresence initial={false}>
      {!hidden && (
        <Box
          mr={2}
          as={motion.div}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          data-sentry-mask // This can contain student names so mask it just in case
        >
          {searchBox}
        </Box>
      )}
    </AnimatePresence>
  );
};

const searchBoxStyles = {
  control: (provided: SystemStyleObject) => ({
    ...provided,
    bg: 'whiteAlpha.300',
    color: 'white',
    border: 'none',
    width: '25vw',
    maxWidth: '290px',
    cursor: 'text',
  }),
  menu: (provided: SystemStyleObject) => ({
    ...provided,
    zIndex: 11, // Ensure it's over sticky table headers
  }),
  placeholder: (provided: SystemStyleObject) => ({
    ...provided,
    color: 'white',
  }),
};

const SearchOption = ({ data }: { data: ScienceSearchResult }) => {
  return (
    <Flex width="100%" alignItems="center">
      {data.icon && (
        <Text fontSize="md" textTransform="uppercase" color="gray.400">
          <FontAwesomeIcon icon={data.icon} fixedWidth={true} />
        </Text>
      )}
      <Flex direction="column" flex={1} ml={2}>
        <Flex>
          <Text flex={1} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
            {data.parent && (
              <>
                <Text as="span" color="gray.500">
                  {data.parent}
                </Text>
                <Text mx={2} as="span" color="gray.300" fontSize="sm">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Text>
              </>
            )}
            {data.title}
          </Text>
          {data.rightText && <Text color="gray.500">{data.rightText}</Text>}
        </Flex>
        <Text fontSize="2xs" color="gray.500" textTransform="uppercase">
          {data.type}
        </Text>
      </Flex>
    </Flex>
  );
};
