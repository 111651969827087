import { createContext, useContext } from 'react';

import { IImageElement } from '../index';

export interface ChoiceGroupContext {
  show?: boolean;
  correct?: boolean;
  useSelectMultipleFeedback?: boolean;
  choiceImages?: { ref: string; images: IImageElement[] }[];
}

export const ChoiceGroupContext = createContext<ChoiceGroupContext>({});
export const useChoiceGroupContext = () => useContext(ChoiceGroupContext);
