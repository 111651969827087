/* eslint-disable no-restricted-imports */
import {
  UseBreakpointOptions,
  useBreakpointValue as useCBreakpointValue,
  useMediaQuery as useCMediaQuery,
  UseMediaQueryOptions,
} from '@chakra-ui/react';

export const useMediaQuery = (
  query: string | string[],
  options?: Omit<UseMediaQueryOptions, 'ssr'>,
) => useCMediaQuery(query, { ssr: false, ...options });

export const useBreakpointValue = <T>(
  v: Partial<Record<string, T>> | Array<T | null>,
  o?: Omit<UseBreakpointOptions, 'ssr'>,
) => useCBreakpointValue<T>(v, { ssr: false, ...o });
