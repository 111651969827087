import { Alert, AlertIcon, Box, GridItem, Text } from '@chakra-ui/react';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useFormContext } from 'react-hook-form';

import { HeaderField } from '../components/FormFields';
import { useStaffContext } from '../Context';
import { useStaffKeyContacts } from '../queries';
import { ContactEditModel, filterUserRolesForProduct, hasRole } from '../utils';
import { getDuplicateRoleWarningMessage } from '../validation';
import { RoleSelector } from './RoleSelector';

export const RolesSection = () => {
  const { watch, setValue, getValues } = useFormContext<ContactEditModel>();
  const { defaultProduct } = useStaffContext();
  const { data } = useStaffKeyContacts({ suspense: true });

  const staffName = getValues('staffMember.name');
  const staffRoles = watch('staffMember.roles');
  const staffProductRoles = filterUserRolesForProduct(staffRoles, defaultProduct);

  const toggleRole = (role: StaffRole, product: Product) =>
    setValue(
      'staffMember.roles',
      hasRole(staffRoles, role, product)
        ? staffRoles.filter(sra => !(sra.product === product && sra.role === role))
        : [...staffRoles, { product, role }],
      { shouldDirty: true },
    );

  // Warn if attempting to add keycontact role held by another user
  const keyContactRoleWarning = getDuplicateRoleWarningMessage(
    staffProductRoles.filter(
      r => ((data?.keyContacts || {})[r.role]?.name ?? staffName) !== staffName,
    ),
  );

  const rowProps = {
    hasRole: (role: StaffRole, product: Product) => hasRole(staffProductRoles, role, product),
    toggle: toggleRole,
  };

  return (
    <>
      <HeaderField name="Staff Role(s)" />
      <GridItem colSpan={2}>
        <Text mb={4}>
          Setting the correct roles for your staff helps us to target communications and support to
          the correct individuals at your school.
        </Text>
        {staffProductRoles.length === 0 && (
          <Alert mb={4} status="error" borderRadius="md">
            <AlertIcon />
            <Box>
              <Text>Please select at least one role.</Text>
            </Box>
          </Alert>
        )}
        {keyContactRoleWarning && (
          <Alert mb={4} status="warning" borderRadius="md">
            <AlertIcon />
            <Box>
              <Text>{keyContactRoleWarning}</Text>
            </Box>
          </Alert>
        )}
        <RoleSelector {...rowProps} />
      </GridItem>
    </>
  );
};
