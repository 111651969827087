import {
  Box,
  Button,
  Card,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Topic } from '@sparx/api/apis/sparx/content/v2/curriculum';
import { FocusSkill } from '@sparx/api/apis/sparx/science/packages/v1/insights';
import { ImageLoadingState, SparxQuestion, useLayoutSteps } from '@sparx/question';
import { useTopicLookup } from 'api/content';
import { useAssignmentInsights } from 'api/packages';
import { useWeekForDate, Week } from 'api/school';
import { GroupWithSettings } from 'api/scienceschool';
import logo from 'app/images/science_blue.svg';
import { Hide } from 'components/chakraExports';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading, LargeLoadingWithText } from 'components/loading/LargeLoading';
import { getAssetUrl, uploadedAssetProvider } from 'components/uploadedasset/UploadedAsset';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { findSubject } from 'utils/subjects';
import { useAssignmentLinker } from 'views/handin/HandInView';

import { NoFollowUpFive } from './InsightsView';
import { Page } from './PrintPages/Page';
import { PrintPages, PrintTheme } from './PrintPages/PrintPages';

export const InsightsPrintView = () => (
  <React.Suspense fallback={<LargeLoading />}>
    <PrintTheme>
      <SuspenseInsightsPrintView />
    </PrintTheme>
  </React.Suspense>
);

const SuspenseInsightsPrintView = () => {
  const { sendEvent } = useClientEvent();
  const { group, foundAssignment, assignmentID, query } =
    useAssignmentLinker(`/teacher/insights/print`);

  const subject = query.subject?.toString() || '';

  const { data, isLoading, isError } = useAssignmentInsights(assignmentID, subject, {
    suspense: true,
  });
  const { data: topicLookup } = useTopicLookup({ suspense: true });
  const week = useWeekForDate(foundAssignment?.startTimestamp, { suspense: true });

  // Trick to get the ratio of mm to px for the users device
  const [mmToPx, setMmToPx] = useState(0);
  const mmBox = (node: HTMLDivElement) => {
    if (node === null) {
      return;
    }
    setMmToPx(node.getBoundingClientRect().width / 100);
  };

  const onPrint = () => {
    sendEvent({ category: 'insights', action: 'print_clicked' }, { assignmentID: assignmentID });
    window.print();
  };

  // Printing dialog analytics
  useEffect(() => {
    const printEvent = (before: boolean) => {
      sendEvent(
        { category: 'insights', action: `print_dialog_${before ? 'open' : 'close'}` },
        {
          assignmentID: assignmentID,
        },
      );
    };
    const onBefore = () => printEvent(true);
    const onAfter = () => printEvent(false);
    window.addEventListener('beforeprint', onBefore);
    window.addEventListener('afterprint', onAfter);
    return () => {
      window.removeEventListener('beforeprint', onBefore);
      window.removeEventListener('afterprint', onAfter);
    };
  }, [assignmentID, sendEvent]);

  const [loaded, { on: setLoaded }] = useBoolean(false);
  const qHeights = useRef<Record<number, number>>({});

  if (isError) {
    return (
      <Card mx="auto" width="500px" mt={4}>
        <Text p={2} fontWeight="bold" color="orange.600">
          Error loading insights questions
        </Text>
      </Card>
    );
  }

  if (isLoading) {
    return <LargeLoading />;
  }

  if (data.skills.length === 0) {
    return (
      <Card mx="auto" width="500px" mt={4}>
        <NoFollowUpFive />
      </Card>
    );
  }

  // After preloading the questions we store their heights so when we can use them
  // when splitting across pages
  const setQHeight = (idx: number, height: number) => {
    qHeights.current[idx] = height;
    if (Object.keys(qHeights.current).length === data.skills.length) {
      setLoaded();
    }
  };

  return (
    <>
      <Box ref={mmBox} width="100mm" height={0} />
      {!loaded ? (
        <>
          <LargeLoadingWithText>Loading questions...</LargeLoadingWithText>
          <Box opacity={0}>
            <Page isLastRender={false} mmToPxRatio={mmToPx}>
              {data.skills.map((skill, index) => (
                <PreloadQuestion skill={skill} setLoaded={h => setQHeight(index, h)} key={index} />
              ))}
            </Page>
          </Box>
        </>
      ) : (
        <PrintPages
          instructions={<Instructions onPrint={onPrint} />}
          mmToPx={mmToPx}
          header={<Header group={group} week={week} subject={subject} />}
          footer={<Footer refID={assignmentID} />}
        >
          {data.skills.map((skill, index) => (
            <Box
              key={index}
              sx={{
                height: qHeights.current[index],
                '@media print': {
                  height: 'unset',
                },
              }}
            >
              <QDisplay
                skill={skill}
                topic={topicLookup?.[skill.topicName]?.topic}
                index={index + 1}
              />
            </Box>
          ))}
        </PrintPages>
      )}
    </>
  );
};

// For the page splitting to work correctly we need to ensure all images have been loaded before we begin
// We track the loading states of the images and only render the questions once all images have been loaded
// If we haven't started loading any images after a short delay we assume there are no images in the questions.
const PreloadQuestion = ({
  skill,
  topic,
  setLoaded,
}: {
  skill: FocusSkill;
  topic?: Topic;
  setLoaded: (height: number) => void;
}) => {
  const imageLoadingStates = useRef<Record<string, ImageLoadingState>>({});
  const loadingTimerId = useRef<NodeJS.Timeout>();
  const ref = useRef<HTMLDivElement>(null);

  // Wait a short period and check whether we have any image loading states,
  // if we don't then we should start rending the questions
  useEffect(() => {
    if (loadingTimerId.current === undefined) {
      loadingTimerId.current = setTimeout(() => {
        if (Object.values(imageLoadingStates.current).length == 0) {
          setLoaded(ref.current?.getBoundingClientRect().height || 1000);
        }
      }, 250);
    }
  }, [setLoaded]);

  const onUpdateImageState = useCallback(
    (src: string, state: ImageLoadingState) => {
      if (src === '') return;
      imageLoadingStates.current[src] = state;
      if (Object.values(imageLoadingStates.current).every(state => state !== 'loading')) {
        if (loadingTimerId.current !== undefined) {
          clearTimeout(loadingTimerId.current);
        }
        loadingTimerId.current = setTimeout(
          () => setLoaded(ref.current?.getBoundingClientRect().height || 1000),
          250,
        );
      }
    },
    [setLoaded],
  );

  return (
    <Box ref={ref}>
      <QDisplay skill={skill} index={0} topic={topic} imageLoad={onUpdateImageState} />
    </Box>
  );
};

const QDisplay = ({
  skill,
  topic,
  index,
  imageLoad,
}: {
  skill: FocusSkill;
  topic?: Topic;
  index: number;
  imageLoad?: (src: string, state: ImageLoadingState) => void;
}) => {
  const steps = useLayoutSteps(skill.questions[0]?.layoutJson);

  const sub = findSubject(skill.subject);

  return (
    <Box borderRadius="md" borderWidth={1} borderColor="gray.400">
      <Flex borderBottomWidth={1} borderColor="gray.400" p={2} justifyContent="space-between">
        <Text fontWeight="semibold">
          {index}. {topic?.displayName}{' '}
          <Text
            as="span"
            color="gray.600"
            borderRadius="full"
            backgroundColor="gray.200"
            px={2}
            ml={1}
          >
            {topic?.code}
          </Text>
        </Text>
        {sub?.icon}
      </Flex>
      <Box p={2}>
        {steps.length > 0 && steps[0] ? (
          <SparxQuestion
            font={{ fontSize: 12 }}
            layout={steps[0].layout}
            input={steps[0].input}
            setInput={() => undefined}
            readOnly={true}
            insightsMode="print"
            shuffleSeed="noshuffle"
            getUploadedAsset={uploadedAssetProvider}
            getAssetUrl={getAssetUrl}
            sendAnalyticEvent={() => undefined}
            imageLoadingCallback={imageLoad}
          />
        ) : (
          <>Error loading question</>
        )}
      </Box>
    </Box>
  );
};

const Instructions = ({ onPrint }: { onPrint: () => void }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  if (!isOpen) return null;

  return (
    <Card mx="auto" width="100%" mt={4} p={6} alignItems="flex-start">
      <Text as="h2" fontSize="xl" fontWeight="bold">
        To print this page:
      </Text>
      <Box ml={4} mt={4}>
        <UnorderedList>
          <ListItem>Click the print button below, or press Ctrl/Cmd+P on your keyboard.</ListItem>
          <ListItem>Ensure the paper size and orientation are set to A4, Portrait.</ListItem>
          <ListItem>
            You may need to <strong>disable</strong> "Headers and footers", <strong>enable</strong>{' '}
            "Background graphics" and set "Margins" to <strong>none</strong>.
          </ListItem>
          <ListItem>
            Print as many copies as you need, or select a PDF printer to save the page as a PDF
            file.
          </ListItem>
        </UnorderedList>
      </Box>
      <Text fontWeight="semibold" mt={4}>
        This top section will not be included when you print.
      </Text>
      <Button onClick={onPrint} mt={4} colorScheme="buttonTeal">
        Print this page
      </Button>
      <Button
        onClick={onClose}
        position="absolute"
        right={2}
        top={2}
        variant="ghost"
        rightIcon={<FontAwesomeIcon icon={faTimes} />}
      >
        <Hide below="sm">Close instructions</Hide>
      </Button>
    </Card>
  );
};

const Header = ({
  group,
  week,
  subject,
}: {
  group?: GroupWithSettings;
  week?: Week;
  subject: string;
}) => {
  const sub = findSubject(subject);

  return (
    <Flex justifyContent="space-between" py={4} alignItems="center">
      <Text
        fontWeight="bold"
        fontSize="lg"
        color="blue.900"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        Follow-up 5 - {group?.displayName || '?'} - Week {week?.index || '?'}
        {sub?.key !== 'combined' ? ` - ${sub?.name}` : ''}
      </Text>
      <Image src={logo} alt="Sparx Science" height="27px" ml={4} />
    </Flex>
  );
};

const Footer = ({ refID }: { refID: string }) => (
  <Box pt={4} color="gray.400">
    <Flex justifyContent="space-between">
      <Text fontSize="xs">Copyright Sparx {new Date().getFullYear()}</Text>
      <Text fontSize="xs">sparxscience.com</Text>
    </Flex>
    <Text fontSize="2xs">{refID}</Text>
  </Box>
);
