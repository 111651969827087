import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import {
  useStudentCountAfterResolvingConflicts,
  useUnresolvedConflicts,
} from '@sparx/mis-sync-import/src/hooks';
import { Footer } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import { FooterVariant } from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Footer/Footer';
import { IdenticalClassesModal } from '@sparx/mis-sync-import/src/MisSyncImport/components/IdenticalClassesModal';
import { useSyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import { WondeClassDetailsFromSparxClass } from '@sparx/mis-sync-import/src/types';
import { createGroupNameFrequencyMap } from '@sparx/mis-sync-import/src/utils';
import { ErrorMessage, WarningMessage } from '@sparx/sparx-design/components';
import { useState } from 'react';

import styles from './ConfigPageDrawer.module.css';

export const ConfigPageDrawer = ({
  system,
  onPreview,
  classesHaveBeenRemovedFromMIS,
  onOpenAllConflicts,
  unsavedChangesCount,
  hasIdenticalClasses,
  initialDuplicateClassNames,
  getWondeClassDetailsFromSparxClass,
}: {
  system: string;
  onPreview: () => void;
  classesHaveBeenRemovedFromMIS?: boolean;
  onOpenAllConflicts: () => void;
  unsavedChangesCount: number;
  hasIdenticalClasses: boolean;
  initialDuplicateClassNames: Record<string, string>;
  getWondeClassDetailsFromSparxClass: (sparxClass: Group) => WondeClassDetailsFromSparxClass;
}) => {
  const { syncConfig } = useSyncConfig();
  const { groupSubject } = useMisSyncContext();
  const [modalOpen, setModalOpen] = useState(false);
  const countAfterResolvingConflicts = useStudentCountAfterResolvingConflicts();

  const unresolvedConflicts = useUnresolvedConflicts();
  const unresolvedConflictsCount = unresolvedConflicts.length;

  const numExistingClasses = syncConfig.existingClasses.length;
  const hasClassesAfterUserActions =
    syncConfig.classesToAdd.length + (numExistingClasses - syncConfig.classesToRemove.length) > 0;

  const notAllClassesHaveYearGroups = syncConfig.classesToAdd.some(
    cta => cta.configuredYearGroupId === '' && countAfterResolvingConflicts(cta) > 0,
  );

  const classNamesCountMap = createGroupNameFrequencyMap(
    syncConfig.existingClasses,
    syncConfig.classesToAdd,
    syncConfig.classesToRemove,
    getWondeClassDetailsFromSparxClass,
  );
  const notAllClassesHaveUniqueNames =
    Object.values(classNamesCountMap).some(count => count > 1) ||
    Object.values(initialDuplicateClassNames).length;

  const hasNewEmptyClasses = syncConfig.classesToAdd.some(
    cta => countAfterResolvingConflicts(cta) === 0,
  );

  /**
   * Disable preview button if any of the following criteria is met:
   * - There are no classes in the school and would be no classes after the user's actions (i.e.
   *   there are no classes in the school and the user hasn't done anything yet)
   * - Any class that the user has added still needs a year group assigned
   * - There are any unresolved conflicts
   * - Not all existing classes or classes to be added have unique names
   */
  const previewDisabled =
    (numExistingClasses === 0 && !hasClassesAfterUserActions) ||
    notAllClassesHaveYearGroups ||
    unresolvedConflictsCount > 0 ||
    notAllClassesHaveUniqueNames ||
    hasNewEmptyClasses;

  let content = (
    <div>
      <p>
        <strong>
          Any changes here will apply for all teachers at your school, not just for you.
        </strong>
      </p>
      {groupSubject === StudentGroupType.CLASS_SCIENCE && (
        <p className={styles.ScienceSubtext}>
          Sparx Science only supports importing one class per subject. Where students are in a
          separate class for Biology, Chemistry & Physics, import just one of these classes. This
          class can be used to assign homework across all three subjects each week.
        </p>
      )}
    </div>
  );
  let variant: FooterVariant = 'info';

  if (classesHaveBeenRemovedFromMIS) {
    content = (
      <div>
        <ErrorMessage
          message="Some classes have been removed from your school's MIS"
          className={styles.WarningMessage}
        />
        <p className={styles.SubText}>Syncing changes will remove the classes from {system}.</p>
      </div>
    );
    variant = 'error';
  }

  if (hasNewEmptyClasses) {
    content = (
      <div>
        <WarningMessage
          message="Empty classes cannot be imported"
          className={styles.WarningMessage}
        />
        <p>
          One or more of your selected classes has no students assigned to it so cannot be imported.
          Please cancel those classes to proceed.
        </p>
      </div>
    );
    variant = 'warning';
  }

  if (notAllClassesHaveYearGroups) {
    content = (
      <div>
        <WarningMessage
          message="Please select a year group for all classes"
          className={styles.WarningMessage}
        />
        <p>
          Some classes need to be assigned a year group - please select a year group from the
          dropdown menu for each class.
        </p>
      </div>
    );
    variant = 'warning';
  }

  if (unresolvedConflictsCount > 0) {
    content = (
      <div>
        <WarningMessage
          message="Students can only belong to one class in each Sparx product"
          className={styles.WarningMessage}
        />
        <p>
          Some students are in multiple classes. To resolve this, remove conflicting classes or{' '}
          <button className={styles.TextButton} onClick={() => onOpenAllConflicts()}>
            view the conflicts
          </button>{' '}
          and choose a preferred class for each student.{' '}
          <strong>
            {unresolvedConflictsCount} unresolved conflict
            {unresolvedConflictsCount === 1 ? '' : 's'} remaining.
          </strong>
        </p>
      </div>
    );
    variant = 'warning';
  }

  if (hasIdenticalClasses) {
    content = (
      <div className={styles.HasIdenticalClassesWarning}>
        <WarningMessage
          message={`Students can only belong to one class in ${system}.`}
          className={styles.WarningMessage}
        />
        <button onClick={() => setModalOpen(true)}>More info</button>
      </div>
    );
    variant = 'warning';
  }

  if (notAllClassesHaveUniqueNames) {
    content = (
      <div>
        <WarningMessage
          message="Some classes have identical names"
          className={styles.WarningMessage}
        />
        <p>
          Classes in Sparx systems need a unique name, please remove any duplicate classes or make a
          change in your MIS and return here to sync your classes.
        </p>
      </div>
    );
    variant = 'warning';
  }

  return (
    <>
      {modalOpen && <IdenticalClassesModal onClose={() => setModalOpen(false)} />}
      <Footer
        variant={variant}
        onSubmit={onPreview}
        isDisabled={!!previewDisabled}
        submitText="Preview changes"
        unsavedChangesCount={unsavedChangesCount}
        groupSubject={groupSubject}
      >
        {content}
      </Footer>
    </>
  );
};
