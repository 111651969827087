import { ListActivityFeedResponse_AggregateActivity } from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { activitiesClient } from 'api';
import { endOfMonth, isBefore, isEqual, startOfDay, startOfHour, startOfMonth } from 'date-fns';
import { useMemo } from 'react';

import { useStudentPackageSummaries } from './packages';
import { getSchoolID } from './sessions';
import { useILXpTargetStatesForStudent } from './xp';

export interface CommonActivityEntry {
  correctActivities: number;
  totalSeconds: number;
  totalActivities: number;
}

export interface ILActivityEntry extends CommonActivityEntry {
  type: 'independentlearning';
  topics: Set<string>;
}

export interface AssignmentActivityEntry extends CommonActivityEntry {
  type: 'assignment';
  assignmentName: string;
}

export interface CompletionEntry {
  type: 'completion';
  completionType: 'assignment' | 'il_target';
  assignmentName: string;
}

export type ActivityEntry = ILActivityEntry | AssignmentActivityEntry | CompletionEntry;

export interface DailyActivity {
  day: Date;
  entries: ActivityEntry[];
}

export const useDailyActivityFeed = (studentId: string, month: Date) => {
  const startTime = startOfMonth(month);
  const endTime = endOfMonth(month);

  const { data: packageSummary = [] } = useStudentPackageSummaries(studentId, { suspense: true });
  const { data: ilTargets = [] } = useILXpTargetStatesForStudent(studentId, { suspense: true });

  const { data: acts = [] } = useQuery({
    queryKey: ['activityfeed', studentId, startTime.toISOString()],
    queryFn: async () =>
      activitiesClient.listActivityFeed({
        schoolName: `schools/${await getSchoolID()}`,
        studentName: `students/${studentId}`,
        dateRange: {
          startTime: Timestamp.fromDate(startTime),
          endTime: Timestamp.fromDate(endTime),
        },
      }).response,
    select: data => data.activities,
    suspense: true,
  });

  return useMemo<DailyActivity[]>(() => {
    const activities = [...acts];

    // Add package completions to the activities
    packageSummary.forEach(pkg => {
      if (!pkg.assignmentName || !pkg.state?.completionTimestamp) return;

      const completed = Timestamp.toDate(pkg.state.completionTimestamp);
      if (isBefore(completed, startTime) || isBefore(endTime, completed)) return;

      activities.push({
        activityType: 'completed/assignment/' + pkg.assignmentName,
        correctActivities: 0,
        totalActivities: 0,
        topics: [],
        totalSeconds: 0,
        timestampHour: Timestamp.fromDate(startOfHour(completed)),
      });
    });

    // Add il target completions to the activities
    ilTargets.forEach(target => {
      if (!target.assignmentName || !target.startTimestamp || !target.completedTimestamp) return;
      const completed = Timestamp.toDate(target.completedTimestamp);
      if (isBefore(completed, startTime) || isBefore(endTime, completed)) return;

      activities.push({
        activityType: 'completed/il_target/' + target.assignmentName,
        correctActivities: 0,
        totalActivities: 0,
        topics: [],
        totalSeconds: 0,
        timestampHour: Timestamp.fromDate(startOfHour(completed)),
      });
    });

    if (activities.length === 0) return [];

    // sort the activities
    activities.sort((a, b) => {
      const diff = (b.timestampHour?.seconds || 0) - (a.timestampHour?.seconds || 0);
      if (diff === 0 && a.activityType !== b.activityType) {
        // always put completion first
        if (a.activityType.startsWith('completed/')) {
          return 1;
        }
        if (a.activityType.startsWith('assignments/')) {
          return 1;
        }
        return -1;
      }
      return diff;
    });

    const dailyActivities: DailyActivity[] = [];
    let currentDay: DailyActivity | undefined;
    for (const act of activities) {
      if (!act.timestampHour) continue;

      const entry = activityToEntry(act);
      if (!entry) continue;

      const day = startOfDay(Timestamp.toDate(act.timestampHour));

      if (!currentDay || !isEqual(currentDay.day, day)) {
        currentDay = { day, entries: [entry] };
        dailyActivities.push(currentDay);
        continue;
      }

      // The activity happened for the current day so now we need to add it.

      if (entry.type === 'completion') {
        // We  try to place completion activities directly after the activity that would have caused them
        let lastIndex = -1;
        if (entry.completionType === 'il_target') {
          lastIndex = currentDay.entries.findLastIndex(e => e.type === 'independentlearning');
        } else {
          lastIndex = currentDay.entries.findLastIndex(
            e => e.type === 'assignment' && e.assignmentName === entry.assignmentName,
          );
        }

        if (lastIndex !== -1) {
          currentDay.entries.splice(lastIndex, 0, entry);
        } else {
          currentDay.entries.push(entry);
        }
        continue;
      }

      // If the previous entry is the same type as this entry then add them together, otherwise add the new entry
      const prevEntry = currentDay.entries[currentDay.entries.length - 1];
      if (entry.type === 'independentlearning' && prevEntry.type === 'independentlearning') {
        // We can aggregate this entry with the previous one
        prevEntry.correctActivities += entry.correctActivities;
        prevEntry.totalActivities += entry.totalActivities;
        prevEntry.totalSeconds += entry.totalSeconds;
        entry.topics.forEach(prevEntry.topics.add, prevEntry.topics);
      } else if (
        entry.type === 'assignment' &&
        prevEntry.type === 'assignment' &&
        // Only aggregate actvities for the same assignment
        entry.assignmentName === prevEntry.assignmentName
      ) {
        prevEntry.correctActivities += entry.correctActivities;
        prevEntry.totalActivities += entry.totalActivities;
        prevEntry.totalSeconds += entry.totalSeconds;
      } else {
        // We cannot aggregate this entry with the previous one, add as is
        currentDay.entries.push(entry);
      }
    }

    return dailyActivities;
  }, [acts, ilTargets, packageSummary, startTime, endTime]);
};

const activityToEntry = (act: ListActivityFeedResponse_AggregateActivity): ActivityEntry | null => {
  if (act.activityType.startsWith('assignments/')) {
    return {
      type: 'assignment',
      assignmentName: act.activityType,
      correctActivities: act.correctActivities,
      totalActivities: act.totalActivities,
      totalSeconds: act.totalSeconds,
    };
  }

  if (act.activityType === 'independentlearning') {
    return {
      type: 'independentlearning',
      topics: new Set(act.topics),
      correctActivities: act.correctActivities,
      totalActivities: act.totalActivities,
      totalSeconds: act.totalSeconds,
    };
  }

  if (act.activityType.startsWith('completed/')) {
    const parts = act.activityType.split('/');
    if (parts.length <= 2) {
      console.error('Invalid completed activity type:', act.activityType);
      return null;
    }

    const completionType = parts[1] === 'assignment' ? 'assignment' : 'il_target';
    return {
      type: 'completion',
      completionType,
      assignmentName: parts.slice(2).join('/'),
    };
  }

  console.error('Unknown activity type:', act.activityType);
  return null;
};
