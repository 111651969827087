import { Box, BoxProps } from '@chakra-ui/react';

import { LevelBadge1 } from './LevelBadge1';
import { LevelBadge5 } from './LevelBadge5';
import { LevelBadge25 } from './LevelBadge25';
import { LevelBadge100 } from './LevelBadge100';

export type BadgeProps = BoxProps & {
  level: number;
};

export const LevelBadge = ({ level, ...rest }: BadgeProps) => {
  let Badge = null;
  switch (true) {
    case level === 0:
      Badge = LevelBadge1;
      break;
    case level % 100 === 0:
      Badge = LevelBadge100;
      break;
    case level % 25 === 0:
      Badge = LevelBadge25;
      break;
    case level % 5 === 0:
      Badge = LevelBadge5;
      break;
    default:
      Badge = LevelBadge1;
  }
  return (
    <Box
      filter={level < 1 ? 'grayscale(1)' : undefined}
      opacity={level < 1 ? 0.4 : undefined}
      {...rest}
    >
      <Badge level={level} />
    </Box>
  );
};
