import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { HandinStatus, useHandInStatus } from '@sparx/packageactivity';
import { usePackages } from 'api/packages';
import { useBackLink } from 'app/BackLink';
import { getHandinStatusStyling, StatusBadge } from 'components/CompletionBadge';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { Paginator } from 'components/pagination/Paginator';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PkgILContent, PkgILLevel, PkgStudentLevel } from 'utils/annotations';
import { CombinedIcon, useResourceSubject } from 'utils/subjects';
import { LevelChip } from 'views/independentlearning/components/LevelChip';

export const PreviousTasks = () => {
  const { data: allPackages = [] } = usePackages({ suspense: true });
  const previousPackages = useMemo(
    () =>
      allPackages
        .filter(p => p.type === 'independentlearning')
        .sort((a, b) => (b.startTimestamp?.seconds || 0) - (a.startTimestamp?.seconds || 0)),
    [allPackages],
  );

  useBackLink('/independentlearning');

  return (
    <PageContainer>
      <Box pt={2}>
        <PageTitle
          title={
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/independentlearning" textDecoration="underline">
                  Independent Learning
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>Previous Tasks</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          }
        />
      </Box>
      <VStack spacing={[2, 3]} align="stretch">
        <Text>Check progress and continue with tasks you&apos;ve previously created.</Text>
        {previousPackages.length === 0 ? (
          <Text textAlign="center" color="gray.400">
            You don&apos;t have any previous tasks yet.
          </Text>
        ) : (
          <Paginator items={previousPackages} keyGetter={p => p.name} perPage={6}>
            {p => <PackageRow pkg={p} />}
          </Paginator>
        )}
      </VStack>
    </PageContainer>
  );
};

export const PackageRow = ({ pkg }: { pkg: Package }) => {
  const { status, percentages } = useHandInStatus(pkg.state?.completion, undefined);
  const percentageCorrect = percentages.C.roundedPercentage;
  const styling = getHandinStatusStyling(status);
  const complete = status === HandinStatus.Complete;

  const subject = useResourceSubject(pkg.annotations[PkgILContent] || '', {
    suspense: true,
  });

  const TaskIcon =
    packageName(pkg) != 'Personal practice' ? subject?.iconComponent || CombinedIcon : CombinedIcon;

  return (
    <Flex
      alignItems="center"
      as={LinkBox}
      _hover={{
        boxShadow: 'elevationMedium',
      }}
      transition="box-shadow 0.2s"
      boxShadow="elevationLow"
      borderRadius="lg"
      bg="white"
      overflow="hidden"
      pr={[3, 5]}
    >
      <Flex
        borderLeftWidth={10}
        borderLeftColor={styling.color}
        flex={1}
        justifyContent="space-between"
        flexDirection={{ base: 'column', lg: 'row' }}
        mr={[3, 5]}
      >
        <Box pt={4} pb={{ base: 2, lg: 4 }} pl={[4, 5]}>
          <HStack>
            {TaskIcon && <TaskIcon height="34px" width="34px" mr={2} />}
            <Box>
              <Text color="gray.500" fontSize={{ base: 'sm', md: 'md' }}>
                <PrettyTimestamp fmt="EEEE do MMMM h:mmaaa">{pkg.startTimestamp}</PrettyTimestamp>
              </Text>
              <Text fontWeight="bold" color="blue.800" fontSize={{ base: 'md', md: 'lg' }}>
                <LinkOverlay as={Link} to={`/${pkg.name}`}>
                  {packageName(pkg)}
                </LinkOverlay>
              </Text>
            </Box>
          </HStack>
        </Box>
        <Flex
          px={{ base: 4, sm: 5, lg: 0 }}
          pb={{ base: 4, lg: 0 }}
          justifyContent="flex-end"
          w="100%"
          alignItems="center"
          flexDirection={{ base: 'row-reverse', lg: 'row' }}
          fontSize={{ base: 'md', md: 'lg' }}
        >
          {status === HandinStatus.NotStarted || status === HandinStatus.NotStartedLate ? (
            <Text color="gray.400">Not started</Text>
          ) : (
            <Text fontWeight="bold" color={styling.color}>
              {percentageCorrect}%
            </Text>
          )}
          <Box w={4} />
          <StatusBadge completion={pkg.state?.completion} due={pkg.endTimestamp} />
          <Box w={4} />
          <LevelChip
            // match height with the StatusBadge
            h="36px"
            level={pkg.annotations[PkgILLevel] || pkg.annotations[PkgStudentLevel]}
          />
        </Flex>
      </Flex>
      <Center
        bg={complete ? 'green.100' : 'gray.100'}
        borderRadius="full"
        w={10}
        h={10}
        fontSize="lg"
        color={complete ? 'green.800' : 'gray.800'}
      >
        <FontAwesomeIcon icon={complete ? faCheck : faChevronRight} />
      </Center>
    </Flex>
  );
};

const packageName = (pkg: Package) => {
  return pkg.title || 'Personal practice';
};
