import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faMagnifyingGlass, faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { useUserType } from 'api/sessions';
import { getAssetUrlFromBucket } from 'app/storage';
import React, { useState } from 'react';
import { validate as validateUUID } from 'uuid';

export const uploadedAssetProvider = (name: string) => <UploadedAsset uploadedAssetName={name} />;

interface UploadedAssetProps {
  uploadedAssetName: string;
}

export const UploadedAsset = ({ uploadedAssetName }: UploadedAssetProps) => {
  const { isTeacher } = useUserType();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rotation, setRotation] = useState(0);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['uploadedasset', uploadedAssetName],
    queryFn: async () => getAssetUrl(uploadedAssetName),
    staleTime: Infinity,
    cacheTime: 0,
    retry: false,
  });

  if (isLoading)
    return (
      <Box textAlign="center">
        <Spinner color="blue.500" />
      </Box>
    );
  if (isError || !data)
    return (
      <Text textAlign="center" color="gray.500">
        Error loading image
      </Text>
    );

  return (
    <Box position="relative">
      <img src={data} alt={''} />
      {isTeacher && (
        <IconButton
          colorScheme="blackAlpha"
          aria-label="Zoom"
          position="absolute"
          right={0}
          top={0}
          onClick={onOpen}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </IconButton>
      )}
      <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom={true} isCentered={true}>
        <ModalOverlay />
        <ModalContent padding={2} maxWidth="2000px" height="100%">
          <ModalCloseButton color="white" bg="RGBA(0, 0, 0, 0.6)" />
          <Box
            px={3}
            width="100%"
            height="100%"
            overflowX="auto"
            overflowY="auto"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <img
              src={data}
              alt={''}
              style={{
                transform: `rotate(${rotation}deg)`,
                borderRadius: 10,
                margin: 'auto',
                maxWidth: '80vw',
                maxHeight: '80vh',
              }}
            />
            <HStack spacing={2} justifyContent="center" mt={2}>
              <IconButton aria-label="Rotate left" onClick={() => setRotation(r => r - 90)}>
                <FontAwesomeIcon icon={faRotateLeft} />
              </IconButton>
              <IconButton aria-label="Rotate right" onClick={() => setRotation(r => r + 90)}>
                <FontAwesomeIcon icon={faRotateRight} />
              </IconButton>
            </HStack>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export const getAssetUrl = async (src: string) => {
  if (src.startsWith('schools/')) {
    return await getAssetUrlFromBucket(src);
  } else if (validateUUID(src)) {
    // Fall back to the old assets URL if the setting is not available.
    const assetsURL = window.settings?.ASSETS_URL || 'https://assets.sparxhomework.uk';
    return assetsURL + '/' + src;
  } else {
    return src;
  }
};
