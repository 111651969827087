import {
  CreateSyncPlanRequest,
  PreviewSyncSchoolV2Response,
  StudentClassResolutionPair,
  SyncClass,
  SyncSchoolRequest,
} from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync';
import { Class, Subject } from '@sparx/api/apis/sparx/misintegration/wondewitch/v1/wondewitch';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Code } from '@sparx/api/google/rpc/code';
import { StudentGroupType } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import {
  extractFieldViolationsFromInvalidArgumentStatus,
  extractViolationsFromFailedPreconditionStatus,
  getFirstStatusFromRpcError,
  isRpcError,
} from '@sparx/api/utils/rpc-error';
import mathsIntroImage1 from '@sparx/mis-sync-import/src/assets/maths-sync-intro-01.png';
import mathsIntroImage2 from '@sparx/mis-sync-import/src/assets/maths-sync-intro-02.png';
import mathsShowAllSubjectsImage from '@sparx/mis-sync-import/src/assets/maths-sync-showall.png';
import readerIntroImage1 from '@sparx/mis-sync-import/src/assets/reader-sync-intro-01.png';
import readerIntroImage2 from '@sparx/mis-sync-import/src/assets/reader-sync-intro-02.png';
import readerShowAllSubjectsImage from '@sparx/mis-sync-import/src/assets/reader-sync-show-all-subjects.png';
import scienceIntroImage1 from '@sparx/mis-sync-import/src/assets/science-sync-intro-01.png';
import scienceIntroImage2 from '@sparx/mis-sync-import/src/assets/science-sync-intro-02.png';
import scienceShowAllSubjectsImage from '@sparx/mis-sync-import/src/assets/science-sync-showall.png';
import { SyncConfig } from '@sparx/mis-sync-import/src/MisSyncImport/context/config/types';
import {
  DEFAULT_ENGLISH_SUBJECT_SUBSTRINGS,
  DEFAULT_SCIENCE_SUBJECT_SUBSTRINGS,
  DEFAULT_SUBJECT_SUBSTRINGS,
  SCIENCE_SUBJECT_SUBSTRINGS_TO_EXCLUDE,
  SparxClass,
  WONDE_REGISTRATION_GROUP_SUBJECT_ID,
  WondeClassDetailsFromSparxClass,
  WondeData,
} from '@sparx/mis-sync-import/src/types';
import { getUnixTime } from 'date-fns';

const getLastPart = (input: string, splitChar: string): string => {
  const parts = input?.split(splitChar);
  if (parts.length === 1) {
    return '';
  }
  return parts[parts.length - 1];
};

export const schoolIDFromName = (schoolName?: string) => getLastPart(schoolName ?? '', '/');
export const groupIdFromName = (groupName: string) => getLastPart(groupName, '/');

export const deleteFromSet = <T>(currentSet: Set<T>, toDelete: T): Set<T> => {
  const newSet = new Set(currentSet);
  newSet.delete(toDelete);
  return newSet;
};

/**
 * Provide a function for testing whether a subject matches the defaults. Avoids issues with
 * differing approaches e.g. forgetting to lowercase before testing.
 */
export const isMathsSubjectNameDefault = (subjectName: string): boolean =>
  DEFAULT_SUBJECT_SUBSTRINGS.some((substring: string) =>
    subjectName.toLowerCase().includes(substring.toLowerCase()),
  );

/**
 * Returns if a subject looks like an english subject.
 */
export const isEnglishSubjectName = (subjectName: string): boolean =>
  DEFAULT_ENGLISH_SUBJECT_SUBSTRINGS.some((substring: string) =>
    subjectName.toLowerCase().includes(substring.toLowerCase()),
  );

/**
 * Returns if a subject looks like an science subject.
 */
export const isScienceSubjectName = (subjectName: string): boolean =>
  DEFAULT_SCIENCE_SUBJECT_SUBSTRINGS.some((substring: string) =>
    subjectName.toLowerCase().includes(substring.toLowerCase()),
  ) &&
  !SCIENCE_SUBJECT_SUBSTRINGS_TO_EXCLUDE.some((substring: string) =>
    subjectName.toLowerCase().includes(substring.toLowerCase()),
  );

/**
 * Returns a filter function to call on a group's subject name based on the given group subject.
 * Will return true if, based on the group's name, we think it belongs to the group subject.
 */
export const filterGroupBySubject = (groupSubject: StudentGroupType) => {
  switch (groupSubject) {
    case StudentGroupType.CLASS_ENGLISH:
      return isEnglishSubjectName;
    case StudentGroupType.CLASS_SCIENCE:
      return isScienceSubjectName;
    default:
      return isMathsSubjectNameDefault;
  }
};

export type SystemOptions = {
  dataTheme: string;
  system: string;
  introImage1: string | null;
  introImage2: string | null;
  showAllSubjectsImage: string | null;
  supportLink: string;
  rolloverSupportLink: string | undefined;
};

/**
 * Get the system options for a given group subject.
 * @param groupSubject
 */
export const getSystemOptions = (groupSubject: StudentGroupType): SystemOptions => {
  switch (groupSubject) {
    case StudentGroupType.CLASS_ENGLISH:
      return {
        dataTheme: 'reader',
        system: 'Sparx Reader',
        introImage1: readerIntroImage1,
        introImage2: readerIntroImage2,
        showAllSubjectsImage: readerShowAllSubjectsImage,
        supportLink:
          'https://support.sparxreader.com/docs/ticket-deflector/how-can-we-help-you?p=r0',
        rolloverSupportLink:
          'https://support.sparxreader.com/v1/docs/syncing-classes-towards-the-end-of-the-academic-year',
      };
    case StudentGroupType.CLASS_SCIENCE:
      return {
        dataTheme: 'science',
        system: 'Sparx Science',
        introImage1: scienceIntroImage1,
        introImage2: scienceIntroImage2,
        showAllSubjectsImage: scienceShowAllSubjectsImage,
        supportLink: 'mailto:schoolsupport@sparx.co.uk',
        rolloverSupportLink: undefined,
      };
    case StudentGroupType.CLASS:
      return {
        dataTheme: 'maths',
        system: 'Sparx Maths',
        introImage1: mathsIntroImage1,
        introImage2: mathsIntroImage2,
        showAllSubjectsImage: mathsShowAllSubjectsImage,
        supportLink: 'https://support.sparx.co.uk/docs/ticket-deflector/how-can-we-help-you',
        rolloverSupportLink:
          'https://support.sparx.co.uk/docs/syncing-classes-towards-the-end-if-the-academic-year',
      };
    default:
      console.error('Unknown group subject', groupSubject);
      return {
        dataTheme: 'unknown',
        system: 'Unknown',
        introImage1: null,
        introImage2: null,
        showAllSubjectsImage: null,
        supportLink: 'unknown',
        rolloverSupportLink: undefined,
      };
  }
};

/**
 * Get the product subject required by wondesync from the group type.
 */
export const productSubjectFromGroupType = (groupType: StudentGroupType) => {
  switch (groupType) {
    case StudentGroupType.CLASS:
      return 'MATHS';
    case StudentGroupType.CLASS_ENGLISH:
      return 'ENGLISH';
    case StudentGroupType.CLASS_SCIENCE:
      return 'SCIENCE';
    default:
      return 'MATHS';
  }
};

/**
 * Get a product subject string from the given product
 */
export const productSubjectFromProductType = (product: Product) => {
  switch (product) {
    case Product.SPARX_MATHS:
      return 'MATHS';
    case Product.SPARX_READER:
      return 'ENGLISH';
    case Product.SPARX_SCIENCE:
      return 'SCIENCE';
    default:
      return 'UNKNOWN';
  }
};

/**
 * If the group has no end date or the end date is after the current date, then the class is
 * considered active. Otherwise it is inactive.
 */
export const isGroupExpired = (group: Group): boolean =>
  !!group.endDate && getUnixTime(new Date()) > (group.endDate.seconds ?? 0);

/**
 * Converts SyncConfig to a SyncSchoolRequest which can be sent to the preview endpoint.
 */
export const convertSyncConfigToSyncSchoolRequest = (
  schoolId: string,
  syncConfig: SyncConfig,
  groupType: StudentGroupType,
  defaultConflictResolutionsByProduct: Map<StudentGroupType, Record<string, string>>,
): SyncSchoolRequest => {
  const classes: SyncClass[] = [];
  for (const existingClass of syncConfig.existingClasses) {
    if (
      syncConfig.classesToRemove.includes(existingClass.name) ||
      existingClass.type === StudentGroupType.TUTORGROUP
    ) {
      continue;
    }

    const wondeId = getWondeIDFromExternalID('group', existingClass.externalId);
    if (!wondeId) {
      console.error('Could not get Wonde ID from external ID', existingClass.externalId);
      continue;
    }

    const classAssignedToWondeID = syncConfig.classMatches[wondeId];
    const studentGroupId = classAssignedToWondeID
      ? groupIdFromName(classAssignedToWondeID.name)
      : '';

    classes.push({
      wondeId,
      studentGroupId,
      yearGroupId: existingClass.configuredYearGroupId || existingClass.yearGroupId,
      productSubject: productSubjectFromGroupType(existingClass.type),
    });
  }

  const newProductSubject = productSubjectFromGroupType(groupType);

  for (const newClass of syncConfig.classesToAdd) {
    const matchingGroup = syncConfig.classMatches[newClass.id]?.name;
    classes.push({
      wondeId: newClass.id,
      studentGroupId: matchingGroup ? groupIdFromName(matchingGroup) : '',
      yearGroupId: newClass.configuredYearGroupId,
      productSubject: newProductSubject,
    });
  }

  // Add all the manual conflict resolutions:
  const studentClassResolutionPairs: StudentClassResolutionPair[] = Object.entries(
    syncConfig.conflictResolutions,
  ).map(([studentWondeId, classWondeId]: [string, string]) => ({ studentWondeId, classWondeId }));

  // Add default resolutions (i.e. keeping student in their current class) including for other products, unless the user
  // has overridden it with a manual resolution:
  for (const [product, defaultResolutions] of defaultConflictResolutionsByProduct.entries()) {
    for (const [studentWondeId, defaultResolution] of Object.entries(defaultResolutions)) {
      if (product !== groupType || syncConfig.conflictResolutions[studentWondeId] === undefined) {
        studentClassResolutionPairs.push({ studentWondeId, classWondeId: defaultResolution });
      }
    }
  }

  return {
    async: false,
    schoolId,
    classes,
    studentClassResolutionPairs,
  };
};

export const convertSyncConfigToCreateSyncPlanRequest = (
  schoolId: string,
  syncConfig: SyncConfig,
  groupType: StudentGroupType,
  defaultConflictResolutionsByProduct: Map<StudentGroupType, Record<string, string>>,
): CreateSyncPlanRequest => {
  const req = convertSyncConfigToSyncSchoolRequest(
    schoolId,
    syncConfig,
    groupType,
    defaultConflictResolutionsByProduct,
  );

  // Get a list of classWondeIDs for the current product
  const resolutionClassWondeIDsForProduct = req.classes.map(c => c.wondeId);

  // Create the list of studentClassResolutionPairs for the current product - we don't want to send
  // any that are for other products
  const studentClassResolutionPairs = req.studentClassResolutionPairs.filter(pair =>
    resolutionClassWondeIDsForProduct.includes(pair.classWondeId),
  );

  return {
    schoolName: 'schools/' + schoolId,
    classes: req.classes,
    studentClassResolutionPairs,
    productFilter: [productSubjectFromGroupType(groupType)],
    dryRun: false,
    skipDemographics: false,
    forceIfUnauthorised: false,
  };
};

/*
 * Try to parse a year group number from the name. Return undefined if none exists.
 * @param name
 */
export const parseYearGroup = (name: string): number | undefined => {
  const n = parseInt(name, 10);
  // If the name provided is a number, just return it
  if (!isNaN(n)) {
    return n;
  }
  // Split the name by spaces and find the first value that is a number and return that.
  // E.g. "Year 11" will split into ["Year", "11"] and will return 11 as a number.
  const n2 = name
    .split(' ')
    .map(x => parseInt(x, 10))
    .find(x => !isNaN(x));
  return n2;
};

/**
 * Extracts the Wonde ID from an externalID.
 * @param kind is required to match the type of ID
 * @param externalID
 * @returns undefined if the externalID is absent, malformed, or not for Wonde.
 */
export const getWondeIDFromExternalID = (
  kind: 'group' | 'student' | 'parent',
  externalID: string | undefined,
) => {
  if (!externalID) {
    return undefined;
  }
  const parts = externalID.split('_');
  if (parts.length !== 4 || parts[0] !== 'Wonde') {
    return undefined;
  }
  if (kind === 'group' && !(parts[1] === 'TeachingGroup' || parts[1] === 'TutorGroup')) {
    return undefined;
  }
  if (kind === 'student' && !(parts[1] === 'Student')) {
    return undefined;
  }
  if (kind === 'parent' && !(parts[1] === 'Parent')) {
    return undefined;
  }
  return parts[3];
};

/**
 * Extracts the Wonde ID from a Group's externalID.
 * @param group
 * @returns undefined if the externalID is absent, malformed, or not for Wonde.
 */
export const wondeIDOfGroup = (group: Group): string | undefined =>
  getWondeIDFromExternalID('group', group.externalId);

/**
 * Dummy subject object corresponding to the 'Registration Group' option with `active` set to
 * `false`.
 */
export const WONDE_REGISTRATION_GROUPS_SUBJECT: Subject = {
  active: false,
  code: WONDE_REGISTRATION_GROUP_SUBJECT_ID,
  id: WONDE_REGISTRATION_GROUP_SUBJECT_ID,
  misId: WONDE_REGISTRATION_GROUP_SUBJECT_ID,
  name: 'Registration Group',
};

/**
 * Dummy subject object for no subject provided option with `active` set to `false`.
 */
export const WONDE_NO_SUBJECT: Subject = {
  active: true,
  code: '',
  id: '',
  misId: '',
  name: 'No subject',
};

/**
 * Parses a student group ID from a student group resource name
 * @param groupName
 */
export const getStudentGroupIDFromGroupName = (groupName: string): string | undefined => {
  const groupNameSplit = groupName.split('/');
  return groupNameSplit.length === 4 ? groupNameSplit[3] : undefined;
};

/**
 * Returns a new set containing the elements that are in both setA and setB. (Set has an intersection method, but it is
 * not supported in all browsers)
 * @param setA
 * @param setB
 */
export const setIntersection = <T>(setA?: Set<T>, setB?: Set<T>): Set<T> => {
  if (!setA || !setB) {
    return new Set<T>();
  }
  const intersection = new Set<T>();
  for (const elem of setB) {
    if (setA.has(elem)) {
      intersection.add(elem);
    }
  }
  return intersection;
};

// Given a list of strings returns a string with the elements joined by a comma and 'and' before the last element.
export const joinList = (list: string[]) => {
  if (list.length === 0) {
    return '';
  }
  if (list.length === 1) {
    return list[0];
  }
  const lastElement = list.pop();
  return `${list.join(', ')} and ${lastElement}`;
};

type WondeSyncError =
  | MissingConflictResolution
  | DuplicateUPNs
  | Unauthorized
  | YearGroupChanges
  | PermissionDenied
  | RectificationConflicts
  | DuplicateClassNames;

export type MissingConflictResolution = {
  type: 'MISSING_CONFLICT_RESOLUTION';
  message: string;
};

export type DuplicateUPNs = {
  type: 'DUPLICATE_UPNS';
  duplicates: {
    upn: string;
    message: string;
  }[];
};

type Unauthorized = {
  type: 'UNAUTHORIZED';
};

type YearGroupChanges = {
  type: 'YEAR_GROUP_CHANGES';
};

type PermissionDenied = {
  type: 'PERMISSION_DENIED';
};

export type RectificationConflicts = {
  type: 'RECTIFICATION_CONFLICTS';
  conflicts: {
    externalID: string;
    message: string;
  }[];
};

export type DuplicateClassNames = {
  type: 'DUPLICATE_CLASS_NAMES';
  duplicates: {
    name: string;
    message: string;
  }[];
};

export const getWondeSyncErrors = (error: Error | null): WondeSyncError[] => {
  if (!error) {
    return [];
  }

  const errors: WondeSyncError[] = [];

  const status = getFirstStatusFromRpcError(error);
  if (!status) {
    // The permission denied errors I was seeing returned in prod didn't have the required fields
    // to have a status parsed.
    if (isRpcError(error) && error.code === 'PERMISSION_DENIED') {
      errors.push({ type: 'PERMISSION_DENIED' });
    } else {
      return [];
    }
  }

  switch (status?.code) {
    case Code.INVALID_ARGUMENT:
      {
        const violations = extractFieldViolationsFromInvalidArgumentStatus(status).flat();
        const studentClassResolutionPairsError = violations.find(
          v => v.field === 'student_class_resolution_pairs',
        );
        if (studentClassResolutionPairsError) {
          errors.push({
            type: 'MISSING_CONFLICT_RESOLUTION',
            message: studentClassResolutionPairsError.description,
          });
        }
      }
      break;
    case Code.FAILED_PRECONDITION: {
      const violations = extractViolationsFromFailedPreconditionStatus(status).flat();
      if (violations.some(v => v.type === 'UNAUTHORISED')) {
        errors.push({ type: 'UNAUTHORIZED' });
      }

      if (violations.some(v => v.type === 'YEAR_GROUP_CHANGES')) {
        errors.push({ type: 'YEAR_GROUP_CHANGES' });
      }

      const duplicateUPNViolations = violations.filter(v => v.type === 'DUPLICATE_UPN');
      if (duplicateUPNViolations.length > 0) {
        errors.push({
          type: 'DUPLICATE_UPNS',
          duplicates: duplicateUPNViolations.map(v => ({ upn: v.subject, message: v.description })),
        });
      }

      const rectificationConflictViolations = violations.filter(
        v => v.type === 'RECTIFICATION_CONFLICTS',
      );
      if (rectificationConflictViolations.length > 0) {
        errors.push({
          type: 'RECTIFICATION_CONFLICTS',
          conflicts: rectificationConflictViolations.map(v => ({
            externalID: v.subject,
            message: v.description,
          })),
        });
      }

      const duplicateClassNameViolations = violations.filter(
        v => v.type === 'DUPLICATE_CLASS_NAME',
      );
      if (duplicateClassNameViolations.length > 0) {
        errors.push({
          type: 'DUPLICATE_CLASS_NAMES',
          duplicates: duplicateClassNameViolations.map(v => ({
            name: v.subject,
            message: v.description,
          })),
        });
      }

      break;
    }
    case Code.PERMISSION_DENIED:
      errors.push({ type: 'UNAUTHORIZED' });
      break;
    default:
      break;
  }

  return errors;
};

/**
 * Given a list of student UPNs that have conflicts, find the subjects of the existing classes that those students are
 * in
 * @param misData Wonde data about the school
 * @param existingClasses The existing classes in the school
 * @param upnsWithConflicts A list of student UPNs that have conflicts
 */
export const getSubjectsWithUPNConflicts = (
  misData: WondeData,
  existingClasses: SparxClass[],
  upnsWithConflicts: string[],
): Set<StudentGroupType> => {
  const subjectsWithUPNConflicts: Set<StudentGroupType> = new Set();

  // Get the list of UPNs that are in conflict:
  const upns: Set<string> = new Set(upnsWithConflicts);

  const existingClassesByWondeID = new Map<string, SparxClass>();
  for (const c of existingClasses) {
    const wondeID = wondeIDOfGroup(c);
    if (wondeID) {
      existingClassesByWondeID.set(wondeID, c);
    }
  }

  for (const c of misData.wondeClasses) {
    const existingClass = existingClassesByWondeID.get(c.id);
    if (!existingClass) {
      continue;
    }
    const subject = existingClass.type;
    for (const s of c.students) {
      const studentUPN = s.educationDetails?.upn;
      if (studentUPN && upns.has(studentUPN)) {
        subjectsWithUPNConflicts.add(subject);
        break;
      }
    }
  }

  return subjectsWithUPNConflicts;
};

export const hasChanges = (preview: PreviewSyncSchoolV2Response) => {
  const hasClassChanges =
    preview.newClasses.length > 0 ||
    preview.removedClasses.length > 0 ||
    preview.modifiedClasses.length > 0;

  const hasStudentChanges =
    preview.newStudents.length > 0 ||
    preview.removedStudents.length > 0 ||
    preview.modifiedStudents.length > 0 ||
    preview.unexpiredStudents.length > 0;

  const hasInvalidStudents = preview.invalidStudents.length > 0;

  return { hasClassChanges, hasStudentChanges, hasInvalidStudents };
};

/**
 * Joins a list of strings in to a list that looks like "a, b and c"
 * @param strings
 */
export const joinStringsWithAnd = (strings: string[]): string => {
  let str = '';
  for (let i = 0; i < strings.length; i++) {
    if (i > 0) {
      if (i === strings.length - 1) {
        str += ' and ';
      } else {
        str += ', ';
      }
    }
    str += strings[i];
  }
  return str;
};

const isSparxGroup = (group: Class | Group) => {
  return 'type' in group;
};

/**
 * Given a list of Sparx groups and Wonde classes, return a map of group names to the number of
 * times they appear in the list.
 * @param sparxGroups
 * @param wondeClasses
 * @param studentGroupIDsToRemove
 * @param getWondeClassDetailsFromSparxClass
 */
export const createGroupNameFrequencyMap = (
  sparxGroups: Group[],
  wondeClasses: Class[],
  studentGroupIDsToRemove: string[],
  getWondeClassDetailsFromSparxClass: (sparxClass: Group) => WondeClassDetailsFromSparxClass,
) =>
  [sparxGroups, wondeClasses].flat().reduce<Record<string, number>>((acc, c) => {
    let groupName = '';
    if (isSparxGroup(c) && !studentGroupIDsToRemove.includes(c.name)) {
      const { displayName } = getWondeClassDetailsFromSparxClass(c);
      groupName = displayName;
    } else {
      groupName = c.name;
    }
    acc[groupName] = (acc[groupName] || 0) + 1;
    return acc;
  }, {});
