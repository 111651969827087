import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';

export const packageName = (pkg: Package) => {
  if (pkg.type === 'demo:testcontent') {
    return 'Test Content Package';
  } else if (pkg.type.startsWith('demo:')) {
    return 'Demo homework';
  }
  return 'Homework';
};
