import styles from './ColourOverlay.module.css';
import { overlayColourOptions } from './colours';

type ColourOverlayProps = {
  hexColour?: string;
};

export const ColourOverlay = ({ hexColour = '#FFFFFF' }: ColourOverlayProps) => (
  <div
    className={styles.ColourOverlay}
    style={{
      backgroundColor: Object.values(overlayColourOptions).includes(hexColour)
        ? hexColour
        : '#FFFFFF',
    }}
  />
);
