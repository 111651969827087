import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { useSparxQuestionContext } from '../../question/SparxQuestionContext';
import { INumberFieldElement } from '../../question/types';
import { useClickAwayListener } from '../../utils/clickaway';
import {
  useGetBaseOffset,
  useKeepElementWithinElement,
} from '../../utils/useKeepElementWithinElement';
import { KeypadButtons } from './KeypadButtons';
import styles from './NumericKeypad.module.css';

type NumericKeypadProps = {
  inputElement: INumberFieldElement;
  // shouldPosition is a boolean indicating whether this component should try to set the x position
  // of the keypad to line up with the relevant input
  shouldPosition?: boolean;
  // when repositionTrigger is incremented, the keypad will reposition itself
  repositionTrigger?: number;
  parentElement?: HTMLElement | null;
  // if always open is true, the keypad will always be open
  alwaysOpen?: boolean;
};

/**
 * NumericKeypad renders a keypad.
 * NumericKeypad handles closing the keypad, and positioning it correctly. It renders KeypadButtons
 * to handle the logic of inputting an answer
 */
export const NumericKeypad = ({
  inputElement,
  shouldPosition,
  repositionTrigger,
  parentElement,
  alwaysOpen,
}: NumericKeypadProps) => {
  const { setOpenElementRef, scale, focussedInputRef } = useSparxQuestionContext();
  const closeKeypad = useCallback(() => {
    if (alwaysOpen) {
      return;
    }
    // only close the keypad if this element is the currently open element. This avoids a race condition
    // where, after selecting a new element, that element's keypad is closed.
    setOpenElementRef(c => (c === inputElement.ref ? '' : c));
  }, [setOpenElementRef, inputElement.ref, alwaysOpen]);

  useEffect(() => {
    // if the open element ref isn't this element, close the keypad
    if (focussedInputRef !== inputElement.ref) {
      closeKeypad();
    }
  }, [closeKeypad, focussedInputRef, inputElement.ref]);

  useClickAwayListener(
    closeKeypad,
    true,
    ev => {
      if (alwaysOpen) {
        return false;
      }
      if (!(ev.target instanceof Element)) {
        return true;
      }
      if (ev.target.closest(`[data-number-field]`)) {
        return false;
      }
      if (ev.target.closest(`[data-numeric-keypad="${inputElement.ref}"]`)) {
        return false;
      }
      // don't close if we are clicking on the input reset button (for fraction hints)
      if (ev.target.closest(`[data-reset-button]`)) {
        return false;
      }
      return true;
    },
    // we need to listen to mousedown, not click, as the input moves when it is focused, so the click
    // event is often not fired on the input as the mouse is no longer on it by the time the click
    // has finished (mouse up)
    'mousedown',
  );

  const getTextInputDomElement = useCallback(
    (ref: string) => document.querySelector(`[data-number-field="${ref}"]`),
    [],
  );

  const keypadRef = useRef<HTMLDivElement | null>(null);
  const textFieldRef = useRef<Element | null>(getTextInputDomElement(inputElement.ref));

  // re-find the dom element whenever the inputElement ref changes
  useEffect(() => {
    textFieldRef.current = getTextInputDomElement(inputElement.ref);
  }, [inputElement.ref, getTextInputDomElement]);

  const { offset, recalculate } = useKeepElementWithinElement(
    keypadRef.current,
    parentElement || null,
    useGetBaseOffset(textFieldRef.current, keypadRef.current, parentElement),
    scale,
    !!shouldPosition,
  );

  // recalculate the offset whenever the selected input changes or when a reposition is triggered
  useEffect(() => {
    recalculate();
  }, [recalculate, inputElement.ref, repositionTrigger]);

  return (
    <div
      className={classNames({
        [styles.Keypad]: true,
      })}
      data-numeric-keypad={inputElement.ref}
      ref={keypadRef}
      style={{ left: offset }}
    >
      <KeypadButtons element={inputElement} />
    </div>
  );
};
