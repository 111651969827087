import { faCheck, faMicrophone, faMinus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import classNames from 'classnames';
import * as React from 'react';

import { CorrectIcon } from '../components/CorrectIcon';
import { TextDiffComparison, TextDiffDetail } from '../components/TextFieldDiffElements';
import styles from '../question/SparxQuestion.module.css';
import {
  GapEvaluation,
  LayoutElementProps,
  useSparxQuestionContext,
} from '../question/SparxQuestionContext';
import { ITextFieldElement } from '../question/types';
import { isGapCorrect } from '../utils/isGapCorrect';
import { pasteHandler } from '../utils/paste';
import { useEnterInputBehaviour } from '../utils/use-enter-input-behaviour';
import { MarkdownNode } from './TextElement';

export const TextFieldElement = ({ element }: LayoutElementProps<ITextFieldElement>) => {
  const context = useSparxQuestionContext();
  const value = context.input.text_fields?.[element.ref]?.value;
  const useTextArea = element.text_area;
  const showDiff = Boolean(element.diff);

  // If diff is present use max of 500 or diff length * 2.
  const maxLength = Math.max(500, (element.diff || '').length * 2);

  const {
    show: showCorrect,
    correct,
    correction,
    evaluation,
    hasDetail,
  } = isGapCorrect(element.ref, context.gapEvaluations, context.questionMarkingMode);

  const inputRef = React.useRef<HTMLElement | null>(null);
  useEnterInputBehaviour(inputRef.current, { nextInputAction: 'open' });

  const inputProps = {
    className: classNames(
      styles.TextField,
      styles.TextFieldText,
      !value && !context.readOnly && styles.TextFieldInvalid,
      context.insightsMode === 'presentation' && styles.TextFieldInsightsPresentation,
      showDiff && styles.TextFieldDiff,
    ),
    placeholder: context.insightsMode === 'print' ? '' : 'Enter answer...',
    value: value || '',
    readOnly: context.readOnly,
    ref: (el: HTMLElement | null) => {
      inputRef.current = el;
    },
    ['data-ref']: element.ref,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      context.sendAction({
        action: 'set_text',
        ref: element.ref,
        value: e.target.value,
      }),
    ...pasteHandler(element, useTextArea ? 'textarea' : 'text', context),
  };

  return (
    <div className={useTextArea ? styles.TextAreaContainer : undefined}>
      <div className={classNames(styles.TextFieldWrapper, useTextArea && styles.TextAreaWrapper)}>
        {showDiff && <TextDiffComparison element={element} value={value} inputRef={inputRef} />}
        <div className={styles.TextFieldComponent}>
          {useTextArea ? (
            <textarea
              {...inputProps}
              // Prevent spell checking in diff mode
              spellCheck={!showDiff}
              autoCapitalize={showDiff ? 'off' : undefined}
              autoCorrect={showDiff ? 'off' : undefined}
              data-gramm={showDiff ? 'false' : undefined} // Prevent grammarly in diff mode
              // End diff settings
              maxLength={maxLength}
              rows={context.insightsMode === 'presentation' ? 1 : undefined}
              onKeyDown={e => {
                // Don't submit unless ctrl is pressed
                if (e.key === 'Enter') {
                  if (!e.ctrlKey && !e.metaKey) {
                    e.stopPropagation();
                  } else {
                    e.currentTarget?.blur();
                  }
                }
              }}
            />
          ) : (
            <>
              <input {...inputProps} />
              {context.trialTextFieldSpeechToText && !context.readOnly && (
                <VoiceToText
                  setText={text =>
                    context.sendAction({
                      action: 'set_text',
                      ref: element.ref,
                      value: text,
                    })
                  }
                />
              )}
            </>
          )}
          {(!useTextArea || showDiff) && showCorrect && (
            <CorrectIcon correct={correct} analyticsAnswerType="text-field" />
          )}
        </div>
        {showCorrect && correction && (
          <div className={styles.TextFieldWrapperCorrection}>
            <FontAwesomeIcon icon={faCheck} />
            {correction}
          </div>
        )}
      </div>
      {!showDiff && showCorrect && hasDetail && <MarkingDetail evaluation={evaluation} />}
      {showDiff && showCorrect && hasDetail && (
        <TextDiffDetail
          evaluation={evaluation}
          originalValue={element.diff || ''}
          value={value || ''}
        />
      )}
    </div>
  );
};

const MarkingDetail = ({ evaluation }: { evaluation: GapEvaluation }) => {
  if (evaluation?.additionalData?.['aimarked/feedbackVersion'] === '2') {
    return <MarkingDetailV2 evaluation={evaluation} />;
  }

  const marksAwarded = evaluation?.detail?.reduce((acc, cur) => acc + cur.awardedMarks, 0) || 0;
  const marksTotal = evaluation?.detail?.reduce((acc, cur) => acc + cur.availableMarks, 0) || 0;

  const errorMarking = evaluation?.detail?.some(d => d.errorMarking);

  return (
    <>
      <div className={styles.MarkingResults}>
        {errorMarking ? (
          <div
            className={classNames(styles.MarkingResultDetail, styles.MarkingResultErrorContainer)}
          >
            <CorrectIcon markingError={true} inline={true} analyticsAnswerType="ai-v1" />
            <div className={styles.MarkingResultError}>
              Sorry - we weren't able to mark your answer this time. <br /> Don't worry, we've
              marked it complete so you can continue.
            </div>
          </div>
        ) : (
          <>
            <div className={styles.MarkingResultSummary}>
              {marksAwarded} / {marksTotal} mark{marksAwarded !== 1 && 's'}
            </div>
            {evaluation?.detail?.map((d, i) => (
              <div key={i} className={styles.MarkingResultDetail}>
                <CorrectIcon
                  correct={d.awardedMarks >= d.availableMarks}
                  inline={true}
                  analyticsAnswerType="ai-v1"
                />
                <div className={styles.MarkingResultFeedback}>{d.feedback}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

const MarkingDetailV2 = ({ evaluation }: { evaluation: GapEvaluation }) => {
  const { insightsMode } = useSparxQuestionContext();
  if (evaluation?.detail?.some(d => d.errorMarking)) {
    return (
      <div className={styles.MarkingResultsV2}>
        <div className={classNames(styles.MarkingResultDetail, styles.MarkingResultErrorContainer)}>
          <CorrectIcon markingError={true} inline={true} analyticsAnswerType="ai-v2" />
          <div className={styles.MarkingResultError}>
            Sorry - we weren&apos;t able to mark your answer this time. <br /> Don&apos;t worry,
            we&apos;ve marked it complete so you can continue.
          </div>
        </div>
      </div>
    );
  }

  const marksAwarded = evaluation?.detail?.reduce((acc, cur) => acc + cur.awardedMarks, 0) || 0;
  const marksTotal = evaluation?.detail?.reduce((acc, cur) => acc + cur.availableMarks, 0) || 0;

  const correctMarks = evaluation?.detail?.filter(d => d.awardedMarks >= d.availableMarks);
  const incorrectMarks = evaluation?.detail?.filter(d => d.awardedMarks < d.availableMarks);

  // Don't show the model answer when in insights mode, as the answer will already be the model one.
  const modelAnswer =
    insightsMode === undefined && evaluation?.additionalData?.['aimarked/modelAnswer'];

  const correct = marksAwarded >= marksTotal;

  return (
    <div className={styles.MarkingResultsV2}>
      <div
        className={classNames(styles.MarkingResultSummary, {
          [styles.Correct]: correct,
        })}
      >
        {correct && <FontAwesomeIcon icon={faCheck} fixedWidth={true} className={styles.Icon} />}{' '}
        {marksAwarded} out of {marksTotal} mark{marksTotal !== 1 && 's'}
      </div>
      {correctMarks && correctMarks.length > 0 && (
        <div className={styles.MarkingResultDetail}>
          <h3>Marks given for:</h3>
          {correctMarks.map((m, i) => (
            <div key={i} className={styles.MarkingResultFeedback}>
              <div className={styles.Icon}>
                <CorrectIcon correct={true} inline={true} analyticsAnswerType="ai-v2" />
              </div>
              <MarkdownNode>{m.feedback}</MarkdownNode>
            </div>
          ))}
        </div>
      )}
      {incorrectMarks && incorrectMarks.length > 0 && (
        <div className={styles.MarkingResultDetail}>
          <h3>How to improve:</h3>
          {incorrectMarks.map((m, i) => (
            <div key={i} className={styles.MarkingResultFeedback}>
              <div className={styles.Icon}>
                <div className={styles.IncorrectIcon}>
                  <FontAwesomeIcon icon={faMinus} fixedWidth={true} />
                </div>
              </div>
              <div>
                <MarkdownNode>{m.feedback}</MarkdownNode>
                {m.feedbackHint && <MarkdownNode>{`**Hint**: ${m.feedbackHint}`}</MarkdownNode>}
              </div>
            </div>
          ))}
        </div>
      )}
      {modelAnswer && (
        <div className={styles.MarkingResultDetail}>
          <h3>Model answer:</h3>
          <div className={styles.MarkingResultFeedback}>
            <div className={styles.Icon}>
              <div className={styles.ModelAnswerIcon}>
                <FontAwesomeIcon icon={faStar} fixedWidth={true} />
              </div>
            </div>
            <MarkdownNode>{modelAnswer}</MarkdownNode>
          </div>
        </div>
      )}
    </div>
  );
};

// Getting the types to work was too much pain for this quick trial
// We should try @types/dom-speech-recognition again though
declare global {
  interface Window {
    SpeechRecognition?: unknown;
    webkitSpeechRecognition?: unknown;
  }
}
const WindowSpeechRecognition =
  typeof window !== 'undefined' && (window.SpeechRecognition || window.webkitSpeechRecognition);

const VoiceToText = ({ setText }: { setText: (text: string) => void }) => {
  if (!WindowSpeechRecognition) {
    return null;
  }
  return <VoiceToTextButton setText={setText} />;
};

const VoiceToTextButton = ({ setText }: { setText: (text: string) => void }) => {
  const [listening, setListening] = React.useState<number | null>(null);
  const [running, setRunning] = React.useState(false);

  const recognition = React.useRef(
    (() => {
      // @ts-expect-error - Getting the types to work was a pain
      const rec = new WindowSpeechRecognition();
      rec.continuous = false;
      rec.interimResults = false;
      rec.maxAlternatives = 1;
      rec.lang = 'en-GB';
      return rec;
    })(),
  );

  const onPointerUp = React.useCallback(() => {
    if (listening === null) return;

    const timeHeld = Date.now() - listening;

    if (timeHeld > 400) {
      setListening(null);
      recognition.current.stop();
    }
  }, [listening]);

  React.useEffect(() => {
    const rec = recognition.current;

    const recStart = () => {
      setRunning(true);
    };
    const recEnd = () => {
      setRunning(false);
      setListening(null);
    };
    const recResult = (e: unknown) => {
      setListening(null);
      // @ts-expect-error - Getting the types to work was a pain
      setText(e.results[0][0].transcript);
    };

    rec.addEventListener('result', recResult);
    rec.addEventListener('start', recStart);
    rec.addEventListener('end', recEnd);
    window.addEventListener('pointerup', onPointerUp);
    window.addEventListener('pointercancel', onPointerUp);
    return () => {
      rec.removeEventListener('result', recResult);
      rec.removeEventListener('start', recStart);
      rec.removeEventListener('end', recEnd);
      window.removeEventListener('pointerup', onPointerUp);
      window.removeEventListener('pointercancel', onPointerUp);
    };
  }, [setText, onPointerUp]);

  const onStart = () => {
    if (listening === null) {
      setListening(Date.now());
      recognition.current.start();
    } else {
      setListening(null);
      recognition.current.stop();
    }
  };

  const loading = running && !listening;

  return (
    <button
      onPointerDown={onStart}
      className={classNames(styles.SpeechToText, {
        [styles.Listening]: listening !== null,
        [styles.Loading]: loading,
      })}
      disabled={loading}
    >
      {loading ? <LoadingSpinner /> : <FontAwesomeIcon icon={faMicrophone} />}
    </button>
  );
};
