import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import {
  Box,
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  add,
  addDays,
  format,
  getDate,
  getDay,
  getHours,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  set,
  setHours,
  setMinutes,
} from 'date-fns';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { AssignmentContentsSetting } from 'views/planner/components/AssignmentContentsSetting';

interface DatePickerProps {
  name: string;
  date: Date;
  setDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  readonly?: boolean;
}

export const setDateNextValid = (date: Date, now: Date) => {
  const nowHour = now.getHours();
  if (isBefore(date, now)) {
    // If we are still before then it means that we should push the time forward
    // to the next available hour
    if (nowHour < 17) {
      // Set the hour to the next hour
      return set(date, { hours: nowHour + 1 });
    } else {
      // set next week day
      date = addDays(date, 1); // move to tomorrow
      if (getDay(date) === 0)
        date = addDays(date, 1); // sun move to mon
      else if (getDay(date) === 6) date = addDays(date, 2); // sat move to mon
    }
  }
  return date;
};

// Checks whether the given date is after minDate and before maxDate
// minDate and maxDate are both optional if not specified the date is unbounded in that direction.
// 1 day is added to the Max date, as the max date should be inclusive
export const isDateValid = (date: Date, minDate?: Date, maxDate?: Date) =>
  !(minDate && isBefore(date, minDate)) && !(maxDate && isAfter(date, add(maxDate, { days: 1 })));

export const DatePicker = ({
  name,
  date,
  setDate,
  minDate,
  maxDate,
  readonly,
}: DatePickerProps) => {
  const [editableDate, setEditableDate] = useState(date);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => setEditableDate(date),
  });

  const submit = () => {
    onClose();
    setDate(editableDate);
  };

  const checkDate = (date: Date) => {
    return isDateValid(date, minDate, maxDate);
  };

  const changeDate = (changed: Date) =>
    setEditableDate(
      set(editableDate, {
        year: getYear(changed),
        month: getMonth(changed),
        date: getDate(changed),
      }),
    );

  const changeTime = (hours: number) =>
    setEditableDate(setHours(setMinutes(editableDate, 0), hours));

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index: number) => setTabIndex(index);

  return (
    <>
      <AssignmentContentsSetting title={name}>
        <Input
          size="sm"
          value={format(date, 'EEE do MMMM')}
          w="170px"
          onChange={() => undefined}
          isDisabled={readonly}
          onClick={() => {
            if (!readonly) {
              setDefaultIndex(0);
              onOpen();
            }
          }}
        />
        <Text opacity={readonly ? 0.4 : 1}>at</Text>
        <Input
          size="sm"
          value={format(date, 'HH:mm')}
          w="80px"
          onChange={() => undefined}
          isDisabled={readonly}
          onClick={() => {
            if (!readonly) {
              setDefaultIndex(1);
              onOpen();
            }
          }}
        />
      </AssignmentContentsSetting>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <Box backgroundColor="blue.800" p={4} textAlign="center">
            <Text color="white" fontWeight="bold" fontSize="lg">
              Set a {name} date &amp; time
            </Text>
            <Box backgroundColor="whiteAlpha.200" px={3} py={2} borderRadius="md" mt={3}>
              <Text color="white" fontWeight="bold" fontSize="lg">
                <span onClick={() => handleTabsChange(0)}>
                  {format(editableDate, 'EEEE do MMMM')}
                </span>{' '}
                at <span onClick={() => handleTabsChange(1)}>{format(editableDate, 'HH:mm')}</span>
              </Text>
            </Box>
          </Box>
          <Tabs
            isFitted
            isLazy
            defaultIndex={defaultIndex}
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab>
                <FontAwesomeIcon icon={faCalendar} />
                <Text ml={2}>Date</Text>
              </Tab>
              <Tab>
                <FontAwesomeIcon icon={faClock} />
                <Text ml={2}>Time</Text>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={5} display="flex" justifyContent="center">
                <Calendar
                  date={editableDate}
                  onChange={changeDate}
                  showMonthAndYearPickers={false}
                  minDate={minDate}
                  maxDate={maxDate}
                  shownDate={minDate}
                />
              </TabPanel>
              <TabPanel px={5}>
                <Text fontSize="sm" mb={1}>
                  {name} time:
                </Text>
                <Select
                  value={getHours(editableDate)}
                  onChange={e => changeTime(parseInt(e.target.value))}
                >
                  {getTimeOptions(getHours(editableDate), hour =>
                    Boolean(
                      minDate && isSameDay(editableDate, minDate) && getHours(minDate) >= hour,
                    ),
                  )}
                </Select>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <ModalFooter display="flex" p={3}>
            <Button colorScheme="buttonTeal" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Spacer />
            <Button colorScheme="buttonTeal" onClick={submit} isDisabled={!checkDate(editableDate)}>
              Set date &amp; time
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const earliestHour = 3;
const latestHour = 22;
export const getTimeOptions = (selected: number, hourIsDisabled?: (hour: number) => boolean) => (
  <>
    {(selected < earliestHour || selected > latestHour) && (
      <option value={selected}>{selected}:00</option>
    )}
    {Array.from({ length: latestHour - earliestHour + 1 }).map((_, i) => (
      <option key={i} value={i + earliestHour} disabled={hourIsDisabled?.(i + earliestHour)}>
        {i + earliestHour}:00
      </option>
    ))}
  </>
);
