import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { HandinStatus, isStarted, useHandInStatus } from '@sparx/packageactivity';
import { Stack, StackDirection } from '@sparx/sparx-design/components';
import { SparxUnit } from '@sparx/sparx-design/utils/units';
import { useBreakpointValue } from 'components/chakraExports';
import { getHandinStatusStyling } from 'components/CompletionBadge';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { UntilTimestamp } from 'components/timestamp/UntilTimestamp';
import { useNavigate } from 'react-router-dom';
import { useKeyPress } from 'utils/hooks/keypress';
import { packageName } from 'views/packagelist/helpers';
import { nudge } from 'views/task/HomeworkComplete/HomeworkComplete';

import ILImage from './ILImage.svg';

type nudgeProps = {
  nudge: nudge;
  onFinish: () => void;
};
export const Nudge = ({ nudge, onFinish }: nudgeProps) => {
  const navigate = useNavigate();

  const { link: buttonLink, text: buttonText } = getNudgeLinkInfo(nudge);

  // Do the nudge on enter press
  useKeyPress({ Enter: () => navigate(buttonLink) });

  const buttonLayout: StackDirection =
    useBreakpointValue({
      base: 'column',
      sm: 'row',
    }) || 'row';

  const spacing: SparxUnit =
    useBreakpointValue({
      base: 5,
      md: 10,
    }) || 10;

  return (
    <Box justifyContent="center" h="auto" w="100%" p={5} textAlign="center" id="1" my={10}>
      <Stack direction="column" spacing={spacing} align="center" justify="center">
        <Heading size={{ base: 'md', md: 'lg' }} fontWeight="bold">
          {getHeading(nudge)}
        </Heading>
        <Box w={'100%'} textAlign="left">
          <Stack direction="column" spacing={spacing}>
            {getContent(nudge)}
          </Stack>
        </Box>
        <Stack direction={buttonLayout} spacing={5}>
          <Button onClick={onFinish} colorScheme="buttonTeal" variant="outline">
            Back to Home
          </Button>
          <Button onClick={() => navigate(buttonLink)} colorScheme="buttonTeal">
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

const getHeading = (nudge: nudge) => {
  switch (nudge.type) {
    case 'hw':
      return `Looks like you have a previous homework that ${
        isStarted(nudge.package.state?.completion)
          ? "isn't quite finished..."
          : "hasn't been started."
      }`;
    case 'il':
      return "Would you like to try this week's Independent Learning XP Challenge?";
  }
};

const getContent = (nudge: nudge) => {
  switch (nudge.type) {
    case 'hw':
      return (
        <>
          <DummyPackageRow pkg={nudge.package} />
          <Text textAlign="center" fontSize={{ base: 'md', md: 'lg' }}>
            Fancy catching-up while you are on a roll?
          </Text>
        </>
      );
    case 'il':
      return (
        <>
          <Text textAlign="center" fontSize={{ base: 'md', md: 'lg' }}>
            Earn extra XP to boost your learning
          </Text>
          <Image src={ILImage} w={{ base: '100px', sm: '150px' }} mx="auto" />
        </>
      );
  }
};

const getNudgeLinkInfo = (nudge: nudge) => {
  switch (nudge.type) {
    case 'hw':
      return { link: `/${nudge.package.name}`, text: 'Catch-up' };
    case 'il':
      return { link: `/independentlearning`, text: 'Independent Learning' };
  }
};

// This is a copy pasted PackageRow from package list view, with no interactivity, and no status badge, and some minor
// styling changes.
const DummyPackageRow = ({ pkg }: { pkg: Package }) => {
  const { status, percentages } = useHandInStatus(
    pkg.state?.completion,
    pkg.endTimestamp,
    pkg.cancelledTime,
  );
  const percentageCorrect = percentages.C.roundedPercentage;
  const styling = getHandinStatusStyling(status);

  const packageTitle = (
    <>
      {packageName(pkg)} for{' '}
      <PrettyTimestamp fmt="EEEE do MMMM h:mmaaa">{pkg.endTimestamp}</PrettyTimestamp>
    </>
  );

  return (
    <Flex
      alignItems="center"
      transition="box-shadow 0.2s"
      boxShadow="elevationLow"
      borderRadius="lg"
      bg="white"
      overflow="hidden"
      pr={[3, 5]}
    >
      <Flex
        borderLeftWidth={10}
        borderLeftColor={styling.color}
        flex={1}
        justifyContent="space-between"
        flexDirection={{ base: 'column', sm: 'row' }}
        mr={[3, 5]}
      >
        <Box pt={4} pb={{ base: 2, lg: 4 }} pl={[4, 5]}>
          <Text color={'gray.500'} fontSize={{ base: 'sm', md: 'md' }}>
            Due <UntilTimestamp>{pkg.endTimestamp}</UntilTimestamp>
          </Text>
          <Text fontWeight="bold" color={'blue.800'} fontSize={{ base: 'md', md: 'lg' }}>
            {packageTitle}
          </Text>
        </Box>
        <Flex
          px={0}
          pb={{ base: 4, sm: 0 }}
          justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
          alignItems="center"
          flexDirection={{ base: 'row-reverse', lg: 'row' }}
          fontSize={{ base: 'md', sm: 'lg' }}
        >
          {status === HandinStatus.NotStarted || status === HandinStatus.NotStartedLate ? (
            <Text color="gray.400">Not started</Text>
          ) : (
            <Text fontWeight="bold" color={styling.color}>
              {percentageCorrect}%
            </Text>
          )}
          <Box w={4} />
        </Flex>
      </Flex>
    </Flex>
  );
};
