import {
  GetCurrentSessionResponse,
  LogSearchQueryRequest,
} from '@sparx/api/apis/sparx/science/sessions/v1/session';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getSchoolIDFromUrl, redirectToLogin, sessionsClient } from 'api';
import { queryClient } from 'api/client';
import { configureUserProperties } from 'app/analytics';
import { isAnonymousMode } from 'utils/anonymous';

const makeSessionQuery = <T = GetCurrentSessionResponse>(): UseQueryOptions<
  GetCurrentSessionResponse,
  Error,
  T
> => ({
  queryKey: ['session'],
  queryFn: async () => {
    const data = await sessionsClient.getCurrentSession({}).response;

    // Ensure that the school that is in the URL is the one that we currently have a session for.
    // If there is a mismatch then we redirect to the login page for the correct school.
    //
    // We do it here so that the session query never actually resolves, preventing the page from
    // loading and then redirecting straight away.
    const schoolID = getSchoolIDFromUrl();
    if (schoolID && schoolID !== data.schoolId) {
      await redirectToLogin();
    }

    return data;
  },
  cacheTime: Infinity,
  staleTime: Infinity,
});

const sessionQuery: UseQueryOptions<GetCurrentSessionResponse, Error> = {
  ...makeSessionQuery(),
  onSuccess: configureUserProperties,
};

export const useSession = (opts?: { suspense: boolean }) => useQuery({ ...sessionQuery, ...opts });

export const useSearchQueryEvent = () =>
  useMutation({
    mutationFn: async (query: Omit<LogSearchQueryRequest, 'schoolName'>) => {
      await sessionsClient.logSearchQuery({
        schoolName: `schools/${await getSchoolID()}`,
        ...query,
      });
    },
  });

export enum FeatureFlag {
  Lessons = 'lessons',
  SuppressRolePrompt = 'suppress-role-prompt',
  RolePromptOverrideDate = 'role-prompt-override-date',
  Xp = 'xp',
  MaxImageSize = 'max-image-size',
}

export const useBooleanFlag = (name: FeatureFlag) => {
  const { data } = useSession();
  const flag = data?.featureFlags?.fields[name];
  if (flag?.kind?.oneofKind === 'boolValue') {
    return flag.kind?.boolValue;
  }
  return false; // default is false
};

export const useStringFlag = (name: FeatureFlag) => {
  const { data } = useSession();
  const flag = data?.featureFlags?.fields[name];
  if (flag?.kind?.oneofKind === 'stringValue') {
    return flag.kind?.stringValue;
  }
  return undefined;
};

export const getSchoolID = async () =>
  queryClient.fetchQuery(sessionQuery).then(data => data.schoolId);

export const useSchoolID = () =>
  useQuery({ ...makeSessionQuery<string>(), select: data => data.schoolId });

export const useUserType = () => {
  const { data, isLoading } = useSession();
  const { data: { sparxFeaturesEnabled, jamieMode } = {} } = useSparxStaffFeaturesEnabled();
  const anonymousMode = isAnonymousMode();

  const isActuallySparxStaff = data?.userType === 'sparx' && !anonymousMode;

  return {
    isLoading,
    isTeacher: data?.userType === 'staff' || data?.userType === 'sparx',
    // isSparxStaff should be used in most cases when checking if the user is a
    // Sparx staff member, it takes into account the Sparx Features toggle
    isSparxStaff: isActuallySparxStaff && !!sparxFeaturesEnabled,
    // isActuallySparxStaff is true if the user is Sparx Staff, it ignores the
    // state of the Sparx Features toggle. isSparxStaff should be used instead in most cases.
    isActuallySparxStaff,
    sparxFeaturesEnabled: isActuallySparxStaff && !!sparxFeaturesEnabled,
    jamieMode: isActuallySparxStaff && !!sparxFeaturesEnabled && !!jamieMode,
  };
};

const useSparxStaffFeaturesEnabled = () =>
  useQuery({
    queryKey: ['session', 'sparx'],
    queryFn: () => ({
      sparxFeaturesEnabled: localStorage.getItem('sci/sparxfeatures') === 'on',
      jamieMode: localStorage.getItem('sci/jamiemode') === 'on',
    }),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

export const setSparxFeatures = (on: boolean) => {
  if (on) {
    localStorage.setItem('sci/sparxfeatures', 'on');
  } else {
    localStorage.removeItem('sci/sparxfeatures');
  }
  queryClient.invalidateQueries(['session', 'sparx']);
};

export const setJamieMode = (on: boolean) => {
  if (on) {
    localStorage.setItem('sci/jamiemode', 'on');
  } else {
    localStorage.removeItem('sci/jamiemode');
  }
  queryClient.invalidateQueries(['session', 'sparx']);
};
