import { Box, Center, Flex, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { faCheck, faChevronRight, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Package } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { HandinStatus, useHandInStatus } from '@sparx/packageactivity';
import { getHandinStatusStyling, StatusBadge } from 'components/CompletionBadge';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { UntilTimestamp } from 'components/timestamp/UntilTimestamp';
import { Tooltip } from 'components/tooltip/Tooltip';
import { Link } from 'react-router-dom';
import { packageName } from 'views/packagelist/helpers';

interface PackageRowProps {
  pkg: Package;
}

export const PackageRow = ({ pkg }: PackageRowProps) => {
  const { status, percentages } = useHandInStatus(
    pkg.state?.completion,
    pkg.endTimestamp,
    pkg.cancelledTime,
  );
  const percentageCorrect = percentages.C.roundedPercentage;
  const styling = getHandinStatusStyling(status);
  const complete = status === HandinStatus.Complete;
  const cancelled = status === HandinStatus.Cancelled;

  const packageTitle = (
    <>
      {packageName(pkg)} for{' '}
      <PrettyTimestamp fmt="EEEE do MMMM h:mmaaa">{pkg.endTimestamp}</PrettyTimestamp>
    </>
  );

  const row = (
    <Flex
      alignItems="center"
      as={LinkBox}
      _hover={
        !cancelled
          ? {
              boxShadow: 'elevationMedium',
            }
          : undefined
      }
      transition="box-shadow 0.2s"
      boxShadow="elevationLow"
      borderRadius="lg"
      bg="white"
      overflow="hidden"
      pr={[3, 5]}
    >
      <Flex
        borderLeftWidth={10}
        borderLeftColor={styling.color}
        flex={1}
        justifyContent="space-between"
        flexDirection={{ base: 'column', lg: 'row' }}
        mr={[3, 5]}
      >
        <Box pt={4} pb={{ base: 2, lg: 4 }} pl={[4, 5]}>
          <Text color={cancelled ? 'gray.400' : 'gray.500'} fontSize={{ base: 'sm', md: 'md' }}>
            {cancelled ? (
              'Cancelled'
            ) : (
              <>
                Due <UntilTimestamp>{pkg.endTimestamp}</UntilTimestamp>
              </>
            )}
          </Text>
          <Text
            fontWeight="bold"
            color={cancelled ? 'gray.400' : 'blue.800'}
            fontSize={{ base: 'md', md: 'lg' }}
          >
            {cancelled ? (
              packageTitle
            ) : (
              <LinkOverlay as={Link} to={`/${pkg.name}`}>
                {packageTitle}
              </LinkOverlay>
            )}
          </Text>
        </Box>
        {!cancelled && (
          <Flex
            px={{ base: 4, sm: 5, lg: 0 }}
            pb={{ base: 4, lg: 0 }}
            justifyContent={{ base: 'flex-end', lg: 'flex-start' }}
            alignItems="center"
            flexDirection={{ base: 'row-reverse', lg: 'row' }}
            fontSize={{ base: 'md', md: 'lg' }}
          >
            {status === HandinStatus.NotStarted || status === HandinStatus.NotStartedLate ? (
              <Text color="gray.400">Not started</Text>
            ) : (
              <Text fontWeight="bold" color={styling.color}>
                {percentageCorrect}%
              </Text>
            )}
            <Box w={4} />
            <StatusBadge completion={pkg.state?.completion} due={pkg.endTimestamp} />
          </Flex>
        )}
      </Flex>
      <Center
        bg={cancelled ? undefined : complete ? 'green.100' : 'gray.100'}
        borderRadius="full"
        w={10}
        h={10}
        fontSize={cancelled ? '2xl' : 'lg'}
        color={cancelled ? 'gray.400' : complete ? 'green.800' : 'gray.800'}
      >
        <FontAwesomeIcon icon={cancelled ? faLock : complete ? faCheck : faChevronRight} />
      </Center>
    </Flex>
  );

  if (cancelled) {
    return (
      <Tooltip
        label={
          <>
            <Text fontSize="lg">Homework cancelled</Text>
            <Text mt={2}>
              This homework has been cancelled by your teacher and does not need to be completed.
            </Text>
          </>
        }
        placement="top"
        contentProps={{ maxWidth: '400px' }}
      >
        {row}
      </Tooltip>
    );
  }

  return row;
};
