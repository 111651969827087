import {
  CreateSchoolPeriodRequest,
  DeleteSchoolPeriodRequest,
  SchoolPeriod,
  SchoolPeriodType,
  SchoolYear,
  UpdateSchoolPeriodRequest,
} from '@sparx/api/apis/sparx/school/calendar/v3/calendar';
import { ListSchoolGroupsForSchoolRequest } from '@sparx/api/apis/sparx/school/v2/schoolgroups';
import {
  Group,
  ListGroupsRequest,
  ListYearGroupsRequest,
  UpdateGroupRequest,
} from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { getSchool } from '@sparx/query/schools-service';
import { FetchQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { calendarClient, groupsClient, schoolGroupsClient, studentsClient } from 'api';
import { queryClient } from 'api/client';
import { getSchoolID } from 'api/sessions';
import { addDays, format, isAfter, isBefore, isEqual, max, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useMemo } from 'react';
import { isAnonymousMode } from 'utils/anonymous';

import { fetchInterimAwareYear } from './schoolcalendar';

export const studentsQueryKey = ['school', 'students'];
export const studentsQuery: FetchQueryOptions<Student[]> = {
  queryKey: studentsQueryKey,
  queryFn: async () => {
    // If the fake names is set, we replace the student names with fake names
    let mapStudent = (s: Student) => s;
    if (isAnonymousMode()) {
      const { randomName, randomStudentLogin } = await import('api/fakenames');
      mapStudent = (s: Student) => ({
        ...s,
        ...randomName(s.studentId),
        login: randomStudentLogin(s.studentId, s.login),
      });
    }

    return studentsClient
      .listStudents({ schoolId: await getSchoolID() })
      .response.then(data => data.students.map(mapStudent));
  },
  staleTime: 1000 * 60 * 15, // 15 minutes
};

export const groupsQueryKey = ['school', 'groups'];
export const groupsQuery: FetchQueryOptions<Group[]> = {
  queryKey: groupsQueryKey,
  queryFn: async () =>
    groupsClient
      .listGroups(
        ListGroupsRequest.create({
          groupIds: [],
          includeHomeworkPlans: false,
          parent: `schools/${await getSchoolID()}`,
        }),
      )
      .response.then(data => data.groups),
  staleTime: 1000 * 60 * 15, // 15 minutes
};

export interface SchoolYearWithDates extends SchoolYear {
  start: Date;
  end: Date;
  current?: boolean;
  next?: boolean;
  previous?: boolean;
}

export const schoolYearQuery: FetchQueryOptions<SchoolYearWithDates[]> = {
  queryKey: ['school', 'year'],
  queryFn: async () =>
    await calendarClient
      .listSchoolYears({
        parent: `schools/${await getSchoolID()}`,
      })
      .response.then(d => {
        const yearsWithStartDate: SchoolYearWithDates[] = d.schoolYears.map(year => ({
          ...year,
          start: Timestamp.toDate(year.startDate!),
          end: Timestamp.toDate(year.endDate!),
        }));
        yearsWithStartDate.sort((a, b) => (isBefore(a.start, b.start) ? -1 : 1));
        for (const i in yearsWithStartDate) {
          if (isAfter(yearsWithStartDate[i].start, new Date())) {
            yearsWithStartDate[parseInt(i) - 1].current = true;
            yearsWithStartDate[i].next = true;
            const prev = parseInt(i) - 2;
            if (prev >= 0) {
              yearsWithStartDate[prev].previous = true;
            }
            break;
          }
        }
        return yearsWithStartDate;
      }),
  cacheTime: Infinity,
  staleTime: 15 * 60 * 1000, // 15 minutes,
};

// listSchoolPeriods with a specific year index doesn't always return all periods
// intersecting that school year, for some reason the periods may not be associated
// with the right year index.
// Instead we get all periods and then filter ased on dates instead.
const allSchoolYearPeriodsQuery: FetchQueryOptions<SchoolPeriod[]> = {
  queryKey: ['school', 'year', 'periods', 'all'],
  queryFn: async () =>
    await calendarClient
      .listSchoolPeriods({
        parent: `schools/${await getSchoolID()}`,
        schoolYear: 0,
        periodType: SchoolPeriodType.HOLIDAY,
      })
      .response.then(r => r.periods),
  cacheTime: Infinity,
  staleTime: 15 * 60 * 1000, // 15 minutes
};

const schoolYearPeriodQuery = (yearIndex: string): FetchQueryOptions<SchoolPeriod[]> => ({
  queryKey: ['school', 'year', 'periods', yearIndex],
  queryFn: async () => {
    const [schoolYears, periods] = await Promise.all([
      queryClient.fetchQuery(schoolYearQuery),
      // listSchoolPeriods with a specific year index doesn't always return all periods
      // intersecting that school year, for some reason the periods may not be associated
      // with the right year index.
      // Instead we get all periods and then filter based on dates instead.
      queryClient.fetchQuery(allSchoolYearPeriodsQuery),
    ]);

    // Find the current school year or the one with the index if provided
    let schoolYear: SchoolYearWithDates | undefined;
    if (yearIndex === '0') {
      schoolYear = findMostRecentSchoolYear(schoolYears);
    } else {
      schoolYear = schoolYears.find(y => y.name.endsWith(`/years/${yearIndex}`));
    }

    return periods.filter(period => {
      if (!schoolYear || !period.startDate || !period.endDate) {
        return false;
      }
      const periodStart = Timestamp.toDate(period.startDate);
      const periodEnd = Timestamp.toDate(period.endDate);
      // Include the period if it starts before the end of the year, and ends after the start of the year
      return isBefore(periodStart, schoolYear.end) && isBefore(schoolYear.start, periodEnd);
    });
  },
  // Dont need to keep this around for long as this is just data calculated from other queries with their own cache/stale times
  cacheTime: 5000,
  staleTime: 0,
});

export interface Options {
  suspense: boolean;
  enabled?: boolean;
  refetchOnMount?: 'always' | boolean;
}

export const useStudents = (opts: Options) =>
  useQuery({
    ...studentsQuery,
    ...opts,
  });

export const useStudentsLookup = (opts: Options) =>
  useQuery({
    ...studentsQuery,
    select: data =>
      data.reduce<Record<string, Student | undefined>>((acc, s) => {
        acc[s.studentId] = s;
        return acc;
      }, {}),
    ...opts,
  });

export const useGroups = (opts: Options) =>
  useQuery({
    ...groupsQuery,
    ...opts,
  });

export const useSortedGroups = (groups: Group[], includeAll?: boolean) =>
  useMemo(
    () =>
      groups
        ?.filter(g => includeAll || g.type === StudentGroupType.CLASS_SCIENCE)
        .sort(
          (a, b) =>
            parseInt(a.displayName) - parseInt(b.displayName) ||
            a.displayName.localeCompare(b.displayName),
        ) || [],
    [groups, includeAll],
  );

export const useGroupOfType = (
  student: Student | undefined,
  groups: Group[] | undefined,
  typ: StudentGroupType,
) => useMemo(() => findGroupOfType(student, groups || [], typ), [student, groups, typ]);

export const findGroupOfType = (
  student: Student | undefined,
  groups: Group[],
  typ: StudentGroupType,
) =>
  groups.find(
    g => g.type === typ && student?.studentGroupIds.includes(g.name.split('studentGroups/')[1]),
  );

export const useGroup = (groupID: string, opts: Options) =>
  useQuery({
    ...groupsQuery,
    ...opts,
    select: d => d.find(g => groupID && g.name.endsWith(groupID)),
  });

export const useSchoolYears = (opts: Options, onlyActive?: boolean) =>
  useQuery({
    ...schoolYearQuery,
    ...opts,
    select: d => (onlyActive ? d.filter(y => y.current || y.next) : d),
  });

export const useCurrentSchoolYear = (opts: Options) =>
  useQuery({
    ...schoolYearQuery,
    ...opts,
    select: d => findMostRecentSchoolYear(d),
  });

export const usePasswordReset = ({ onSuccess }: { onSuccess?: () => void }) =>
  useMutation({
    mutationFn: async (studentIds: string[]) =>
      studentsClient
        .grantPasswordReset({
          studentIds: studentIds,
          schoolId: await getSchoolID(),
        })
        .response.then(r => r.studentLogins),
    onSuccess: resp => {
      studentsQuery.queryKey &&
        queryClient.setQueryData(studentsQuery.queryKey, (data: Student[] | undefined) => {
          if (!data) return data;
          // Overwrite any existing login with the new ones
          return data.map(d => (resp[d.studentId] ? { ...d, login: resp[d.studentId] } : d));
        });
      onSuccess?.();
    },
  });

export interface Week {
  index: number;
  startDate: Date;
  endDate: Date;
  lastDate: Date; // lastDate is the last day of the week
  past: boolean;
  current: boolean;
  next: boolean;
  dates: WeekDate[];
}

interface WeekDate {
  date: Date;
  holiday: boolean;
  outsideAY?: boolean;
}

export const dateInWeek = (w: Week, date: Date): boolean =>
  (isEqual(w.startDate, date) || isBefore(w.startDate, date)) && isAfter(w.endDate, date);

export const findMostRecentSchoolYear = (
  years: SchoolYearWithDates[],
): SchoolYearWithDates | undefined => years.find(y => y.current);

export const findPreviousSchoolYear = (
  years: SchoolYearWithDates[],
): SchoolYearWithDates | undefined => years.find(y => y.previous);

export const useWeeks = (opts: Options) => useWeeksForYear('0', opts);

export const useHolidaysForSchoolYear = (yearIndex: string, opts: Options) =>
  useQuery({
    ...schoolYearPeriodQuery(yearIndex),
    ...opts,
    select: data =>
      data
        .filter(p => !!p.startDate && !!p.endDate)
        .map<SchoolPeriodWithDates>(p => ({
          ...p,
          start: Timestamp.toDate(p.startDate!),
          end: Timestamp.toDate(p.endDate!),
        })),
  });

export interface SchoolPeriodWithDates extends SchoolPeriod {
  start: Date;
  end: Date;
}

export const useAllHolidays = (opts: Options) =>
  useQuery({
    ...allSchoolYearPeriodsQuery,
    ...opts,
    select: data =>
      data
        .filter(p => !!p.startDate && !!p.endDate)
        .map<SchoolPeriodWithDates>(p => ({
          ...p,
          start: Timestamp.toDate(p.startDate!),
          end: Timestamp.toDate(p.endDate!),
        })),
  });

export const useWeeksForYear = (yearIndex: string, opts: Options) => {
  const getSchoolQuery = getSchool.useOpts();
  return useQuery({
    queryKey: ['school', 'weeks', yearIndex],
    queryFn: async () => {
      const [schoolYears, periods, school] = await Promise.all([
        queryClient.fetchQuery(schoolYearQuery),
        queryClient.fetchQuery(schoolYearPeriodQuery(yearIndex)),
        queryClient.fetchQuery(getSchoolQuery()),
      ]);
      const now = new Date();

      // Find the current school year or the one with the index if provided
      let schoolYear: SchoolYearWithDates | undefined;
      if (yearIndex === '0') {
        // If we want the current year we need to figure out which one that should be
        const calYear = await fetchInterimAwareYear();
        if (calYear?.isPrevious) {
          schoolYear = findPreviousSchoolYear(schoolYears);
        } else {
          schoolYear = findMostRecentSchoolYear(schoolYears);
        }
      } else {
        schoolYear = schoolYears.find(y => y.name.endsWith(`/years/${yearIndex}`));
      }
      if (!schoolYear) return [];

      const holidays = new Set<string>();
      for (const period of periods) {
        if (!period.startDate || !period.endDate) continue;
        const periodStart = Timestamp.toDate(period.startDate);
        const periodEnd = Timestamp.toDate(period.endDate);
        for (
          let start = startOfDay(periodStart);
          isBefore(start, periodEnd) || isEqual(start, periodEnd);
          start = addDays(start, 1)
        ) {
          holidays.add(format(start, 'yyyy-MM-dd'));
        }
      }

      let isPast = true;
      let isNext = false;
      const weeks: Week[] = [];
      for (const week of schoolYear?.weeks || []) {
        if (!week.startDate || !week.index) continue;

        let startDate = Timestamp.toDate(week.startDate);
        let endDate = addDays(startDate, 7);
        let lastDate = addDays(startDate, 6);

        // // The start date of the week is the date in the school's timezone, we need timestamps in UTC so convert them here
        startDate = zonedTimeToUtc(
          new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
          school.timeZone,
        );
        endDate = zonedTimeToUtc(
          new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
          school.timeZone,
        );
        lastDate = zonedTimeToUtc(
          new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate()),
          school.timeZone,
        );

        const dates: WeekDate[] = [];
        for (let start = startDate; isBefore(start, endDate); start = addDays(start, 1)) {
          dates.push({
            date: start,
            holiday: holidays.has(format(start, 'yyyy-MM-dd')),
            outsideAY: isBefore(start, schoolYear.start) || isAfter(start, schoolYear.end),
          });
        }

        const current =
          (isEqual(startDate, now) || isBefore(startDate, now)) && isAfter(endDate, now);
        if (current) isPast = false;
        weeks.push({
          index: week.index,
          startDate,
          endDate,
          lastDate,
          past: isPast,
          current,
          next: isNext,
          dates,
        });
        isNext = current;
      }
      return weeks;
    },
    // Dont need to keep this around for long as this is just data calculated from other queries with their own cache/stale times
    cacheTime: 5000,
    staleTime: 0,
    ...opts,
  });
};

export const useWeekForDate = (date: Timestamp | undefined, opts: Options) => {
  const { data: weeks } = useWeeks(opts);
  return useMemo(
    () => weeks?.find(w => date && dateInWeek(w, Timestamp.toDate(date))),
    [weeks, date],
  );
};

export const useUpdateGroup = () =>
  useMutation({
    mutationFn: async (req: UpdateGroupRequest) => groupsClient.updateGroup(req).response,
    onSuccess: () => {
      queryClient.invalidateQueries(groupsQuery.queryKey);
    },
  });

export const yearGroupsQuery: FetchQueryOptions<YearGroup[]> = {
  queryKey: ['school', 'yeargroups'],
  queryFn: async () =>
    groupsClient
      .listYearGroups(
        ListYearGroupsRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
        }),
      )
      .response.then(data => data.yearGroups),
  staleTime: 1000 * 60 * 15, // 15 minutes
};

export const useYearGroups = (opts: Options) =>
  useQuery({
    ...yearGroupsQuery,
    ...opts,
  });

export const useDeleteSchoolPeriod = () =>
  useMutation({
    mutationFn: async (periodName: string) =>
      calendarClient.deleteSchoolPeriod(DeleteSchoolPeriodRequest.create({ name: periodName }))
        .response,
    onSuccess: (_, periodName) => {
      // Update the query data, then invalidate all the period queries.
      // updating the data first means the change will appear straight away
      // rather than waiting for the refetch to complete.
      queryClient.setQueryData(
        allSchoolYearPeriodsQuery.queryKey!,
        (data: SchoolPeriod[] | undefined) => {
          if (!data) return undefined;
          return data.filter(v => v.name !== periodName);
        },
      );
      queryClient.invalidateQueries(['school', 'year', 'periods']);
    },
  });

export const useCreateOrUpdateSchoolPeriod = () =>
  useMutation({
    mutationFn: async (period: SchoolPeriod) =>
      !period.name
        ? calendarClient.createSchoolPeriod(
            CreateSchoolPeriodRequest.create({
              parent: `schools/${await getSchoolID()}`,
              period,
            }),
          ).response
        : calendarClient.updateSchoolPeriod(UpdateSchoolPeriodRequest.create({ period })).response,
    onSuccess: newData => {
      // Update the query data, then invalidate all the period queries.
      // updating the data first means the change will appear straight away
      // rather than waiting for the refetch to complete.
      queryClient.setQueryData(
        allSchoolYearPeriodsQuery.queryKey!,
        (data: SchoolPeriod[] | undefined) => {
          if (!data) return undefined;
          // replace or add the period
          const idx = data.findIndex(v => v.name === newData.name);
          if (idx === -1) data.push(newData);
          else data.splice(idx, 1, newData);
          return data;
        },
      );
      queryClient.invalidateQueries(['school', 'year', 'periods']);
    },
  });

export const useSchoolGroups = (opts: Options) =>
  useQuery({
    queryKey: ['schoolgroups'],
    queryFn: async () =>
      schoolGroupsClient
        .listSchoolGroupsForSchool(
          ListSchoolGroupsForSchoolRequest.create({
            schoolName: `schools/${await getSchoolID()}`,
          }),
        )
        .response.then(data => data.schoolGroups),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...opts,
  });

// returns when Science was enabled for the school.
export const useScienceEnabledDate = () => {
  const { data: school } = getSchool.useSuspenseQuery();

  if (!school || !school.createTime) return new Date();

  const createTime = Timestamp.toDate(school.createTime);

  const undeleteTime = school.undeleteTime ? Timestamp.toDate(school.undeleteTime) : new Date(0);

  const pct = school.productChangeTimes.find(p => p.product === Product.SPARX_SCIENCE);
  if (!pct || !pct.enabledTime) {
    // This is unexpected but fallback to the school creation/undelete date
    return max([createTime, undeleteTime]);
  }

  const productEnabledTime = Timestamp.toDate(pct.enabledTime);

  return max([createTime, undeleteTime, productEnabledTime]);
};
