import { Box, Button, Flex, Heading, HStack, Image } from '@chakra-ui/react';
import { faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task, TaskItem_Status } from '@sparx/api/apis/sparx/science/packages/v1/package';
import { getCompletionSummary } from '@sparx/packageactivity';
import { ProgressBar } from '@sparx/sparx-design/components';
import classNames from 'classnames';
import RapidFireIcon from 'components/rapidfire/rapidfireicon.svg';
import React, { useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { isTaskRapidFire } from 'utils/rapidfire';
import styles from 'views/task/Navigator.module.css';

interface NavigatorProps {
  task: Task | undefined;
  selected: number | 'results';
  setSelected: (value: number | 'results') => void;
}

export const Navigator = (props: NavigatorProps) => {
  if (isTaskRapidFire(props.task)) {
    return <RapidFireNav {...props} />;
  }
  return <DefaultNav {...props} />;
};

const DefaultNav = ({ task, selected, setSelected }: NavigatorProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const scrollIntoView = (id: number | 'results', instant?: boolean) => {
    const element = document.getElementById('nav-' + id);
    if (element) {
      element.scrollIntoView({
        inline: instant ? 'center' : 'center',
        block: 'nearest',
        behavior: instant ? 'auto' : 'smooth',
      });
    }
  };

  useEffect(() => {
    if (task?.contents?.taskItems) {
      scrollIntoView(selected, false);
    }
  }, [task?.contents?.taskItems, selected]);
  const items = task?.contents?.taskItems || [];

  return (
    <HStack
      className={styles.NavStack}
      alignItems="flex-end"
      as={ScrollContainer}
      hideScrollbars={true}
      py={2}
      my={-2}
      innerRef={containerRef}
      vertical={false}
      overflowY="hidden"
      zIndex={3}
      position="relative"
    >
      {items.map((item, index) => (
        <TaskItemButton
          selected={selected === index + 1}
          index={index + 1}
          status={item.state?.status}
          key={item.name}
          onClick={() => setSelected(index + 1)}
        />
      ))}
      <NavigatorButton
        width={80}
        selected={selected === 'results'}
        onClick={() => setSelected('results')}
        id={`nav-results`}
      >
        Results
      </NavigatorButton>
    </HStack>
  );
};

interface TaskItemButtonProps {
  index: number;
  status?: TaskItem_Status;
  selected?: boolean;
  onClick?: () => void;
}

const TaskItemButton = ({ index, status, selected, onClick }: TaskItemButtonProps) => {
  let icon;
  let backgroundColour;
  let textColour;
  let iconColour = 'white';
  switch (status) {
    case TaskItem_Status.CORRECT:
    case TaskItem_Status.PENDING_CORRECT:
      backgroundColour = 'green.500';
      textColour = 'white';
      icon = <FontAwesomeIcon icon={faCheck} />;
      break;
    case TaskItem_Status.SKIPPED:
      icon = <FontAwesomeIcon icon={faArrowRight} />;
      iconColour = 'gray.500';
      break;
    case TaskItem_Status.INCORRECT:
      icon = <FontAwesomeIcon icon={faTimes} />;
      iconColour = 'red.500';
      break;
  }

  return (
    <NavigatorButton
      selected={selected}
      onClick={onClick}
      backgroundColour={backgroundColour}
      textColour={textColour}
      id={`nav-${index}`}
    >
      <span translate="no">Q{index}</span>
      {icon && (
        <Box pl={2} mb={-1} fontSize="lg" color={selected ? 'white' : iconColour}>
          {icon}
        </Box>
      )}
    </NavigatorButton>
  );
};

interface NavigatorButtonProps {
  children: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
  backgroundColour?: string;
  textColour?: string;
  width?: number;
  id?: string;
}

const NavigatorButton = ({
  children,
  selected,
  onClick,
  backgroundColour = 'gray.200',
  textColour = 'gray.800',
  width = 75,
  id,
}: NavigatorButtonProps) => (
  <Button
    className={classNames(styles.Button, {
      [styles.Selected]: selected,
    })}
    bg={selected ? 'blue.800' : backgroundColour}
    color={selected ? 'white' : textColour}
    _hover={selected ? { bg: 'blue.800' } : {}}
    _focus={selected ? { bg: 'blue.800' } : {}}
    w={[`${width * 0.9}px`, `${width}px`]}
    borderBottomRadius={0}
    onClick={onClick}
    flexShrink={0}
    id={id}
    fontSize={['sm', 'md']}
  >
    {children}
    <Box className={classNames(styles.ChevronContainer, selected && styles.ChevronContainerActive)}>
      <Box borderColor="blue.800" className={styles.Chevron} />
    </Box>
  </Button>
);

const RapidFireNav = ({ task }: NavigatorProps) => {
  const summary = getCompletionSummary(task?.state?.completion);
  const items = task?.contents?.taskItems || [];

  const itemProportion = 100 / items.length;
  for (const item of items) {
    if (item.state && !item.state?.completed) {
      const annotations = item.state.annotations;
      const totalSteps = parseInt(annotations['total_steps'] || '0');
      const correctSteps = parseInt(annotations['correct_steps'] || '0');
      if (totalSteps > 0) {
        const stepProportion = correctSteps / totalSteps;
        summary.percentages['C'].roundedPercentage += stepProportion * 0.5 * itemProportion;
      }
    }
  }

  const percentage = summary.percentages['C'].roundedPercentage;
  return (
    <Flex alignItems="center" className={styles.RapidFireNav}>
      <Image src={RapidFireIcon} height="20px" mr={2} />
      <Heading size="md" color="white" mr={4} letterSpacing="-0.1rem">
        Flashcards
      </Heading>
      <ProgressBar
        animateFill={true}
        animateScale={false}
        completionBarClassName={styles.RapidFireProgressBar}
        percentComplete={percentage}
      />
    </Flex>
  );
};
