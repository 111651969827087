import { XpAward, XpAward_Reason } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { createContext, useContext } from 'react';

export const useXpContext = () => useContext(XpContext);

export const XpContext = createContext<XpContextType>({
  addNewAwards: () => undefined,
  getAwardsToShow: () => [],
  markAwardsAsShown: () => undefined,
  showLevelUp: false,
  level: 0,
  markLevelUpSeen: () => undefined,
});

type XpContextType = {
  // addNewAwards notifies xpManager of new awards, so they can be handled appropriately
  addNewAwards: (newAwards: XpAward[]) => void;
  // getAwardsToShow returns all awards of a given reason that have not yet been shown
  getAwardsToShow: (reason: XpAward_Reason) => XpAward[];
  // markAwardsAsShown marks all awards of a given reason as shown
  markAwardsAsShown: (reason: XpAward_Reason) => void;
  // showLevelUp is a boolean indicating whether a levelup should be shown
  showLevelUp: boolean;
  // level is the current user level, as fetched from the xpState query (up to date with the step we are on, but not
  // necessarily with the server)
  level: number;
  // markLevelSeen marks a levelup as seen, so it will not be shown to the user again
  markLevelUpSeen: (level: number) => void;
};
