import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import { simulateClickOnEnter } from '@sparx/react-utils/keyboard';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { useBreakpoint } from '../../hooks';
import { MinusIcon, PlusIcon, TimesIcon, ZoomInIcon } from '../../icons';
import dialogStyles from '../../shared-styles/Dialog.module.css';
import { Button } from '../button/Button';
import styles from './ZoomableDialog.module.css';

export type ZoomableDialogChoice = {
  // if onSelect is provided, the select button will be shown on the dialog, and the onSelect function
  // will be called when the button displayed choice is unselected and the button is clicked
  onSelect?: () => void;
  element: ReactNode;
};

/**
 * ZoomableDialog adds a zoom button beneath its children that opens a dialog which contains the
 * children, allowing the user to zoom in on them.
 * It may optionally take an array of choices, in which case the dialog will show those choices instead
 * of the child when opened, with buttons to allow scrolling though them.
 * If choices are passed the initialDisplayedChoice and selectedChoice props will determine which
 * choice is initially displayed and which is shown as selected.
 */
export const ZoomableDialog = ({
  containerClassName,
  showZoomControls,
  choices,
  initialDisplayedChoice = 0,
  selectedChoice,
  onZoomClick,
  children,
}: {
  containerClassName?: string;
  showZoomControls?: boolean;
  choices?: ZoomableDialogChoice[];
  initialDisplayedChoice?: number;
  selectedChoice?: number;
  onZoomClick?: () => void;
  children?: ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const [displayedChoice, setDisplayedChoice] = useState(initialDisplayedChoice);
  const displayedChoiceSelected = selectedChoice === displayedChoice;

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    setLoaded(false);
    if (open) {
      setDisplayedChoice(initialDisplayedChoice);
    }
  };

  const zoomableDivRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollToCenter = (newZoomLevel: number) => {
    // Get the zoomableDiv and container from the DOM
    const zoomableDiv = zoomableDivRef.current;
    const container = containerRef.current;

    if (zoomableDiv && container) {
      // Scale the zoomable div
      zoomableDiv.style.transform = `scale(${newZoomLevel})`;
      zoomableDiv.style.transformOrigin = `center center`;

      const zoomHeight = zoomableDiv.getBoundingClientRect().height || 0;
      const zoomWidth = zoomableDiv.getBoundingClientRect().width || 0;
      const containerHeight = container.getBoundingClientRect().height || 0;
      const containerWidth = container.getBoundingClientRect().width || 0;

      // shift down and right so that the whole image is visible. We must do this, or have the transform origin
      // be top left, and deal with shifting it up and right instead.
      const top = zoomHeight > containerHeight ? (zoomHeight - containerHeight) / 2 : 0;
      const left = zoomWidth > containerWidth ? (zoomWidth - containerWidth) / 2 : 0;
      zoomableDiv.style.position = 'relative';
      zoomableDiv.style.top = `${top}px`;
      zoomableDiv.style.left = `${left}px`;

      // work out difference between container and zoomable div's original sizes, so we can account
      // for the offset caused by flex centering
      const widthOffset = containerWidth - zoomWidth / newZoomLevel;
      const heightOffset = containerHeight - zoomHeight / newZoomLevel;

      container.scrollTop = (container.scrollHeight - zoomableDiv.scrollHeight - heightOffset) / 2;
      container.scrollLeft = (container.scrollWidth - zoomableDiv.scrollWidth - widthOffset) / 2;
    }
  };

  const zoomAndScroll = useCallback((newZoomLevel: number) => {
    setZoomLevel(newZoomLevel);
    scrollToCenter(newZoomLevel);
  }, []);

  const zoomIn = () => {
    zoomAndScroll(zoomLevel * 1.2);
  };
  const zoomOut = () => {
    zoomAndScroll(zoomLevel / 1.2);
  };

  const isSmall = useBreakpoint('sm');

  const loadingAborter = useRef(new AbortController());

  // check if any images need to be loaded within the zoom div
  const [loaded, setLoaded] = useState(false);
  // this function should only be called once per element, but we abort the signals every time we call
  // it to be sure we aren't stacking up loads of listeners.
  const loadingStateRef = useCallback(
    (node: HTMLDivElement | null) => {
      loadingAborter.current.abort();
      loadingAborter.current = new AbortController();
      if (!loaded) {
        let imagesToLoad = 0;
        node?.querySelectorAll('img').forEach(img => {
          if (!img.complete) {
            imagesToLoad++;
            img.addEventListener(
              'load',
              () => {
                imagesToLoad--;
                if (imagesToLoad === 0) {
                  setLoaded(true);
                }
              },
              { signal: loadingAborter.current.signal },
            );
          }
        });
        if (imagesToLoad === 0) {
          setLoaded(true);
        }
      }
    },
    [loaded],
  );

  // when the modal is open and loaded, zoom as far as we can without causing scroll
  useEffect(() => {
    if (loaded && open) {
      if (containerRef.current && zoomableDivRef.current) {
        // work out what zoom level we can go to
        const containerBoundingRect = containerRef.current.getBoundingClientRect();

        const maxWidthZoom =
          Math.floor(containerBoundingRect.width) / Math.ceil(zoomableDivRef.current.clientWidth);
        const maxHeightZoom =
          Math.floor(containerBoundingRect.height) / Math.ceil(zoomableDivRef.current.clientHeight);

        const maxAllowableZoom = Math.min(maxWidthZoom, maxHeightZoom);
        zoomAndScroll(maxAllowableZoom);
      }
    }
  }, [loaded, open, zoomAndScroll]);

  useEffect(() => {
    if (!open) {
      return;
    }
    const doZoomAndScrollOnResize = () => {
      zoomAndScroll(zoomLevel);
    };
    window.addEventListener('resize', doZoomAndScrollOnResize);
    return () => window.removeEventListener('resize', doZoomAndScrollOnResize);
  }, [open, zoomAndScroll, zoomLevel]);

  useEffect(() => {
    zoomAndScroll(zoomLevel);
  }, [zoomAndScroll, zoomLevel]);

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
      <Button
        as={Dialog.Trigger}
        leftIcon={<ZoomInIcon />}
        size="sm"
        className={styles.ZoomButton}
        onKeyDown={simulateClickOnEnter}
        onClick={e => {
          e.stopPropagation();
          onZoomClick?.();
        }}
        tabIndex={0}
      >
        <span>Zoom</span>
      </Button>
      <Dialog.Portal>
        <Dialog.Overlay
          className={dialogStyles.DialogOverlay}
          // Prevent clicks bubbling up to the element we clicked to enter this dialog, which we are
          // still a (React) child of.
          // Manually close the modal on click outside
          onClick={e => {
            setOpen(false);
            e.stopPropagation();
          }}
        />
        <Dialog.Content
          // Prevent clicks and keypresses bubbling
          // Allow Escape to bubble so it can close the dialog.
          onClick={e => e.stopPropagation()}
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
          className={classNames(
            dialogStyles.DialogContent,
            dialogStyles.FullWidth,
            styles.ZoomableDialogContent,
          )}
        >
          {(showZoomControls || choices) && (
            <div className={styles.DialogTopBanner}>
              {showZoomControls && (
                <div className={styles.ZoomOptions}>
                  {!isSmall && 'Image zoom'}
                  <Button
                    variant="contained"
                    onClick={zoomIn}
                    onKeyDown={simulateClickOnEnter}
                    className={styles.ZoomButtonControls}
                    size="sm"
                  >
                    <PlusIcon className={styles.ZoomControlIcon} />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={zoomOut}
                    onKeyDown={simulateClickOnEnter}
                    className={styles.ZoomButtonControls}
                    size="sm"
                  >
                    <MinusIcon className={styles.ZoomControlIcon} />
                  </Button>
                </div>
              )}
              {choices && (
                <div className={styles.ChoicesControls}>
                  {choices.length > 1 && (
                    <>
                      {!isSmall && `Showing ${displayedChoice + 1}/${choices.length}`}
                      <Button
                        size="sm"
                        onClick={() => {
                          setDisplayedChoice(i => (choices.length + i - 1) % choices.length);
                          setLoaded(false);
                        }}
                      >
                        <FontAwesomeIcon transform={{ y: 1 }} icon={faChevronLeft} />
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          setDisplayedChoice(i => (i + 1) % choices.length);
                          setLoaded(false);
                        }}
                      >
                        <FontAwesomeIcon transform={{ y: 1 }} icon={faChevronRight} />
                      </Button>
                    </>
                  )}
                  {choices[displayedChoice].onSelect && (
                    <Button
                      className={classNames(
                        styles.SelectButton,
                        displayedChoiceSelected && styles.Selected,
                      )}
                      // onSelect will never be undefined here because of the check above
                      onClick={() => {
                        choices[displayedChoice].onSelect?.();
                        setOpen(false);
                      }}
                      size="sm"
                      variant={'outlined'}
                      isDisabled={displayedChoiceSelected}
                    >
                      {displayedChoiceSelected ? `Selected` : 'Select'}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(showZoomControls && styles.ScrollableContainer)}
            ref={containerRef}
          >
            <div className={classNames(styles.DialogContainer, containerClassName)}>
              <div
                className={styles.ZoomDiv}
                ref={n => {
                  zoomableDivRef.current = n;
                  loadingStateRef(n);
                }}
              >
                {choices ? choices[displayedChoice].element : children}
              </div>
            </div>
          </div>
          <div className={styles.CloseButton}>
            <Button
              as={Dialog.Close}
              rightIcon={<TimesIcon />}
              size="md"
              onKeyDown={simulateClickOnEnter}
            >
              Close
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
