import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Date as gDate } from '@sparx/api/google/type/date';
import { useDisclosure } from '@sparx/react-utils';
import { useMutation } from '@tanstack/react-query';
import { reportsClient } from 'api';
import { useInterimAwareSchoolYear } from 'api/schoolcalendar';
import { getSchoolID } from 'api/sessions';
import { useBreakpointValue } from 'components/chakraExports';
import { useClientEvent } from 'components/ClientEventProvider';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { SuspenseRoute } from 'components/suspenseroute/SuspenseRoute';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import { unstable_usePrompt } from 'react-router-dom';

import icon from './reporticon.svg';

export const ReportsView = () => {
  return (
    <SuspenseRoute>
      <SuspenseReportsView />
    </SuspenseRoute>
  );
};

const SuspenseReportsView = () => {
  return (
    <>
      <PageContainer>
        <PageTitle title="Reporting" />

        <Text mb={6} fontSize="lg" color="gray.500">
          Downloadable Reports
        </Text>

        <Box maxWidth="700px">
          <Box
            bg="white"
            boxShadow="elevationLow"
            borderRadius="md"
            p={6}
            display="flex"
            alignItems="flex-start"
          >
            <VStack spacing={3} alignItems="flex-start" mr={4}>
              <Text fontSize="lg" color="teal.800" fontWeight="bold">
                Student Activity Report
              </Text>
              <Text pb={2}>
                Get homework completion and student rewards data to help you praise students or
                intervene. Contextual data for each student allows you to filter the report by year,
                class or registration group.
              </Text>

              <GenerateReportModal />
            </VStack>
            <img src={icon} alt="" />
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};

const doDownloadUrl = (url: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = '';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const GenerateReportModal = () => {
  const { open, onOpen, onClose } = useDisclosure({ defaultOpen: false });
  const { sendEvent } = useClientEvent();

  const [includeDemographicData, setIncludeDemographicData] = useState(false);
  const [includeRewardsData, setIncludeRewardsData] = useState(false);
  const [includeTeacherNames, setIncludeTeacherNames] = useState(false);
  const { data: year } = useInterimAwareSchoolYear();
  const [dateSelection, setDateSelection] = useState<Range[]>([
    {
      startDate: year?.start,
      endDate: new Date(),
      showDateDisplay: false,
      key: 'selection',
    },
  ]);

  const { mutate, isLoading, error } = useMutation({
    mutationFn: async () => {
      const startTime = performance.now();
      return reportsClient
        .getStudentActivityReport({
          schoolName: `schools/${await getSchoolID()}`,
          startDate: dateSelection[0].startDate
            ? gDate.fromJsDate(dateSelection[0].startDate)
            : undefined,
          endDate: dateSelection[0].endDate
            ? gDate.fromJsDate(dateSelection[0].endDate)
            : undefined,
          includeDemographicData,
          includeTeacherNames,
          includeRewardsData,
        })
        .response.then(data => {
          sendEvent(
            { action: 'generate_student_activity_report', category: 'reporting' },
            {
              timeToGenerate: Math.round(performance.now() - startTime).toString(),
              startDate: dateSelection[0].startDate?.toISOString() || '',
              endDate: dateSelection[0].endDate?.toISOString() || '',
              includeDemographicData: includeDemographicData.toString(),
              includeRewardsData: includeRewardsData.toString(),
              includeTeacherNames: includeTeacherNames.toString(),
            },
          );
          return data;
        });
    },
    onSuccess: data => {
      doDownloadUrl(data.downloadUrl);
      onClose();
    },
  });

  unstable_usePrompt({
    message: 'A report is still generating. Are you sure you want to leave?',
    when: isLoading,
  });

  const months = useBreakpointValue({ base: 1, md: 2 });
  return (
    <>
      <Button colorScheme="buttonTeal" onClick={onOpen}>
        Generate Report...
      </Button>
      <Modal isOpen={open} onClose={onClose} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Student Activity Report</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align={'flex-start'}>
              <Text>
                Core student data (names, classes, year groups etc.) and homework completion data
                (weekly and aggregate completion rates) will always be included in the report.
              </Text>
              <Text>Choose additional data to include:</Text>
              <VStack spacing={0.5} align={'flex-start'}>
                <Checkbox
                  pb={2}
                  isChecked={includeDemographicData}
                  onChange={e => setIncludeDemographicData(e.target.checked)}
                >
                  Include demographic data
                  <InfoTooltip text="Provided we have permission to access the data in your school’s MIS, your report will include: pupil premium, free school meals, English as an additional language, and gender data." />
                </Checkbox>
                <Checkbox
                  pb={2}
                  isChecked={includeRewardsData}
                  onChange={e => setIncludeRewardsData(e.target.checked)}
                >
                  Include rewards data
                  <InfoTooltip text="Include XP and XP level." />
                </Checkbox>
                <Checkbox
                  pb={2}
                  isChecked={includeTeacherNames}
                  onChange={e => setIncludeTeacherNames(e.target.checked)}
                >
                  Include teacher names
                </Checkbox>
              </VStack>
              <Flex justifyContent={'center'} alignItems={'center'} w={'100%'}>
                <DateRange
                  months={months}
                  direction="horizontal"
                  editableDateInputs={true}
                  ranges={dateSelection}
                  onChange={item => setDateSelection([item.selection])}
                  minDate={year?.start}
                  maxDate={year?.end}
                  fixedHeight={true}
                  moveRangeOnFirstSelection={false}
                />
              </Flex>

              {Boolean(error) && (
                <Alert borderRadius="md" status="error">
                  <AlertIcon />
                  <Text>There was an error generating the report. Please try again later.</Text>
                </Alert>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="buttonTeal"
              rightIcon={<FontAwesomeIcon icon={faDownload} />}
              isLoading={isLoading}
              onClick={() => mutate()}
            >
              Download report (Excel)
            </Button>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
