import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setOutOfDateErrorCallback } from 'api/client';
import { useClientEvent } from 'components/ClientEventProvider';
import { useEffect, useState } from 'react';
import { attemptReload } from 'utils/attempt-reload';

export const ClientOutOfDateHandler = () => {
  const { sendEvent } = useClientEvent();
  const [outOfDate, setOutOfDate] = useState(false);
  const { isOpen, onOpen } = useDisclosure();

  useEffect(() => {
    setOutOfDateErrorCallback(() => {
      setOutOfDate(true);
    });
    return () => {
      setOutOfDateErrorCallback(undefined);
    };
  }, [onOpen]);

  useEffect(() => {
    if (outOfDate) {
      // Attempt a automatic reload, if we can't (because we've already tried recently) then show the modal
      const auto = attemptReload();
      sendEvent(
        { action: 'detected-out-of-date', category: 'client-out-of-date' },
        { auto_reloading: auto ? 'true' : 'false' },
        { immediate: true },
      );
      if (!attemptReload()) onOpen();
    }
  }, [outOfDate, onOpen, sendEvent]);

  return (
    <Modal isOpen={isOpen} onClose={() => undefined} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          <Box as="span" color="orange.500">
            <FontAwesomeIcon icon={faWarning} />
          </Box>{' '}
          Sparx Science needs to Update
        </ModalHeader>
        <ModalBody textAlign="center">
          <Text>
            The version of Sparx Science you are currently using is out of date and needs to be
            updated to continue.
          </Text>
          <Text mt={4}>
            Please click &apos;Refresh&apos; to reload the page and switch to the latest version.
          </Text>
          <Text fontSize="small" mt={4} color="gray.500">
            If this message persists after reloading the page you may need to clear your browser
            cache.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            colorScheme="buttonTeal"
            onClick={() => {
              sendEvent({ action: 'clicked-reload', category: 'client-out-of-date' }, undefined, {
                immediate: true,
              });
              window.location.reload();
            }}
          >
            Refresh
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
