import { Hide } from 'components/chakraExports';

import { useShowGettingStartedMessaging, useShowNewYearSetupMessaging } from '../hooks';
import { GettingStartedHeader } from './GettingStartedHeader';
import { NewYearSetupHeader } from './NewYearSetupHeader';

export const SchoolSetupHeader = () => {
  const gettingStarted = useShowGettingStartedMessaging();
  const newYear = useShowNewYearSetupMessaging();

  return (
    <Hide below="md">
      {newYear ? (
        newYear !== 'before' && <NewYearSetupHeader />
      ) : gettingStarted ? (
        <GettingStartedHeader />
      ) : null}
    </Hide>
  );
};
