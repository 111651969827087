import { Button } from '@sparx/design/components/button/Button';

import Card from './Card';
import styles from './Errors.module.css';

export default function NotFound({ home = '/' }: { home?: string }) {
  return (
    <Card>
      <p className={styles.Title}>Not Found</p>
      <p className={styles.Content}>Sorry, we couldn&apos;t find that page.</p>
      <Button className={styles.Button} as="a" href={home} variant="primary">
        Go Home
      </Button>
    </Card>
  );
}
