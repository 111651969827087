import { SchoolYear } from '@sparx/api/apis/sparx/school/calendar/v4/calendar';
import {
  IdentityUserType,
  UpdateSchoolCalendarRolloverStepAnnotationsRequest,
} from '@sparx/api/apis/sparx/school/v2/schoolactions';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { useMutation } from '@tanstack/react-query';
import { schoolActionsClient } from 'api';

import { queryClient } from './client';
import { SchoolCalendarQueryKey } from './schoolcalendar';
import { getSchoolID } from './sessions';

export const useCompleteRollover = () =>
  useMutation(
    async () =>
      await schoolActionsClient.completeRollover({
        schoolName: `schools/${await getSchoolID()}`,
        product: Product.SPARX_SCIENCE,
        allProducts: false,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([SchoolCalendarQueryKey]),
    },
  );

export const useUpdateRolloverAnnotations = () =>
  useMutation({
    mutationFn: async ({
      currentYear,
      annotations,
    }: {
      currentYear: boolean;
      annotations: {
        [key: string]: string;
      };
    }) => {
      const yearUpdate: SchoolYear = SchoolYear.create({
        rolloverState: {
          scienceRolloverState: {
            annotations,
          },
        },
      });

      const req = UpdateSchoolCalendarRolloverStepAnnotationsRequest.create({
        schoolCalendar: {
          name: `schools/${await getSchoolID()}/calendar`,
          currentYear: currentYear ? yearUpdate : undefined,
          pendingYear: currentYear ? undefined : yearUpdate,
        },
        updateMask: FieldMask.create({
          paths: [
            `${currentYear ? 'current_year' : 'pending_year'}.rollover_state.science_rollover_state.annotations`,
          ],
        }),
      });

      return schoolActionsClient.updateSchoolCalendarRolloverStepAnnotations(req).response;
    },
    onSuccess: data => {
      queryClient.setQueryData([SchoolCalendarQueryKey], data);
    },
  });

export const useUnlinkStudentSSO = () =>
  useMutation({
    mutationFn: async ({ studentId }: { studentId: string }) => {
      const schoolName = `schools/${await getSchoolID()}`;
      return schoolActionsClient.unlinkSSOIdentity({
        schoolName,
        userType: IdentityUserType.STUDENT,
        userId: studentId,
      }).response;
    },
  });
