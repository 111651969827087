import { MessageType } from '@protobuf-ts/runtime';
import { RpcError } from '@protobuf-ts/runtime-rpc';

export interface BasicQueryOptions {
  suspense?: boolean;
  enabled?: boolean;
}

export const getUpdateMask = <T extends object, MT extends MessageType<T>>(
  mt: MT,
  obj: Partial<{ [P in keyof T]: unknown }>,
  omit?: Set<string>,
): string[] =>
  Object.keys(obj)
    .map(k => mt.fields.find(f => f.localName === k)?.name)
    .filter(n => !!n && !omit?.has(n)) as string[];

export const translateErrorMessage = (err: unknown) => {
  if (err instanceof RpcError) {
    if (err.code === 'NOT_FOUND') return 'Not found';
    if (err.code === 'INVALID_ARGUMENT') return 'Invalid argument';
    if (err.code === 'FAILED_PRECONDITION') return 'Invalid request';
    if (err.code === 'INTERNAL') return 'Internal server error';
    return err.message;
  }
  return 'Unknown error';
};
