import {
  BatchGetStudentXpStateRequest,
  ILTargetState,
  ListILTargetXpRequest,
  XpAward,
} from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { xpClient } from 'api';
import { useIsLqd } from 'components/xp/utils';
import { useCallback } from 'react';

import { Options } from './school';
import { fetchInterimAwareYear } from './schoolcalendar';
import { FeatureFlag, getSchoolID, useBooleanFlag } from './sessions';

const userXpQueryKey = ['userXpState'];

// useUserXpState is a query which fetches the current user's XP state.
// We disable the query when in lqd, and update it manually when xp is awarded.
export const useUserXpState = () => {
  const xpEnabled = useBooleanFlag(FeatureFlag.Xp);
  const isLqd = useIsLqd();
  return useQuery({
    queryKey: userXpQueryKey,
    queryFn: () => xpClient.getCurrentUserXpState({}).response,
    suspense: true,
    select: data => data.xpState,
    enabled: xpEnabled && !isLqd,
  });
};

// useUpdateUserXpState returns a function to update the useXpStateQuery to reflect the most recent XP state after the
// given awards.
export const useUpdateUserXpState = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (awards: XpAward[]) => {
      const state = awards[awards.length - 1]?.xpStateAfterAward;
      if (state) {
        queryClient.setQueryData(userXpQueryKey, { xpState: state });
      }
    },
    [queryClient],
  );
};

export const useStudentXpState = (studentId: string) => {
  return useQuery({
    queryKey: ['studentXpState', studentId],
    queryFn: async () =>
      xpClient.batchGetStudentXpState(
        BatchGetStudentXpStateRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
          studentNames: ['students/' + studentId],
        }),
      ).response,
    suspense: true,
    select: data =>
      data.xpStateMap['students/' + studentId] || {
        currentXp: 0,
        level: 0,
        studentName: 'students/' + studentId,
      },
  });
};

export const useILXpTargetStates = (assignmentID: string, options: Options) =>
  useQuery({
    queryKey: ['independentlearning', 'assignment', assignmentID, 'xp_target_summaries'],
    queryFn: async () =>
      xpClient.listILTargetXp(
        ListILTargetXpRequest.create({
          schoolName: `schools/${await getSchoolID()}`,
          query: {
            oneofKind: 'assignmentName',
            assignmentName: `assignments/${assignmentID}`,
          },
        }),
      ).response,
    select: data => data.ilTargetXpStates,
    ...options,
  });

export const useILXpTargetStatesForStudent = (studentID: string, options: Options) =>
  useQuery({
    queryKey: ['independentlearning', 'student', studentID, 'xp_target_summaries'],
    queryFn: async () => {
      const year = await fetchInterimAwareYear();
      if (!year) return [];
      return xpClient
        .listILTargetXp(
          ListILTargetXpRequest.create({
            schoolName: `schools/${await getSchoolID()}`,
            query: {
              oneofKind: 'studentQuery',
              studentQuery: {
                studentName: `students/${studentID}`,
                dateRange: {
                  startTime: Timestamp.fromDate(year.start),
                  endTime: Timestamp.fromDate(year.end),
                },
              },
            },
          }),
        )
        .response.then(data => data.ilTargetXpStates || []);
    },
    ...options,
  });

export interface ILTargetStateWithDates extends ILTargetState {
  startDate: Date;
  endDate: Date;
}

export const useUserILTargets = () => {
  const xpEnabled = useBooleanFlag(FeatureFlag.Xp);
  return useQuery({
    queryKey: ['userILXpTargets'],
    queryFn: () => {
      return xpClient.listCurrentUserILTargetXp({}).response;
    },
    select: data =>
      (data.ilTargetXpStates || [])
        .filter(t => t.endTimestamp && t.startTimestamp && t.targetXp > 0)
        .map<ILTargetStateWithDates>(t => ({
          ...t,
          startDate: Timestamp.toDate(t.startTimestamp!),
          endDate: Timestamp.toDate(t.endTimestamp!),
        }))
        .sort(
          (a, b) =>
            a.endDate.getTime() - b.endDate.getTime() ||
            a.startDate.getTime() - b.startDate.getTime(),
        ),
    suspense: true,
    enabled: xpEnabled,
  });
};
