import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import { getCompletionSummary, HandinStatus, useHandInStatus } from '@sparx/packageactivity';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIndependentLearningContext } from '../Context';
import practiceResume from '../images/icon_practice_resume.svg';
import { ILChevron } from './Misc';
import { ILCard } from './PersonalPracticeCard';

export const ContinuePractice = () => {
  const { previousPackages } = useIndependentLearningContext();
  const navigate = useNavigate();

  const firstIncomplete = useMemo(
    () =>
      previousPackages.find(
        pkg =>
          getCompletionSummary(pkg.state?.completion, undefined).status !== HandinStatus.Complete,
      ),
    [previousPackages],
  );

  const { percentages } = useHandInStatus(firstIncomplete?.state?.completion, undefined);

  if (!firstIncomplete) {
    return null;
  }

  const percentageCorrect = percentages.C.roundedPercentage;

  return (
    <ILCard title="Previous tasks" image={practiceResume}>
      <Text mb={2} maxWidth="75%" position="relative">
        Carry on with your previous task:
        <br />
        <strong>{firstIncomplete.title}</strong>
      </Text>
      <Flex grow={1} />
      <HStack spacing={4} mt={2}>
        <Button
          onClick={() => {
            navigate(`/${firstIncomplete.name}`);
          }}
          rightIcon={<ILChevron />}
          maxWidth={200}
        >
          Continue
          {percentageCorrect > 0 && (
            <Text fontWeight="bold" color="green" ml={2}>
              {percentageCorrect}%
            </Text>
          )}
        </Button>
        <Button
          onClick={() => {
            navigate(`/independentlearning/previoustasks`);
          }}
          maxWidth={200}
        >
          View all
        </Button>
      </HStack>
    </ILCard>
  );
};
