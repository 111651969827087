import {
  CreateSyncPlanResponse,
  PreviewSyncSchoolV2Response,
} from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync';
import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import {
  backToConfigFromSync,
  cancelledPreview,
  previewLoadedWithNoChanges,
  previewSyncError,
  syncComplete,
  syncError,
  syncPlanCreated,
  syncStarted,
  viewedPreview,
} from '@sparx/mis-sync-import/src/analytics';
import { useMisSyncContext } from '@sparx/mis-sync-import/src/Context';
import {
  useCheckForRemoteUpdates,
  useConflictingStudents,
  useGetSchoolData,
  useGetWondeData,
  useRemoveScroll,
  useSetContainerHeight,
  useTimer,
  useUnsavedChangesCount,
} from '@sparx/mis-sync-import/src/hooks';
import { BlockSyncMessage } from '@sparx/mis-sync-import/src/MisSyncImport/components/BlockSyncMessage';
import { SupportLink } from '@sparx/mis-sync-import/src/MisSyncImport/components/common';
import Button from '@sparx/mis-sync-import/src/MisSyncImport/components/common/Button/Button';
import ErrorPanel from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/ErrorPanel/ErrorPanel';
import GenericErrorPanel from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/GenericErrorPanel';
import PreviewError from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/PreviewError';
import { TroubleshootingMessage } from '@sparx/mis-sync-import/src/MisSyncImport/components/errors/PreviewError/TroubleshootingMessage';
import SyncProgress from '@sparx/mis-sync-import/src/MisSyncImport/components/SyncProgress/SyncProgress';
import {
  SyncConfigProvider,
  useSyncConfig,
} from '@sparx/mis-sync-import/src/MisSyncImport/context/config';
import styles from '@sparx/mis-sync-import/src/MisSyncImport/MisSyncImport.module.css';
import LoadingMessage from '@sparx/mis-sync-import/src/MisSyncImport/pages/LoadingMessage';
import MisSyncConfigPage from '@sparx/mis-sync-import/src/MisSyncImport/pages/MisSyncConfigPage';
import MisSyncPreviewPage from '@sparx/mis-sync-import/src/MisSyncImport/pages/MisSyncPreviewPage';
import {
  activeSyncInProgressKey,
  initialSyncInProgressKey,
  manualSyncInProgressKey,
  schoolDataQueryKey,
  useApplySyncPlan,
  useAutoPollingSyncInProgress,
  useCreateSyncPlan,
  useSyncInProgress,
} from '@sparx/mis-sync-import/src/queries';
import { SchoolData, WondeClass, WondeData } from '@sparx/mis-sync-import/src/types';
import {
  convertSyncConfigToCreateSyncPlanRequest,
  getSubjectsWithUPNConflicts,
  getSystemOptions,
  getWondeSyncErrors,
  schoolIDFromName,
} from '@sparx/mis-sync-import/src/utils';
import {
  convertCreateSyncPlanResponseToPreviewResponse,
  syncPlanHasChanges,
} from '@sparx/mis-sync-import/src/utils/syncplan';
import { useBeforeUnload } from '@sparx/react-utils';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

enum Step {
  Configuration,
  Preview,
  Sync,
}

const RemoteSyncErrorContent = () => {
  return (
    <>
      <p>Failed to check for remote syncs.</p>
      <p>
        Please click &quot;Retry&quot; and <SupportLink linkText="contact support" /> if the issue
        persists.
      </p>
    </>
  );
};

/**
 * Entry point for the MIS sync import component.
 * @constructor
 */
export const MisSyncImport = () => {
  useRemoveScroll();

  const { reloadSchool } = useMisSyncContext();
  const queryClient = useQueryClient();

  // Invalidate the initial sync in progress key when the component is unmounted. This is to
  // ensure that when we come back to the page this is checked immediately.
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([initialSyncInProgressKey]);
      queryClient.invalidateQueries([schoolDataQueryKey]);
    };
  }, [queryClient]);

  const {
    data: syncInProgressData,
    isFetching,
    isError,
    startPolling,
  } = useAutoPollingSyncInProgress([initialSyncInProgressKey], true, {
    onSuccess: data => {
      // If we get false back, it means that there is no longer a sync running and we should reload
      // the school to get the latest annotations.
      if (data.inProgress === false) {
        reloadSchool();
      }
    },
  });

  const syncInProgress = syncInProgressData?.inProgress;

  if (isError) {
    return (
      <ErrorPanel action={() => startPolling()} actionText="Retry">
        <RemoteSyncErrorContent />
      </ErrorPanel>
    );
  }

  if (syncInProgress) {
    return <LoadingMessage message="Waiting for active sync to finish" />;
  }

  if (isFetching) {
    return <LoadingMessage message="Checking for running syncs" />;
  }

  return (
    <SyncConfigProvider>
      <Main />
    </SyncConfigProvider>
  );
};

/**
 * Main component for the MIS sync import page. This component is responsible for loading the data
 * required for the sync and managing the loading state for that data.
 * @constructor
 */
const Main = () => {
  const { school, groupSubject, sparxStaffFeaturesEnabled } = useMisSyncContext();

  const schoolName = school?.name;
  const { dispatch } = useSyncConfig();
  // Set some initial state to track whether we have dispatched the initial sync config to get
  // existing classes.
  const [initialSyncConfigCalculated, setInitialSyncConfigCalculated] = useState(false);
  const [forceIfUnauthorised, setForceIfUnauthorised] = useState(false);

  const {
    schoolData,
    loading: schoolDataLoading,
    error: schoolDataError,
  } = useGetSchoolData(schoolName);

  const {
    wondeData,
    loading: wondeDataLoading,
    error: wondeDataError,
    hasWondeTroubleshootingErrors,
  } = useGetWondeData(schoolName);

  // Whenever we have loaded new school groups, update the classes in the sync config.
  useEffect(() => {
    let wondeClasses = wondeData?.wondeClasses;
    // If we're forcing the sync even though we're unauthorised, we treat it as if we have had empty
    // data returned from Wonde.
    if (forceIfUnauthorised) {
      wondeClasses = [];
    }
    if (schoolData?.studentGroups && schoolData?.yearGroups && wondeClasses) {
      dispatch({
        type: 'update_classes',
        classes: schoolData.studentGroups,
        yearGroups: schoolData.yearGroups,
        wondeClasses,
        groupTypeFilter: groupSubject,
      });
      // Once the data has loaded and we have updated the classes, we can set the initial sync
      // as calculated.
      setInitialSyncConfigCalculated(true);
    }
  }, [dispatch, groupSubject, schoolData, wondeData, forceIfUnauthorised]);

  // If turning off Sparx staff mode, reset any class matches that were made
  useEffect(() => {
    if (!sparxStaffFeaturesEnabled) {
      dispatch({ type: 'reset_class_matches' });
    }
  }, [dispatch, sparxStaffFeaturesEnabled]);

  // If we're forcing the sync even though we're unauthorised, we should show the sync page even
  // though there's an error.
  if (forceIfUnauthorised && school && schoolData) {
    return (
      <SyncContainer
        school={school}
        wondeData={{
          wondeClasses: [],
          wondeSubjects: {},
        }}
        schoolData={schoolData}
        // We can set this to true as we're forcing the sync so we can consider the initial sync
        // config as calculated.
        initialSyncConfigCalculated={true}
        forceIfUnauthorised={forceIfUnauthorised}
        setForceIfUnauthorised={setForceIfUnauthorised}
      />
    );
  }

  if (schoolDataError || wondeDataError) {
    if (hasWondeTroubleshootingErrors) {
      return (
        <>
          <TroubleshootingMessage />
          {sparxStaffFeaturesEnabled && (
            <div className={styles.SparxStaffButtonContainer}>
              <Button onClick={() => setForceIfUnauthorised(true)}>
                Sparx Staff - Continue anyway
              </Button>
            </div>
          )}
        </>
      );
    }
    return <GenericErrorPanel task="load your classes and subjects" />;
  }

  if (schoolDataLoading || !schoolData || !school) {
    return <LoadingMessage message="Loading student and class data" />;
  }

  if (wondeDataLoading || !wondeData) {
    return <LoadingMessage message="Loading data from your MIS" />;
  }

  return (
    <SyncContainer
      school={school}
      wondeData={wondeData}
      schoolData={schoolData}
      initialSyncConfigCalculated={initialSyncConfigCalculated}
      forceIfUnauthorised={forceIfUnauthorised}
      setForceIfUnauthorised={setForceIfUnauthorised}
    />
  );
};

/**
 * Container component for the MIS sync import. This component is responsible for managing the height
 * of the container and passing the required data to the MisSync component.
 * @param school
 * @param wondeData
 * @param schoolData
 * @param initialSyncConfigCalculated
 * @param forceIfUnauthorised
 * @param setForceIfUnauthorised
 * @constructor
 */
const SyncContainer = ({
  school,
  wondeData,
  schoolData,
  initialSyncConfigCalculated,
  forceIfUnauthorised,
  setForceIfUnauthorised,
}: {
  school: School;
  wondeData: WondeData;
  schoolData: SchoolData;
  initialSyncConfigCalculated: boolean;
  forceIfUnauthorised: boolean;
  setForceIfUnauthorised: (force: boolean) => void;
}) => {
  const { groupSubject } = useMisSyncContext();
  const { dataTheme } = getSystemOptions(groupSubject);
  const { ref, height } = useSetContainerHeight();

  return (
    <div ref={ref} style={{ height }} className={styles.MisSyncContainer} data-theme={dataTheme}>
      <MisSync
        school={school}
        misData={wondeData}
        schoolData={schoolData}
        initialSyncConfigCalculated={initialSyncConfigCalculated}
        forceIfUnauthorised={forceIfUnauthorised}
        setForceIfUnauthorised={setForceIfUnauthorised}
      />
    </div>
  );
};

/**
 * Main MIS sync import component. This component is responsible for managing the flow of the MIS sync
 * import process.
 * @param school
 * @param misData
 * @param schoolData
 * @param initialSyncConfigCalculated
 * @param forceIfUnauthorised
 * @param setForceIfUnauthorised
 * @constructor
 */
const MisSync = ({
  school,
  misData,
  schoolData,
  initialSyncConfigCalculated,
  forceIfUnauthorised,
  setForceIfUnauthorised,
}: {
  school: School;
  misData: WondeData;
  schoolData: SchoolData;
  initialSyncConfigCalculated: boolean;
  forceIfUnauthorised: boolean;
  setForceIfUnauthorised: (force: boolean) => void;
}) => {
  const timer = useTimer();
  const { hasRemoteUpdates, checkForRemoteUpdates, resetLastSyncTime } =
    useCheckForRemoteUpdates(school);

  // Initial preview state to track whether we have loaded the initial preview. Cache the data returned so
  // it isn't recalculated when moving back from the preview page after making changes and not syncing
  const [initialPreview, setInitialPreview] = useState<{
    complete: boolean;
    errored: boolean;
    data: PreviewSyncSchoolV2Response | undefined;
  }>({
    complete: false,
    data: undefined,
    errored: false,
  });

  const [selectedClasses, setSelectedClasses] = useState<Set<WondeClass>>(new Set());
  const {
    groupSubject,
    reloadSchool,
    onUnsavedChanges,
    onSyncComplete,
    sendEvent,
    sparxStaffFeaturesEnabled,
    schoolInInterim,
  } = useMisSyncContext();
  const schoolId = schoolIDFromName(school.name);

  const { syncConfig, dispatch } = useSyncConfig();
  const queryClient = useQueryClient();

  const conflictingStudents = useConflictingStudents();

  const unsavedChangesCount = useUnsavedChangesCount();

  const [step, setStep] = useState(Step.Configuration);

  // Manual check for any active remote syncs. This is used to check whether there is a sync running
  // at certain points in the flow. It is enabled by default and it only triggered when the refetch
  // function is called.
  const {
    refetch: checkForRemoteSyncs,
    isFetching: checkForRemoteSyncsIsFetching,
    isError: remoteSyncInProgressError,
  } = useSyncInProgress([manualSyncInProgressKey], {
    enabled: false,
    retryDelay: 2000,
    retry: 2,
  });

  const [showRemoteSyncInProgress, setShowRemoteSyncInProgress] = useState(false);

  const retryRemoteSyncCheck = () => {
    setShowRemoteSyncInProgress(false);
    checkForRemoteSyncs();
  };

  const [activeSyncInProgress, setActiveSyncInProgress] = useState<{
    inProgress: boolean;
    lastSyncFailed: boolean;
  }>({
    inProgress: false,
    lastSyncFailed: false,
  });

  // Notify the parent component if there are any unsaved changes, so that it can prompt the user if they try to leave
  // to a different page in the app. (Ignore changes if a sync has just been completed)
  const unsavedChanges: boolean =
    (unsavedChangesCount > 0 || selectedClasses.size > 0) &&
    (step !== Step.Sync || activeSyncInProgress.inProgress);

  useEffect(() => {
    if (onUnsavedChanges) {
      onUnsavedChanges(unsavedChanges);
    }
  }, [onUnsavedChanges, unsavedChanges]);

  // Prompt the user if they try to leave the app with unsaved changes.
  useBeforeUnload(
    'You have unsaved changes. Are you sure you want to leave this page?',
    unsavedChanges,
  );

  // Clear the selected classes when we move between steps
  useEffect(() => {
    setSelectedClasses(new Set());
  }, [step]);

  // Auto poll for in progress sync and set activeSyncInProgress to false when the sync has finished
  // running. This is disabled by default (second param) and will only start polling when the
  // startPolling function is called.
  const { startPolling, isError: activeSyncInProgressError } = useAutoPollingSyncInProgress(
    [activeSyncInProgressKey],
    false,
    {
      onSuccess: data => {
        setActiveSyncInProgress(data);
        if (!data.inProgress) {
          resetLastSyncTime();
          reloadSchool();
          if (onSyncComplete) {
            onSyncComplete();
          }
        }
      },
    },
  );

  const [userTriggeredPreview, setUserTriggeredPreview] = useState(false);

  const createSyncPlan = useCreateSyncPlan({
    onMutate: () => timer.start(),
    onSuccess: data => {
      sendEvent(syncPlanCreated(timer.get()));
      const { hasClassChanges, hasStudentChanges, hasParentChanges } = syncPlanHasChanges(
        data.syncPlan,
      );
      if (!hasClassChanges && !hasStudentChanges && !hasParentChanges) {
        sendEvent(previewLoadedWithNoChanges());
      }
      timer.reset();
    },
    onError: error => {
      sendEvent(previewSyncError(error, timer.get()));
      timer.reset();
    },
    onSettled: (data, error) => {
      if (!initialPreview.complete) {
        setInitialPreview({
          complete: true,
          data: convertCreateSyncPlanResponseToPreviewResponse(
            data,
            groupSubject,
            schoolData.students,
            misData.wondeClasses,
            schoolData.studentGroups,
          ),
          errored: !!error,
        });
      }
    },
  });

  const previewLoading = createSyncPlan.isLoading;
  const previewError = createSyncPlan.error;

  // Send an initial preview to get back auth and last sync status. Only send the request if there
  // are classes to sync, otherwise it will return an error we don't want.
  useEffect(() => {
    // If we haven't calculated the initial sync config yet, or if we have already made the initial
    // preview request, then we can stop here.
    if (!initialSyncConfigCalculated || initialPreview.complete) {
      return;
    }

    // If the school is in interim the initial preview will return an error, so don't request it
    if (schoolInInterim) {
      setInitialPreview(s => ({ ...s, complete: true }));
      return;
    }

    if (!createSyncPlan.data && !createSyncPlan.isLoading && !createSyncPlan.isError) {
      const request = convertSyncConfigToCreateSyncPlanRequest(
        schoolId,
        syncConfig,
        groupSubject,
        conflictingStudents.defaultResolutionsByProduct,
      );
      // Set dry run to true so we don't store the sync plan.
      request.dryRun = true;
      // Skip the demographics as we don't care about them and they have a performance overhead.
      request.skipDemographics = true;
      request.forceIfUnauthorised = forceIfUnauthorised;
      createSyncPlan.mutate(request);
    }
  }, [
    createSyncPlan,
    misData,
    schoolData,
    schoolId,
    groupSubject,
    syncConfig,
    initialSyncConfigCalculated,
    conflictingStudents.defaultResolutionsByProduct,
    initialPreview.complete,
    schoolInInterim,
    forceIfUnauthorised,
  ]);

  const applySyncPlan = useApplySyncPlan({
    onMutate: () => timer.start(),
    onSuccess: () => {
      sendEvent(syncComplete(timer.get()));
      timer.reset();
    },
    onError: error => {
      sendEvent(syncError(error, timer.get()));
      timer.reset();
    },
  });

  const [syncPlanName, setSyncPlanName] = useState<string | null>(null);

  if (previewError && initialPreview.errored) {
    const errors = getWondeSyncErrors(previewError);
    if (errors.some(e => e.type === 'UNAUTHORIZED')) {
      return (
        <>
          <TroubleshootingMessage />
          {sparxStaffFeaturesEnabled && (
            <div className={styles.SparxStaffButtonContainer}>
              <Button
                onClick={() => {
                  // We want to force a sync even though we're unauthorised, so send another request
                  // to create a sync plan with the forceIfUnauthorised flag set to true.
                  setForceIfUnauthorised(true);
                  const request = convertSyncConfigToCreateSyncPlanRequest(
                    schoolId,
                    syncConfig,
                    groupSubject,
                    conflictingStudents.defaultResolutionsByProduct,
                  );
                  // Set dry run to true so we don't store the sync plan.
                  request.dryRun = true;
                  // Skip the demographics as we don't care about them and they have a performance overhead.
                  request.skipDemographics = true;
                  // We want to force a sync even though we're unauthorised.
                  request.forceIfUnauthorised = true;
                  createSyncPlan.mutate(request);
                }}
              >
                Sparx Staff - Continue anyway
              </Button>
            </div>
          )}
        </>
      );
    }
    if (errors.some(e => e.type === 'DUPLICATE_UPNS')) {
      // If there is just one error and it's a duplicate UPN error - if the if the error concerns any students in
      // classes for the current subject, show the full details of the error. Otherwise, if the UPN error concerns only
      // students in other subjects show a block sync message.
      if (errors.length === 1 && errors[0].type === 'DUPLICATE_UPNS') {
        const upnError = errors[0];

        const subjectsWithUPNConflicts = getSubjectsWithUPNConflicts(
          misData,
          syncConfig.existingClasses,
          upnError.duplicates.map(d => d.upn),
        );
        if (subjectsWithUPNConflicts.has(groupSubject)) {
          return <PreviewError error={previewError} />;
        } else {
          return <BlockSyncMessage groupTypesWithErrors={subjectsWithUPNConflicts} />;
        }
      }
      return <PreviewError error={previewError} />;
    }

    if (errors.some(e => e.type === 'RECTIFICATION_CONFLICTS')) {
      if (sparxStaffFeaturesEnabled) {
        return (
          <PreviewError
            error={previewError}
            sparxStudents={schoolData.students}
            wondeClasses={misData.wondeClasses}
          />
        );
      }
      return <GenericErrorPanel />;
    }

    const missingConflictResolution = errors.find(e => e.type === 'MISSING_CONFLICT_RESOLUTION');
    const duplicateClassNames = errors.find(e => e.type === 'DUPLICATE_CLASS_NAMES');
    // If there are any duplicate class names we will handle this on the configuration page and prevent the user from
    // continuing to the preview page with them imported.
    if (!duplicateClassNames && (!missingConflictResolution || userTriggeredPreview)) {
      return <GenericErrorPanel task="load your classes and subjects" />;
    }
  }

  // If we haven't finished loading the initial preview, continue to show a loading message.
  if (!initialPreview.complete && step === Step.Configuration) {
    return <LoadingMessage message="Checking for student changes" />;
  }

  const doPreview = async (timeVisible: number) => {
    // Confirm if the user has at least one class selected on the LHS
    if (selectedClasses.size > 0) {
      if (
        !window.confirm(
          `You have selected ${selectedClasses.size} class${
            selectedClasses.size === 1 ? '' : 'es'
          } on the left but not chosen to import ${
            selectedClasses.size === 1 ? 'it' : 'them'
          }. Are you sure you want to continue?`,
        )
      ) {
        return;
      }
    }

    // Reload the school to make sure no sync has been run since the last time we loaded the
    // school data. If there has been a sync, the sync config will be out of date and we should
    // ask the user to reload the page.
    checkForRemoteUpdates();

    // Reset the previous request to remove any existing errors.
    createSyncPlan.reset();

    setStep(Step.Preview);
    sendEvent(viewedPreview(timeVisible));
    checkForRemoteSyncs().then(({ data: syncInProgress }) => {
      if (syncInProgress?.inProgress) {
        setShowRemoteSyncInProgress(true);
        return;
      }
      setUserTriggeredPreview(true);
      const request = convertSyncConfigToCreateSyncPlanRequest(
        schoolId,
        syncConfig,
        groupSubject,
        conflictingStudents.defaultResolutionsByProduct,
      );
      request.forceIfUnauthorised = forceIfUnauthorised;
      createSyncPlan.mutate(request, {
        onSuccess: data => {
          if (data.syncPlan?.name) {
            setSyncPlanName(data.syncPlan.name);
          }
        },
      });
    });
  };

  const cancelPreview = (buttonLocation: 'top' | 'bottom', timeVisible: number) => {
    setStep(Step.Configuration);
    sendEvent(cancelledPreview(buttonLocation, timeVisible));
    checkForRemoteSyncs();
    createSyncPlan.reset();
  };

  const doSync = async (timeVisible: number) => {
    checkForRemoteUpdates();
    setActiveSyncInProgress({ inProgress: true, lastSyncFailed: false });
    setStep(Step.Sync);
    sendEvent(syncStarted(timeVisible));

    checkForRemoteSyncs().then(({ data: syncInProgress }) => {
      if (syncInProgress?.inProgress) {
        setShowRemoteSyncInProgress(true);
        return;
      }

      if (syncPlanName) {
        applySyncPlan.mutate(
          { name: syncPlanName, async: true },
          { onSuccess: () => startPolling() },
        );
      }
    });
  };

  const resetAfterSync = (timeVisible: number) => {
    dispatch({ type: 'reset' });
    // Invalidate any school data. This will be out of date due to the sync modifying the data. When
    // we load the student groups, they automatically set the existing classes in the sync config.
    queryClient.invalidateQueries([schoolDataQueryKey]);
    reloadSchool();

    if (onSyncComplete) {
      onSyncComplete();
    }

    setStep(Step.Configuration);
    sendEvent(backToConfigFromSync(timeVisible));
  };

  if (remoteSyncInProgressError || activeSyncInProgressError) {
    return (
      <ErrorPanel
        action={() => (remoteSyncInProgressError ? retryRemoteSyncCheck() : startPolling())}
        actionText="Retry"
      >
        <RemoteSyncErrorContent />
      </ErrorPanel>
    );
  }

  // This should only happen if there is a remote sync running when the user tries to preview or
  // apply a sync. We don't show this if on the Sync page, as that has a differently styled error message
  if ((showRemoteSyncInProgress || hasRemoteUpdates) && step !== Step.Sync) {
    return (
      <ErrorPanel
        title="Another user is currently running an MIS sync."
        action={() => window.location.reload()}
        actionText="Reload"
      >
        <p>Please wait and then try again.</p>
      </ErrorPanel>
    );
  }

  const isLoading = previewLoading || checkForRemoteSyncsIsFetching || !initialPreview.complete;

  if (checkForRemoteSyncsIsFetching && step === Step.Configuration) {
    return <LoadingMessage message="Checking for in progress syncs" />;
  }

  if (step === Step.Preview) {
    const data = convertCreateSyncPlanResponseToPreviewResponse(
      createSyncPlan.data,
      groupSubject,
      schoolData.students,
      misData.wondeClasses,
      schoolData.studentGroups,
    );

    return (
      <MisSyncPreviewPage
        preview={data}
        error={previewError}
        schoolData={schoolData}
        onCancel={(buttonLocation: 'top' | 'bottom', timeVisible: number) =>
          cancelPreview(buttonLocation, timeVisible)
        }
        onSync={(timeVisible: number) => doSync(timeVisible)}
        isLoading={isLoading}
        syncPlan={createSyncPlan.data?.syncPlan}
        wondeClasses={misData.wondeClasses}
      />
    );
  }

  if (step === Step.Sync) {
    // Note, if some things are loading, just show sync in progress
    return (
      <SyncProgress
        isComplete={!activeSyncInProgress.inProgress}
        onBack={(timeVisible: number) => resetAfterSync(timeVisible)}
        error={applySyncPlan.error}
        syncFailed={activeSyncInProgress.lastSyncFailed}
        otherUserRunningSync={showRemoteSyncInProgress || hasRemoteUpdates}
      />
    );
  }

  const hasStudentChangesSinceLastSync = getHasStudentChanges(createSyncPlan.data);
  const duplicateClassNames = getWondeSyncErrors(createSyncPlan.error).find(
    e => e.type === 'DUPLICATE_CLASS_NAMES',
  );

  return (
    <MisSyncConfigPage
      school={school}
      misData={misData}
      schoolData={schoolData}
      onPreview={(timeVisible: number) => doPreview(timeVisible)}
      hasStudentChangesSinceLastSync={hasStudentChangesSinceLastSync}
      selectedClasses={selectedClasses}
      setSelectedClasses={setSelectedClasses}
      unsavedChangesCount={unsavedChangesCount}
      duplicateClassNames={duplicateClassNames}
    />
  );
};

const getHasStudentChanges = (
  data: PreviewSyncSchoolV2Response | CreateSyncPlanResponse | undefined,
): boolean => {
  if (!data) {
    return false;
  }

  if ('syncPlan' in data) {
    const { hasStudentChanges, hasParentChanges } = syncPlanHasChanges(data.syncPlan);
    return hasStudentChanges || hasParentChanges;
  }

  if ('modifiedStudents' in data) {
    return (
      data.modifiedStudents.length > 0 ||
      data.newStudents.length > 0 ||
      data.removedStudents.length > 0 ||
      data.unexpiredStudents.length > 0
    );
  }

  return false;
};
