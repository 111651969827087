import {
  Box,
  Button,
  Flex,
  HStack,
  Link as ChakraLink,
  List,
  ListItem,
  Select,
  Text,
} from '@chakra-ui/react';
import { faBan, faCalendar, faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListPackageSummariesResponse,
  PackageSummary,
} from '@sparx/api/apis/sparx/science/packages/v1/package';
import {
  Assignment,
  Assignment_IgnoredUser_Reason,
} from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Subject } from '@sparx/api/apis/sparx/science/v1/subject';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { CompletionSummary, getCompletionSummary } from '@sparx/packageactivity';
import { UseQueryResult } from '@tanstack/react-query';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useQueryState } from 'api/client';
import { useIndependentLearningSummaries } from 'api/independentlearning';
import { useBatchPackageSummaries } from 'api/packages';
import { useAssignment } from 'api/planner';
import { dateInWeek, useStudentsLookup, useWeeks } from 'api/school';
import { useILXpTargetStates } from 'api/xp';
import classNames from 'classnames';
import { CancelledWarning, useCancelledStudents } from 'components/CancelledUtils';
import { useClientEvent } from 'components/ClientEventProvider';
import { StudentName } from 'components/Names';
import { ProgressBar } from 'components/progressbar/ProgressBar';
import { DataTable } from 'components/table/DataTable';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { InlineTextTooltip, Tooltip } from 'components/tooltip/Tooltip';
import { assignmentHasILTarget } from 'components/xp/utils';
import { format, isBefore } from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  getCompletionDateColour,
  getCompletionDateDays,
  getCompletionDateText,
} from 'utils/completion';
import { toTimeSpentDuration } from 'utils/duration';
import { articleCancellingHomework } from 'utils/knowledgeBaseArticles';
import { getRoundedPercentage } from 'utils/percentage';
import { plural } from 'utils/plural';
import { SubjectToName } from 'utils/subjects';

import styles from './HandInTable.module.css';

interface HandinTableProps {
  assignmentID: string;
  assignments?: Assignment[];
  assignmentLinker?: (assignmentID: string, userID?: string) => string;
}

interface HandinRowCommon {
  student?: Student;
  ilValue?: number;
  assignment?: Assignment;
  prevWeeks?: (CompletionSummary | undefined)[];
}

type HandinRow = HandinRowCommon &
  (
    | {
        homeworkOff: true;
      }
    | {
        homeworkOff: false;
        summary: PackageSummary;
        status: CompletionSummary;
        displayStatus: CompletionSummary | undefined;
      }
  );

const fallbackNameSortFn = (a: Row<HandinRow>, b: Row<HandinRow>) =>
  (a.original.student?.givenName || '').localeCompare(b.original.student?.givenName || '') ||
  (a.original.student?.familyName || '').localeCompare(b.original.student?.familyName || '');

const completionSortFn = (a: Row<HandinRow>, b: Row<HandinRow>) => {
  if (a.original.homeworkOff && b.original.homeworkOff) {
    return fallbackNameSortFn(a, b);
  } else if (a.original.homeworkOff) {
    return 1;
  } else if (b.original.homeworkOff) {
    return -1;
  }

  const aStatus = a.original.displayStatus;
  const bStatus = b.original.displayStatus;
  if (!aStatus && !bStatus) {
    return fallbackNameSortFn(a, b);
  } else if (!aStatus) {
    return 1;
  } else if (!bStatus) {
    return -1;
  }

  if (aStatus.cancelled && bStatus.cancelled) {
    return fallbackNameSortFn(a, b);
  } else if (aStatus.cancelled) {
    return 1;
  } else if (bStatus.cancelled) {
    return -1;
  }

  return (
    (aStatus.percentages['C']?.roundedPercentage || 0) -
      (bStatus.percentages['C']?.roundedPercentage || 0) ||
    (aStatus.percentages['W']?.roundedPercentage || 0) -
      (bStatus.percentages['W']?.roundedPercentage || 0) ||
    fallbackNameSortFn(a, b)
  );
};

const completionDateSortFn = (a: Row<HandinRow>, b: Row<HandinRow>) => {
  if (a.original.homeworkOff && b.original.homeworkOff) {
    return fallbackNameSortFn(a, b);
  } else if (a.original.homeworkOff) {
    return 1;
  } else if (b.original.homeworkOff) {
    return -1;
  }

  const aStatus = a.original.displayStatus;
  const bStatus = b.original.displayStatus;
  if (!aStatus && !bStatus) {
    return fallbackNameSortFn(a, b);
  } else if (!aStatus) {
    return 1;
  } else if (!bStatus) {
    return -1;
  }

  if (aStatus.cancelled && bStatus.cancelled) {
    return fallbackNameSortFn(a, b);
  } else if (aStatus.cancelled) {
    return 1;
  } else if (bStatus.cancelled) {
    return -1;
  }

  return (
    (a.original.summary.state?.completionTimestamp?.seconds || 0) -
      (b.original.summary.state?.completionTimestamp?.seconds || 0) || completionSortFn(a, b)
  );
};

const useCompletionSubjectFilter = () =>
  useQueryState(`hand-in-selected-subject`, Subject.SUBJECT_UNDEFINED);

const SubjectCompletionHeader = () => {
  const { sendEvent } = useClientEvent();
  const [completionSubject, _setCompletionSubject] = useCompletionSubjectFilter();
  const setCompletionSubject = (s: Subject) => {
    _setCompletionSubject(s);
    sendEvent(
      { action: 'change_completion_subject', category: 'hand-in' },
      {
        from: SubjectToName[completionSubject] || 'All',
        to: SubjectToName[s] || 'All',
      },
    );
  };

  return (
    <Flex direction={{ base: 'column', xl: 'row' }} alignItems="center">
      <Text mr={2} my={1} whiteSpace="pre">
        Completion for
      </Text>
      <Select
        value={completionSubject}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          setCompletionSubject(+e.target.value as Subject);
        }}
        width={40}
        my={{ base: 0, xl: -4 }}
      >
        <option value={Subject.SUBJECT_UNDEFINED}>All Subjects</option>
        <option value={Subject.BIOLOGY}>Biology</option>
        <option value={Subject.CHEMISTRY}>Chemistry</option>
        <option value={Subject.PHYSICS}>Physics</option>
      </Select>
    </Flex>
  );
};

const NAWithTooltip = ({ label }: { label?: string }) => (
  <Tooltip label={label} placement="top">
    <Text textAlign="center" color="gray.500">
      N/A
    </Text>
  </Tooltip>
);

const NACompletion = () => {
  const [completionSubject, _] = useCompletionSubjectFilter();
  return (
    <NAWithTooltip
      label={`No ${SubjectToName[completionSubject].toLowerCase()} questions were set this week`}
    />
  );
};

const NACancelled = () => <NAWithTooltip label="Homework cancelled" />;
const NANoHomework = () => (
  <NAWithTooltip label="This student didn't receive homework for this week" />
);

const useColumns = (
  assignmentID: string,
  splitCompletionBySubject: boolean,
  prevWeeks: { id: string; title: string }[],
  usingILXpTarget: boolean,
  assignmentLinker?: (assignmentID: string, userID?: string) => string,
) => {
  const columnHelper = useMemo(() => createColumnHelper<HandinRow>(), []);

  return useMemo(() => {
    const toAnsHist = (data: HandinRow) =>
      data.homeworkOff ? '' : answerHistoryUrlForUser(data.student?.studentId || '', assignmentID);

    return [
      columnHelper.accessor(row => row.student, {
        header: 'Name',
        sortingFn: (a, b) => {
          // Sort by family name, then given name
          const aName = `${a.original.student?.familyName} ${a.original.student?.givenName}`;
          const bName = `${b.original.student?.familyName} ${b.original.student?.givenName}`;
          return aName.localeCompare(bName);
        },
        meta: {
          headerCellProps: {
            borderRightWidth: 1,
          },
          bodyCellProps: {
            borderRightWidth: 1,
            className: styles.HoverLinkUnderline,
          },
          linkTo: (data: HandinRow) => ({
            to: `/teacher/student/${data.student?.studentId}/summary`,
            options: { state: { back: `${window.location.pathname}?${window.location.search}` } },
          }),
        },
        cell: info => (
          <ChakraLink
            as={Link}
            to={`/teacher/student/${info.row.original.student?.studentId}/summary`}
            state={{ back: `${window.location.pathname}?${window.location.search}` }}
            onClick={e => e.stopPropagation()}
            id={`user-${info.row.original.student?.studentId}`}
          >
            <StudentName student={info.getValue()} />
          </ChakraLink>
        ),
      }),
      columnHelper.accessor(
        row =>
          row.homeworkOff ? undefined : row.displayStatus?.percentages.C.roundedPercentage || 'N/A',
        {
          header: () => {
            if (!splitCompletionBySubject) {
              return <Text>Completion</Text>;
            }
            return <SubjectCompletionHeader />;
          },
          id: 'completion',
          meta: {
            headerCellProps: {
              borderRightWidth: splitCompletionBySubject ? 1 : undefined,
            },
            bodyCellProps: {
              borderRightWidth: splitCompletionBySubject ? 1 : undefined,
              className: styles.AnswersLink,
            },
            linkTo: toAnsHist,
          },
          sortingFn: completionSortFn,
          sortUndefined: 'last',
          cell: info => {
            if (info.row.original.homeworkOff) {
              return (
                <Text textAlign="center" color="gray.500">
                  Homework Off
                </Text>
              );
            }
            const status = info.row.original.displayStatus;
            if (!status) {
              return <NACompletion />;
            }
            return (
              <HStack spacing={2} as={Link} to={toAnsHist(info.row.original)}>
                <CompletionCell
                  percent={status.percentages['C'].roundedPercentage}
                  late={info.row.original.status.late}
                />
                <ProgressBar
                  showTooltip={true}
                  values={[
                    {
                      value: status.percentages['C'].roundedPercentage,
                      color: 'green.400',
                      title: 'Complete',
                    },
                    {
                      value: status.percentages['W'].roundedPercentage,
                      color: 'red.400',
                      title: 'Incomplete',
                    },
                    {
                      value: status.percentages['U'].roundedPercentage,
                      color: 'gray.100',
                      textColor: 'gray.500',
                      title: 'Unattempted',
                    },
                  ]}
                />
                {status.cancelled && (
                  <Tooltip label="Homework cancelled" placement="top">
                    <Box as="span" color="red.600">
                      <FontAwesomeIcon icon={faBan} fixedWidth />
                    </Box>
                  </Tooltip>
                )}
              </HStack>
            );
          },
        },
      ),
      columnHelper.accessor(
        row =>
          row.homeworkOff
            ? undefined
            : row.summary.state
              ? row.summary.state.questionTimeSeconds + row.summary.state.supportTimeSeconds
              : 0,
        {
          header: 'Working time',
          meta: {
            align: 'center',
            bodyCellProps: {
              className: styles.AnswersLink,
            },
            linkTo: toAnsHist,
          },
          sortUndefined: 'last',
          cell: info =>
            info.row.original.homeworkOff ? (
              <NANoHomework />
            ) : (
              toTimeSpentDuration(info.getValue()!)
            ),
        },
      ),
      columnHelper.accessor(
        row =>
          row.homeworkOff
            ? undefined
            : getCompletionDateDays(
                row.assignment!.endTimestamp!,
                row.summary.state?.completionTimestamp,
              ),
        {
          sortingFn: completionDateSortFn,
          sortUndefined: 'last',
          header: 'Completion day',
          meta: {
            align: 'center',
            bodyCellProps: {
              className: styles.AnswersLink,
            },
            linkTo: toAnsHist,
          },
          cell: info =>
            info.row.original.homeworkOff ? (
              <NANoHomework />
            ) : info.row.original.displayStatus?.cancelled &&
              !info.row.original.summary.state?.completionTimestamp ? (
              <NACancelled />
            ) : (
              <Text color={getCompletionDateColour(info.getValue())}>
                {getCompletionDateText(info.getValue())}
              </Text>
            ),
        },
      ),
      columnHelper.display({
        header: 'Answers',
        meta: {
          align: 'center',
          bodyCellProps: {
            className: classNames(styles.AnswersLink, styles.HoverLinkUnderline),
          },
          linkTo: toAnsHist,
        },
        cell: info =>
          info.row.original.homeworkOff ? (
            <NANoHomework />
          ) : (
            <Button
              as={Link}
              my={-2}
              size="sm"
              to={toAnsHist(info.row.original)}
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              colorScheme="buttonTeal"
              variant="ghost"
              onClick={e => e.stopPropagation()}
              _hover={{
                bg: 'none',
              }}
            >
              View
            </Button>
          ),
      }),
      columnHelper.accessor(row => row.ilValue || 0, {
        id: 'independentlearning',
        header: () => (
          <HStack justifyContent="center" spacing={0}>
            <Text>IL {usingILXpTarget && 'Challenge'}</Text>
            <InfoTooltip
              text={
                usingILXpTarget
                  ? 'Completion of their weekly Independent Learning Challenge'
                  : 'Time spent working on Independent Learning between homework hand-out and hand-in'
              }
              color="white"
              ml={1}
            />
          </HStack>
        ),
        meta: {
          align: 'center',
          headerCellProps: {
            borderLeftWidth: 1,
          },
          bodyCellProps: {
            borderLeftWidth: 1,
            className: styles.HoverLinkUnderline,
          },
          linkTo: (data: HandinRow) => ({
            to: `/teacher/student/${data.student?.studentId}/summary#activity-feed`,
            options: { state: { back: `${window.location.pathname}?${window.location.search}` } },
          }),
        },
        cell: info => (
          <ChakraLink
            as={Link}
            to={`/teacher/student/${info.row.original.student?.studentId}/summary#activity-feed`}
            state={{ back: `${window.location.pathname}?${window.location.search}` }}
            onClick={e => e.stopPropagation()}
            fontWeight={usingILXpTarget && info.getValue() === 100 ? 'bold' : undefined}
            color={
              info.getValue() == 0
                ? 'gray.500'
                : usingILXpTarget
                  ? info.getValue() === 100
                    ? 'green.500'
                    : 'blue.800'
                  : undefined
            }
          >
            {usingILXpTarget ? (
              <>
                {info.getValue()}%
                {info.getValue() === 100 && (
                  <>
                    {' '}
                    <FontAwesomeIcon icon={faCheck} />
                  </>
                )}
              </>
            ) : (
              toTimeSpentDuration(info.getValue())
            )}
          </ChakraLink>
        ),
      }),
      ...(prevWeeks.length > 0
        ? [
            columnHelper.group({
              header: 'Previous completion',
              meta: {
                align: 'center',
                headerCellProps: {
                  borderLeftWidth: 1,
                },
                bodyCellProps: {
                  borderLeftWidth: 1,
                },
              },
              columns: prevWeeks.map((week, i) =>
                columnHelper.accessor(row => row?.prevWeeks?.[i], {
                  id: `prev-week-${week}-${i}`,
                  header: () =>
                    assignmentLinker ? (
                      <ChakraLink
                        as={Link}
                        to={assignmentLinker(week.id)}
                        onClick={e => e.stopPropagation()}
                      >
                        {week.title}
                      </ChakraLink>
                    ) : (
                      week.title
                    ),
                  enableSorting: false,
                  meta: {
                    align: 'center',
                    headerCellProps: {
                      pl: i > 0 ? 0 : 3,
                      pr: i < prevWeeks.length - 1 ? 0 : 3,
                      borderLeftWidth: i == 0 ? 1 : undefined,
                    },
                    bodyCellProps: {
                      pl: i > 0 ? 0 : 3,
                      pr: i < prevWeeks.length - 1 ? 0 : 3,
                      borderLeftWidth: i == 0 ? 1 : undefined,
                    },
                  },
                  cell: info => {
                    const status = info.getValue();
                    if (!status) {
                      return <NANoHomework />;
                    }
                    if (status.cancelled) {
                      return <NACancelled />;
                    }
                    return (
                      <Flex justifyContent="center">
                        <CompletionCell
                          link={
                            assignmentLinker &&
                            assignmentLinker(week.id, info.row.original.student?.studentId || '')
                          }
                          percent={status.percentages['C'].roundedPercentage}
                        />
                      </Flex>
                    );
                  },
                }),
              ),
            }),
          ]
        : []),
    ];
  }, [
    columnHelper,
    assignmentID,
    splitCompletionBySubject,
    prevWeeks,
    usingILXpTarget,
    assignmentLinker,
  ]);
};

const useRows = (
  assignment: Assignment | undefined,
  studentLookup: Record<string, Student | undefined>,
  summaries: ListPackageSummariesResponse | undefined,
  ilLookup: Map<string, number>,
  completionSubject: Subject,
  prevSummaries: UseQueryResult<ListPackageSummariesResponse, unknown>[],
) =>
  useMemo(() => {
    const studentNames = new Set<string>(summaries?.packageSummaries.map(s => s.studentName));
    assignment?.ignoredUsers.forEach(
      ig =>
        ig.reason === Assignment_IgnoredUser_Reason.HOMEWORK_OFF &&
        studentNames.add('students/' + ig.userId),
    );

    return (
      Array.from(studentNames).reduce<{
        rows: HandinRow[];
        missingStudents: number;
        hasSubjectSplit: boolean;
      }>(
        ({ rows, missingStudents, hasSubjectSplit }, studentName) => {
          const s = summaries?.packageSummaries.find(s => s.studentName === studentName);
          const student = studentLookup[studentName.replace('students/', '')];
          if (!student) {
            // Exclude non-active students
            return { rows, missingStudents: missingStudents + 1, hasSubjectSplit };
          }
          const ilValue = ilLookup.get(studentName);

          let displayStatus: CompletionSummary | undefined;
          let overallStatus: CompletionSummary | undefined;
          let subjectStatus: Map<Subject, CompletionSummary> = new Map();

          const homeworkOff = !s;
          if (!homeworkOff) {
            subjectStatus = new Map(
              s.state?.subjectCompletion.map(sc => [
                sc.subject,
                getCompletionSummary(sc.completion, undefined, s.cancelledTime),
              ]) || [],
            );

            overallStatus = getCompletionSummary(s.state?.completion, undefined, s.cancelledTime);
            displayStatus =
              completionSubject !== Subject.SUBJECT_UNDEFINED
                ? subjectStatus.get(completionSubject)
                : overallStatus;
          }

          const prevWeeks = prevSummaries.map(({ data: pSums }) => {
            const summary = pSums?.packageSummaries.find(p => p.studentName === studentName);
            return summary
              ? getCompletionSummary(summary.state?.completion, undefined, summary.cancelledTime)
              : undefined;
          });

          if (homeworkOff) {
            rows.push({
              student,
              ilValue,
              assignment,
              prevWeeks,
              homeworkOff: true,
            });
          } else {
            rows.push({
              student,
              ilValue,
              assignment,
              prevWeeks,
              homeworkOff: false,
              summary: s,
              status: overallStatus!,
              displayStatus,
            });
          }

          return {
            rows,
            missingStudents,
            hasSubjectSplit: hasSubjectSplit || subjectStatus.size > 0,
          };
        },
        { rows: [], missingStudents: 0, hasSubjectSplit: false },
      ) || { rows: [], missingStudents: 0, hasSubjectSplit: false }
    );
  }, [assignment, studentLookup, summaries, ilLookup, completionSubject, prevSummaries]);

export const HandinTable = ({ assignmentID, assignments, assignmentLinker }: HandinTableProps) => {
  const { data: assignment } = useAssignment(assignmentID, { suspense: true });
  const { data: studentsMap = {} } = useStudentsLookup({ suspense: true });

  const useILXpTarget = assignmentHasILTarget(assignment);

  const { data: ilSummaries } = useIndependentLearningSummaries(assignmentID, {
    suspense: true,
    enabled: !useILXpTarget,
  });
  const { data: ilXpTargetStates } = useILXpTargetStates(assignmentID, {
    suspense: true,
    enabled: useILXpTarget,
  });
  const { data: weeks } = useWeeks({ suspense: true });

  const [prevAssignmentIDs, previousWeeks] = useMemo(() => {
    const assignmentStartDate =
      assignment?.startTimestamp && Timestamp.toDate(assignment?.startTimestamp);

    const prev = assignments
      ? assignments
          .filter(
            a =>
              assignmentStartDate &&
              a.startTimestamp &&
              isBefore(Timestamp.toDate(a.startTimestamp), assignmentStartDate),
          )
          .sort((a, b) => (a.startTimestamp?.seconds || 0) - (b.startTimestamp?.seconds || 0))
          .slice(-5) // Last 5 assignments
          .map(a => ({
            assignment: a,
            week: weeks?.find(
              w => a.startTimestamp && dateInWeek(w, Timestamp.toDate(a.startTimestamp)),
            ),
          }))
      : [];

    return [
      prev.map(v => v.assignment.name.split('/')[1]),
      prev.map(v => ({
        id: v.assignment.name.split('/')[1],
        title: 'W' + (v.week ? v.week?.index.toString() : '?'),
      })),
    ];
  }, [assignments, assignment, weeks]);

  const [{ data: summaries }, ...prevSummaries] = useBatchPackageSummaries(
    [assignmentID, ...prevAssignmentIDs],
    { suspense: true },
  );

  const [completionSubject, _setCompletionSubject] = useCompletionSubjectFilter();

  const ilLookup = useMemo(
    () =>
      new Map(
        useILXpTarget
          ? ilXpTargetStates?.map(s => [
              s.studentName,
              getRoundedPercentage((s.xpEarned * 100) / (s.targetXp || 1)),
            ])
          : ilSummaries?.summaries.map(s => [s.studentName, s.totalSeconds]) || [],
      ),
    [useILXpTarget, ilXpTargetStates, ilSummaries],
  );

  const { rows, missingStudents, hasSubjectSplit } = useRows(
    assignment,
    studentsMap,
    summaries,
    ilLookup,
    completionSubject,
    prevSummaries,
  );

  const columns = useColumns(
    assignmentID,
    hasSubjectSplit,
    previousWeeks,
    useILXpTarget,
    assignmentLinker,
  );

  // If we have a user in the search params, highlight the row and scroll to it.
  // The highlighting will fade after 5 seconds.
  const [highlightedUser, setHighlightedUser] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const userID = searchParams.get('user');
    if (userID) {
      setHighlightedUser(userID);
      setSearchParams(prev => {
        prev.delete('user');
        return prev;
      });
    }
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHighlightedUser(undefined);
    }, 5000);
    const scrollTimeout = setTimeout(() => {
      document
        .getElementById(`user-${highlightedUser}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 50);
    return () => {
      clearTimeout(timeout);
      clearTimeout(scrollTimeout);
    };
  }, [highlightedUser]);

  const compulsoryCompletion = useMemo(() => {
    const hasHwRows = rows.filter(r => !r.homeworkOff);
    const summaryRows = !assignment?.cancelledTime
      ? hasHwRows.filter(r => !r.status.cancelled)
      : hasHwRows;

    const completed = summaryRows.filter(
      r => r.status.percentages['C'].roundedPercentage >= 100,
    ).length;

    return { completed, total: summaryRows.length };
  }, [rows, assignment]);

  if (!assignment) {
    return <>Assignment not found</>; // TODO
  }

  return (
    <>
      <Flex px={2} mt={4} mx={-4} whiteSpace="nowrap" flexWrap="wrap" justifyContent="center">
        {assignment.startTimestamp && (
          <HStack spacing={3} mx={4} my={1}>
            <Text color="gray.500">
              <FontAwesomeIcon icon={faCalendar} />
            </Text>
            <Text fontWeight="bold">Set date/time:</Text>
            <Text>{format(Timestamp.toDate(assignment.startTimestamp), 'EEE do LLL p')}</Text>
          </HStack>
        )}
        {assignment.endTimestamp && (
          <HStack spacing={3} mx={4} my={1}>
            <Text color="gray.500">
              <FontAwesomeIcon icon={faCalendar} />
            </Text>
            <Text fontWeight="bold">Due date/time:</Text>
            <Text>{format(Timestamp.toDate(assignment.endTimestamp), 'EEE do LLL p')}</Text>
          </HStack>
        )}
        <HStack spacing={3} mx={4} my={1}>
          <Text color="gray.500">
            <FontAwesomeIcon icon={faCheck} />
          </Text>
          <Text fontWeight="bold">Compulsory completion:</Text>
          <Text>
            {compulsoryCompletion.completed}/{compulsoryCompletion.total}
          </Text>
        </HStack>
      </Flex>
      <CancelledBanner assignment={assignment} summaries={summaries} studentsMap={studentsMap} />
      <DataTable
        mt={4}
        data={rows}
        columns={columns}
        defaultSort={[{ id: `completion`, desc: false }]}
        className={styles.HandInTable}
        rowIsHighlighted={row =>
          row.student?.studentId === highlightedUser ? 'blue.50' : undefined
        }
      />
      {missingStudents > 0 && (
        <Text textAlign="center" mt={4} fontSize="sm">
          {missingStudents} student{missingStudents != 1 ? 's' : ''} who{' '}
          {missingStudents != 1 ? 'were' : 'was'} originally set this homework no longer exists in
          Sparx, they are excluded from the data above.
        </Text>
      )}
    </>
  );
};

const answerHistoryUrlForUser = (userID: string, assignmentID: string) =>
  `/teacher/handin/answers?${queryString.stringify({
    assignment: assignmentID,
    user: userID,
  })}`;

interface CompletionCellProps {
  percent: number;
  late?: boolean;
  link?: string;
}

const CompletionCell = ({ percent, late, link }: CompletionCellProps) => {
  const complete = percent >= 100;

  let additional = {};
  if (link) {
    additional = {
      as: Link,
      to: link,
      _hover: {
        background: complete ? 'green.100' : late ? 'red.100' : 'gray.100',
        cursor: 'pointer',
      },
    };
  }

  return (
    <Box
      {...additional}
      width={10}
      height={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      my={-3}
      borderRadius="sm"
      flex="0 0 auto"
    >
      {complete ? (
        <Text color="green">
          <FontAwesomeIcon icon={faCheck} />
        </Text>
      ) : (
        <Text color={late ? 'red' : 'blue.800'} fontSize="sm" fontWeight="bold">
          {percent}%
        </Text>
      )}
    </Box>
  );
};

const CancelledBanner = ({
  assignment,
  summaries,
  studentsMap,
}: {
  assignment: Assignment;
  summaries?: ListPackageSummariesResponse;
  studentsMap: Record<string, Student | undefined>;
}) => {
  const students = useCancelledStudents(studentsMap, summaries);

  if (!assignment.cancelledTime && students.length === 0) {
    return null;
  }

  return (
    <CancelledWarning my={2} justifyContent="center">
      <Text>
        This homework has been{' '}
        <strong>
          cancelled
          {students.length > 0 && !assignment.cancelledTime && (
            <>
              {' '}
              for{' '}
              <InlineTextTooltip text={`${students.length} ${plural(students.length, 'student')}`}>
                <List>
                  {students.map((s, i) => (
                    <ListItem key={i}>
                      <StudentName student={s} />
                    </ListItem>
                  ))}
                </List>
              </InlineTextTooltip>
            </>
          )}
        </strong>
        .{' '}
        <ChakraLink
          textDecoration="underline"
          href={articleCancellingHomework}
          target="_blank"
          ml={2}
        >
          More info
        </ChakraLink>
      </Text>
    </CancelledWarning>
  );
};
