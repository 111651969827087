import { useUserType } from 'api/sessions';
import { useBackLink } from 'app/BackLink';
import { StudentNavigation } from 'app/StudentNavigation';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { IndependentLearningContextProvider } from './Context';
import { CreateATask } from './CreateATask';
import { PreviousTasks } from './PreviousTasks';

export const IndependentLearningView = () => {
  // Show a back button to the teacher interface if the user is a teacher
  const { isTeacher } = useUserType();
  useBackLink(isTeacher ? '/teacher' : undefined);
  return (
    <StudentNavigation>
      <Routes>
        <Route path="/" element={<IndexView />} />
        <Route path="/previoustasks" element={<PreviousTasks />} />
      </Routes>
    </StudentNavigation>
  );
};

const IndexView = () => (
  <IndependentLearningContextProvider>
    <CreateATask />
  </IndependentLearningContextProvider>
);
