import { Box, Text } from '@chakra-ui/react';
import { ProgressBar, Stack, StackDirection } from '@sparx/sparx-design/components';
import { useUserXpState } from 'api/xp';
import { StudentNavigation } from 'app/StudentNavigation';
import { Hide, useBreakpointValue } from 'components/chakraExports';
import { PageContainer } from 'components/PageContainer';
import { PageTitle } from 'components/pagetitle/PageTitle';
import { LevelBadge } from 'components/xp/Badges/Badge';
import { getLevelProgress, xpPerLevel } from 'components/xp/utils';

import { MilestoneProgressBar } from './milestone-progress-bar/MilestoneProgressBar';
import styles from './XpView.module.css';

export const XpView = () => {
  const dir = useBreakpointValue<StackDirection>({ base: 'column', md: 'row' });
  const { data: { currentXp, lastLeveledTimestamp } = { currentXp: 0 } } = useUserXpState();
  const { level, levelCompletion, xpToNext } = getLevelProgress(currentXp);

  return (
    <StudentNavigation>
      <PageContainer maxWidth="3xl">
        <PageTitle title={'Sparx Science Rewards'} />
        <Box>
          <Stack direction="column" spacing={5}>
            {/* level badge and xp bar */}
            <Stack spacing={5} direction={dir} align="center">
              <LevelBadge level={level} maxW={175} />
              <Stack direction="column" style={{ width: '100%' }}>
                <Stack justify="space-between" align="baseline" spacing={4}>
                  {/* We wrap the text in boxes, and give the text inline-block to get the base line to be the last line when it wraps. */}
                  <Box>
                    <Text
                      color="green.500"
                      fontWeight="bold"
                      whiteSpace={'nowrap'}
                      display="inline-block"
                    >
                      <Text as="span" fontSize={{ base: '2xl', md: '3xl' }}>
                        {(currentXp * 1).toLocaleString('en-GB')}
                      </Text>{' '}
                      XP
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize={{ base: 'xs', sm: 'sm' }}
                      textAlign="right"
                      display="inline-block"
                    >
                      {xpToNext.toLocaleString('en-GB')} <Hide below="sm">more</Hide> XP to{' '}
                      <Text as="span" whiteSpace="nowrap">
                        Level {level + 1}
                      </Text>
                    </Text>
                  </Box>
                </Stack>
                <Box marginTop={-4}>
                  <ProgressBar
                    animateFill
                    animateScale={false}
                    percentComplete={levelCompletion * 100}
                    containerClassName={styles.ProgressBarContainer}
                  />
                </Box>
              </Stack>
            </Stack>
            <MilestoneProgressBar xp={currentXp} lastLeveledTimestamp={lastLeveledTimestamp} />
            {/* info */}
            <Box
              bg="white"
              as="section"
              borderRadius="lg"
              fontSize="large"
              color="blue.800"
              padding={5}
              boxShadow="lg"
            >
              <Stack spacing={2} direction="column">
                <Text fontWeight="bold">What are Sparx Science Experience Points (XP)?</Text>
                <Text>
                  You earn XP by completing tasks in your Homework and you reach new Sparx Science
                  Levels as you earn more XP.
                </Text>
                <Text>
                  You reach a new Sparx Science Level every {xpPerLevel.toLocaleString('en-GB')} XP.
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </PageContainer>
    </StudentNavigation>
  );
};
