import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assignment, AssignmentSummary } from '@sparx/api/apis/sparx/science/packages/v1/planner';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { createColumnHelper } from '@tanstack/react-table';
import { useAssignments, useAssignmentSummaries } from 'api/planner';
import { useGroups, useSortedGroups, useYearGroups } from 'api/school';
import { useCurrentStaffUser } from 'api/staff';
import { useClientEvent } from 'components/ClientEventProvider';
import { LargeLoading } from 'components/loading/LargeLoading';
import { ProgressBar } from 'components/progressbar/ProgressBar';
import { DataTable } from 'components/table/DataTable';
import { FixedHeightTableContainer } from 'components/table/FixedHeightTableContainer';
import { UntilTimestamp } from 'components/timestamp/UntilTimestamp';
import { isAfter, isBefore, roundToNearestMinutes } from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { plural } from 'utils/plural';

interface AssignmentRow {
  assignment: Assignment;
  summary?: AssignmentSummary;
}

const defaultCompletionValues = {
  totalCount: 1, // stop div 0
  startedCount: 0,
  completeCount: 0,
};

type ClassSelectionOption = 'assigned' | 'all' | undefined;

export const HandinTable = () => {
  const navigate = useNavigate();

  const { data: assignments = [] } = useAssignments({ suspense: false });

  const { data: allGroups, isLoading, isError } = useGroups({ suspense: false });
  const scienceGroups = useSortedGroups(allGroups || []);
  const [groups, setGroups] = useState<Group[]>([]);
  const groupLookup: Record<string, Group> = useMemo(
    () => groups.reduce((acc, group) => ({ ...acc, [group.name]: group }), {}),
    [groups],
  );
  const [selection, setSelection] = useState<ClassSelectionOption>('assigned');

  const allActiveAssignments = useMemo(() => {
    const now = new Date();
    return Object.values(
      assignments.reduce<Record<string, Assignment>>((acc, a) => {
        // Don't show assignments without timestamps (including ungenerated) or cancelled
        if (!a.startTimestamp || !a.endTimestamp || !a.generatedTimestamp || a.cancelledTime)
          return acc;

        // If not assigned to a group, don't show in the table
        if (a.groups.length === 0) return acc;

        const startDate = Timestamp.toDate(a.startTimestamp);
        const endDate = Timestamp.toDate(a.endTimestamp);

        // only include active assignments
        if (!(isBefore(startDate, now) && isAfter(endDate, now))) {
          return acc;
        }

        // Add this assignment if the group hasn't had one yet, or replce the previous one if this one ends sooner
        const exists = acc[a.groups[0].name];
        if (
          !exists ||
          isBefore(Timestamp.toDate(a.endTimestamp), Timestamp.toDate(exists.endTimestamp!))
        ) {
          acc[a.groups[0].name] = a;
        }
        return acc;
      }, {}),
    );
  }, [assignments]);

  // Filter the active assignments to just those for our selected groups
  const activeAssignments = useMemo(
    () => allActiveAssignments.filter(a => !!groupLookup[a.groups[0].name]),
    [allActiveAssignments, groupLookup],
  );

  const { data: summaries = [] } = useAssignmentSummaries(
    activeAssignments.map(a => a.name),
    {
      suspense: false,
      enabled: activeAssignments.length > 0,
    },
  );

  const assignmentRows: AssignmentRow[] = activeAssignments.map(assignment => ({
    assignment,
    summary: summaries.find(s => s.name === assignment.name),
  }));

  const columnHelper = createColumnHelper<AssignmentRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor(
        data =>
          data.assignment.groups
            .map(grp => groupLookup[grp.name]?.displayName || 'Unknown')
            .join(', '),
        {
          header: 'Class',
          cell: ({ cell }) => <Text fontWeight="bold">{cell.getValue()}</Text>,
        },
      ),
      columnHelper.accessor(
        data =>
          data.assignment.endTimestamp
            ? roundToNearestMinutes(Timestamp.toDate(data.assignment.endTimestamp))
            : 0,
        {
          id: 'dueDate',
          header: () => (
            <Text as="span" whiteSpace="nowrap">
              Due date
            </Text>
          ),
          cell: row => (
            <Text whiteSpace="nowrap">
              <UntilTimestamp>{row.row.original.assignment.endTimestamp}</UntilTimestamp>
            </Text>
          ),
        },
      ),
      columnHelper.accessor('summary.completion', {
        id: 'completion',
        header: 'Completion',
        meta: {
          width: '100%',
        },
        sortingFn: (a, b) => {
          const {
            totalCount: tca,
            completeCount: cca,
            startedCount: sca,
          } = a?.original.summary?.completion || defaultCompletionValues;
          const {
            totalCount: tcb,
            completeCount: ccb,
            startedCount: scb,
          } = b?.original.summary?.completion || defaultCompletionValues;

          const aVal = cca / tca;
          const bVal = ccb / tcb;
          if (aVal !== bVal) return aVal - bVal;

          const aVal2 = sca / tca;
          const bVal2 = scb / tcb;
          if (aVal2 !== bVal2) return aVal2 - bVal2;
          return 0;
        },
        cell: ({
          row: {
            original: {
              summary: {
                completion: { totalCount, startedCount, completeCount } = defaultCompletionValues,
              } = {},
            },
          },
        }) => (
          <ProgressBar
            showPercentages={true}
            showTooltip={true}
            values={[
              {
                value: (completeCount / totalCount) * 100,
                color: 'green.400',
                title: 'Complete',
                label: completeCount,
              },
              {
                value: ((startedCount - completeCount) / totalCount) * 100,
                color: 'yellow.400',
                title: 'Incomplete',
                label: startedCount - completeCount,
              },
              {
                value: ((totalCount - startedCount) / totalCount) * 100,
                color: 'gray.100',
                textColor: 'gray.500',
                title: 'Unattempted',
                label: totalCount - startedCount,
              },
            ]}
          />
        ),
      }),
      columnHelper.display({
        id: 'button',
        cell: () => (
          // Note there is no click behaviour here - the row click is handled by the table
          <Button size="sm" colorScheme="buttonTeal">
            View Hand-in
          </Button>
        ),
      }),
    ],
    [columnHelper, groupLookup],
  );

  return (
    <Box mb={4}>
      <Flex mb={2} alignItems="center">
        <Heading ml={1} flex={1} as="h3" size="md">
          Homework hand-in
        </Heading>
        <Flex
          alignItems="center"
          color="gray.700"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
        >
          <Flex alignItems="center" fontSize="sm">
            <Box bg="green.400" h={4} w={5} borderRadius="sm" mr={1} />
            <Text mr={4}>Complete</Text>
            <Box bg="yellow.400" h={4} w={5} borderRadius="sm" mr={1} />
            <Text mr={4}>Incomplete</Text>
            <Box bg="gray.200" h={4} w={5} borderRadius="sm" mr={1} />
            <Text mr={4}>Not started</Text>
          </Flex>
          <GroupMenu
            scienceGroups={scienceGroups}
            setGroups={setGroups}
            selection={selection}
            setSelection={setSelection}
            activeAssignments={allActiveAssignments}
          />
        </Flex>
      </Flex>
      <FixedHeightTableContainer maxHeight={400} overflowY="auto">
        <IncompleteSummaryBanner rows={assignmentRows} />

        <DataTable
          data={assignmentRows}
          columns={columns}
          getRowId={row => row.assignment.name}
          defaultSort={[
            { id: 'dueDate', desc: false },
            { id: 'completion', desc: false },
          ]}
          onRowClick={data => {
            // TODO: this currently assumes only one group ID - probably fine for now
            const groupID = data.assignment.groups[0]?.name.split('/studentGroups/')[1];
            const assignmentID = data.assignment.name.split('/')[1];
            navigate(
              `/teacher/handin?${queryString.stringify({
                assignment: assignmentID,
                group: groupID,
              })}`,
            );
          }}
          noDataRow={
            isLoading ? (
              <LargeLoading />
            ) : isError ? (
              <>Error loading data, please refresh and try again.</>
            ) : groups.length > 0 ? (
              <>No homework is currently live for the current selection.</>
            ) : scienceGroups.length == 0 ? (
              <>No classes available</>
            ) : selection === 'assigned' ? (
              <>
                You have no classes assigned. You can assign classes in{' '}
                <ChakraLink as={Link} to={'/teacher/group'} textDecoration="underline">
                  Class Manager
                </ChakraLink>{' '}
                or select &apos;All classes&apos; above.
              </>
            ) : (
              <>No year groups selected.</>
            )
          }
          compact={true}
        />
      </FixedHeightTableContainer>
    </Box>
  );
};

interface ActiveGroupCounts {
  assigned: number;
  all: number;
  yg: Record<string, number | undefined>;
}

const GroupMenu = ({
  scienceGroups,
  setGroups,
  selection,
  setSelection,
  activeAssignments,
}: {
  scienceGroups?: Group[];
  setGroups: (groups: Group[]) => void;
  selection: ClassSelectionOption;
  setSelection: (selection: ClassSelectionOption) => void;
  activeAssignments: Assignment[];
}) => {
  const { sendEvent } = useClientEvent();
  const { data: allYearGroups, isLoading } = useYearGroups({ suspense: false });
  const { data: staff } = useCurrentStaffUser();

  const [selectedYearGroups, setSelectedYearGroups] = useState<string[]>([]);

  const { assignedGroups, yearGroups, activeCounts } = useMemo(() => {
    const assignedGroups =
      scienceGroups?.filter(g =>
        g.staff.some(s => s.staffID === staff?.name.slice('staff/'.length)),
      ) || [];

    const activeCounts = activeAssignments.reduce<ActiveGroupCounts>(
      (acc, a) => {
        acc.all++;
        if (assignedGroups.find(g => g.name === a.groups?.[0].name)) {
          acc.assigned++;
        }
        const g = scienceGroups?.find(g => g.name === a.groups?.[0].name);
        if (g) {
          acc.yg[g.yearGroupId] = (acc.yg[g.yearGroupId] || 0) + 1;
        }
        return acc;
      },
      { assigned: 0, all: 0, yg: {} satisfies Record<string, number> },
    );

    return {
      assignedGroups,
      yearGroups:
        allYearGroups?.filter(yg => !!scienceGroups?.some(g => g.yearGroupId === yg.yearGroupID)) ||
        [],
      activeCounts,
    };
  }, [scienceGroups, staff, allYearGroups, activeAssignments]);

  const selectedYgsCount = useMemo(
    () => selectedYearGroups.reduce((acc, yg) => acc + (activeCounts.yg[yg] || 0), 0),
    [activeCounts, selectedYearGroups],
  );

  const setOnlyMyClasses = (firstLoad?: boolean) => {
    if (!firstLoad && selection === 'assigned') return;
    setSelection('assigned');
    setSelectedYearGroups([]);
    setGroups(assignedGroups);
    if (!firstLoad) {
      sendEvent(
        { category: 'dashboard', action: 'change_handin_table_classes' },
        {
          selection: 'assigned',
          groups: assignedGroups.map(g => g.name).join(','),
        },
      );
    }
  };

  const toggleYearGroup = (yearGroupID: string) => {
    if (selectedYearGroups.includes(yearGroupID)) {
      updateYearGroups(selectedYearGroups.filter(yg => yg !== yearGroupID));
    } else {
      updateYearGroups([...selectedYearGroups, yearGroupID]);
    }
  };

  const updateYearGroups = (yearGroupIDs: string[], suppressEvent?: boolean) => {
    setSelectedYearGroups(yearGroupIDs);
    setSelection(yearGroupIDs.length > 0 ? 'all' : undefined);
    const groups = scienceGroups?.filter(g => yearGroupIDs.includes(g.yearGroupId)) || [];
    setGroups(scienceGroups?.filter(g => yearGroupIDs.includes(g.yearGroupId)) || []);
    if (!suppressEvent) {
      sendEvent(
        { category: 'dashboard', action: 'change_handin_table_classes' },
        {
          selection: yearGroups.length === yearGroupIDs.length ? 'all' : 'partial',
          groups: groups.map(g => g.name).join(','),
          yearGroups: yearGroupIDs.join(','),
        },
      );
    }
  };

  useEffect(
    () => {
      if (assignedGroups.length > 0) {
        setOnlyMyClasses(true);
      } else {
        updateYearGroups(
          yearGroups.map(yg => yg.yearGroupID),
          true,
        );
      }
    },
    // We only really want this to run when the groups or yeargroups have first been loaded
    [scienceGroups, allYearGroups],
  );

  const MenuText = () => {
    if (selection === 'assigned') {
      return <>Only my classes ({assignedGroups.length})</>;
    }
    if (selectedYearGroups.length === yearGroups.length) {
      return <>All classes ({activeCounts.all})</>;
    }
    if (selectedYearGroups.length > 1) {
      return (
        <>
          {selectedYearGroups.length} {plural(selectedYearGroups.length, 'year')} selected (
          {selectedYgsCount})
        </>
      );
    }
    const yg = yearGroups.find(yg => yg.yearGroupID === selectedYearGroups[0]);
    if (yg) {
      return (
        <>
          {yg.name} ({activeCounts.yg[yg.yearGroupID] || 0})
        </>
      );
    }
    return <>No selection</>;
  };

  return (
    <Menu closeOnSelect={false} gutter={2}>
      <MenuButton
        as={Button}
        variant="outline"
        colorScheme="white"
        width={{ base: 44, md: 52 }}
        mb={{ base: 2, md: 0 }}
        backgroundColor="white"
        rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
        isLoading={!yearGroups || isLoading}
        size={{ base: 'sm', md: 'md' }}
        textAlign="left"
        isDisabled={scienceGroups?.length === 0}
      >
        <MenuText />
      </MenuButton>
      <MenuList zIndex={20}>
        <CheckMenuItem onClick={() => setOnlyMyClasses()} isChecked={selection === 'assigned'}>
          Only my classes ({activeCounts.assigned})
        </CheckMenuItem>
        <CheckMenuItem
          onClick={() =>
            yearGroups.length !== selectedYearGroups.length &&
            updateYearGroups(yearGroups.map(yg => yg.yearGroupID))
          }
          isChecked={selection === 'all'}
          isIndeterminate={selection === 'all' && yearGroups.length !== selectedYearGroups.length}
        >
          All classes ({activeCounts.all})
        </CheckMenuItem>
        <MenuDivider />

        {yearGroups?.map(yg => (
          <CheckMenuItem
            key={yg.yearGroupID}
            onClick={() => toggleYearGroup(yg.yearGroupID)}
            isChecked={selectedYearGroups.includes(yg.yearGroupID)}
          >
            {yg.name} ({activeCounts.yg[yg.yearGroupID] || 0})
          </CheckMenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const CheckMenuItem = ({
  onClick,
  isChecked,
  isIndeterminate,
  children,
}: React.PropsWithChildren<{
  onClick: () => void;
  isChecked: boolean;
  isIndeterminate?: boolean;
}>) => (
  <MenuItem
    onClick={e => {
      onClick();
      e.preventDefault();
    }}
  >
    <Checkbox mr={2} isChecked={isChecked} isIndeterminate={isIndeterminate}>
      {children}
    </Checkbox>
  </MenuItem>
);

const defaultZeroCompletionValues = {
  totalCount: 0,
  startedCount: 0,
  completeCount: 0,
};

const IncompleteSummaryBanner = ({ rows }: { rows: AssignmentRow[] }) => {
  const { totalCount, startedCount, completeCount, notStartedGroups, notCompleteGroups } =
    rows.reduce(
      (acc, row) => {
        if (!row.summary) return acc;

        const { totalCount, startedCount, completeCount } =
          row.summary.completion || defaultZeroCompletionValues;

        if (totalCount - startedCount > 0) acc.notStartedGroups.add(row.assignment.groups[0].name);
        if (totalCount - completeCount > 0)
          acc.notCompleteGroups.add(row.assignment.groups[0].name);

        acc.totalCount += totalCount;
        acc.startedCount += startedCount;
        acc.completeCount += completeCount;
        return acc;
      },
      {
        ...defaultZeroCompletionValues,
        notStartedGroups: new Set<string>(),
        notCompleteGroups: new Set<string>(),
      },
    );

  let message: React.ReactNode;
  const notStarted = totalCount - startedCount;
  const notComplete = totalCount - completeCount;

  if (totalCount === 0) {
    return null;
  } else if (notStarted > 0) {
    message = (
      <>
        {notStarted} student{notStarted !== 1 ? 's' : ''} in {notStartedGroups.size} class
        {notStartedGroups.size !== 1 ? 'es' : ''} {notStarted === 1 ? 'has' : 'have'}n't started
        their homework this week.
      </>
    );
  } else if (notComplete > 0) {
    message = (
      <>
        {notComplete} student{notComplete !== 1 ? 's' : ''} in {notCompleteGroups.size} class
        {notCompleteGroups.size !== 1 ? 'es' : ''} {notStarted === 1 ? 'has' : 'have'}n't completed
        their homework this week.
      </>
    );
  } else {
    message = <>All homework has been completed this week!</>;
  }

  return (
    <Flex
      backgroundColor="white"
      py={4}
      px={4}
      minHeight={16}
      alignItems="center"
      flexDirection={{ base: 'column', lg: 'row' }}
    >
      <Text
        flex={1}
        fontWeight="semibold"
        textAlign={{ base: 'center', md: totalCount > 0 ? 'left' : 'center' }}
      >
        {message}
      </Text>
      {totalCount > 0 && (
        <>
          <Box width={4} />
          <Box width="100%" flex={1} mt={{ base: 2, lg: 0 }}>
            <ProgressBar
              showPercentages={false}
              showTooltip={true}
              values={[
                {
                  value: (completeCount / totalCount) * 100,
                  color: 'green.400',
                  title: 'Complete',
                  label: completeCount,
                },
                {
                  value: ((startedCount - completeCount) / totalCount) * 100,
                  color: 'yellow.400',
                  title: 'Incomplete',
                  label: startedCount - completeCount,
                },
                {
                  value: ((totalCount - startedCount) / totalCount) * 100,
                  color: 'gray.100',
                  textColor: 'gray.500',
                  title: 'Unattempted',
                  label: totalCount - startedCount,
                },
              ]}
            />
          </Box>
        </>
      )}
    </Flex>
  );
};
