import {
  Box,
  Button,
  chakra,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  shouldForwardProp,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ProgressBar, Stack } from '@sparx/sparx-design/components';
import { useMediaQuery } from 'components/chakraExports';
import { CurrentUserDisplayName } from 'components/Names';
import { LevelBadge } from 'components/xp/Badges/Badge';
import { useXpContext } from 'components/xp/XpManager/context';
import { isValidMotionProp, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { useKeyPress } from 'utils/hooks/keypress';

import styles from './LevelUpDialog.module.css';
import RadialGradient from './Radial_Gradient.svg';

export const LevelUpDialog = () => {
  const isOnTeacherPage = location.pathname.startsWith('/teacher');
  const { showLevelUp, level, markLevelUpSeen } = useXpContext();

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
    onClose: () => {
      markLevelUpSeen(level);
      setConfettiThrown(false);
    },
  });

  useKeyPress(isOpen ? { Enter: () => onClose() } : {});

  // if we need to show the page, show it
  // never show it on a teacher page
  useEffect(() => {
    if (!!showLevelUp && !isOnTeacherPage) {
      onOpen();
    }
  }, [isOnTeacherPage, onOpen, showLevelUp]);

  const [confettiThrown, setConfettiThrown] = useState(false);

  const isMilestoneLevel = !!(level % 5 === 0);

  // The Confetti package checks to see when the active prop changes to true
  // to show confetti. We explicitly set it to true when the progress bar fills up,
  // and let it implicitly revert to false when the LevelUpDialog is remounted.
  const throwConfetti = useCallback(() => {
    setConfettiThrown(true);
  }, []);

  const confettiConfig = useMemo(() => generateConfettiConfig(level), [level]);

  const [gradientElement, setGradientElement] = useState<HTMLDivElement | null>(null);
  const [modalBodyElement, setModalBodyElement] = useState<HTMLDivElement | null>(null);
  const gradientCenter = useRef<{ x: number; y: number } | null>(null);

  const updatePosition = useCallback(() => {
    if (gradientElement) {
      const { left, right, top, bottom } = gradientElement.getBoundingClientRect();
      gradientCenter.current = { x: (left + right) / 2, y: (top + bottom) / 2 };
    }
  }, [gradientElement]);

  useEffect(() => {
    updatePosition();
    const aborter = new AbortController();
    modalBodyElement?.addEventListener('scroll', updatePosition, { signal: aborter.signal });
    window.addEventListener('resize', updatePosition, { signal: aborter.signal });
    return () => {
      aborter.abort();
    };
  }, [gradientElement, modalBodyElement, updatePosition]);

  const [smallHeight, smallWidth] = useMediaQuery(['(max-height: 400px)', '(max-width: 400px)']);
  const small = smallHeight || smallWidth;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size={'full'}
      scrollBehavior="inside"
    >
      <ModalContent h={'100%'} bg={'#1d4044f5'} overflow="hidden" position={'relative'}>
        {/* confetti lives outside body, as we don't want it to cause scroll  */}
        {isMilestoneLevel && (
          <Box position="absolute" left={gradientCenter.current?.x} top={gradientCenter.current?.y}>
            <Confetti active={confettiThrown} config={confettiConfig} />
          </Box>
        )}
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          ref={setModalBodyElement}
          zIndex={1}
        >
          {/* radial gradient */}
          <Box
            ref={setGradientElement}
            maxH="min(min(60vh, 500px),70vw)"
            height="100%"
            my={small ? '-20px' : '-50px'}
          >
            <MotionBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              position={'relative'}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              // @ts-expect-error type is the chakra transition, not the framer one, but functions correctly
              transition={{ duration: 0.8, delay: 0.8, ease: 'easeOut' }}
            >
              <MotionBox
                animate={{ rotate: [0, 360] }}
                // @ts-expect-error type is the chakra transition, not the framer one, but functions correctly
                transition={{
                  repeat: Infinity,
                  ease: 'linear',
                  duration: 30,
                  delay: 0.5,
                }}
              >
                <Image
                  src={RadialGradient}
                  maxH={'min(60vh, 500px)'}
                  maxW={'min(70vw, 500px)'}
                  w="100%"
                />
              </MotionBox>
              <MotionBox
                position={'absolute'}
                initial={{ scale: 0, rotate: -5, translateY: '-50%' }}
                animate={{
                  scale: 1,
                  rotate: 0,
                  translateY: '-50%',
                  transition: {
                    duration: 0.6,
                    delay: 1,
                    bounce: 0.7,
                    damping: 7,
                    type: 'spring',
                  },
                }}
                top={'52%'}
              >
                <LevelBadge level={level} maxW={'min(min(40vw, 250px),30vh)'} />
              </MotionBox>
            </MotionBox>
          </Box>
          {/* info */}
          <Stack direction="column" align="center" spacing={smallHeight ? 0.5 : 2}>
            <ProgressBar
              percentComplete={100}
              containerClassName={styles.ProgressBarContainer}
              animateFill
              animateWobble
              showHighlight
              onBarFillAnimateComplete={throwConfetti}
            />
            <Text
              fontSize={small ? 'x-large' : 'xx-large'}
              fontWeight="bold"
              color="green.200"
              textAlign="center"
            >
              You levelled up!
            </Text>
            <Text color="white" fontSize={small ? 'sm' : 'md'}>
              Great work, <CurrentUserDisplayName givenOnly />.
            </Text>
            <Button
              size={small ? 'sm' : 'md'}
              flex={'0 0 40px'}
              mt={small ? 2 : 5}
              colorScheme="buttonTeal"
              onClick={() => {
                onClose();
              }}
            >
              Continue
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const generateConfettiConfig = (level: number) => {
  const milestoneLevel = level % 100 === 0 ? 3 : level % 25 === 0 ? 2 : level % 5 === 0 ? 1 : 0;

  return {
    angle: 90,
    spread: 140,
    startVelocity: 30,
    elementCount: 50 * milestoneLevel ** 2,
    dragFriction: 0.06,
    duration: 20000,
    stagger: milestoneLevel * 2 - 1,
    width: '15px',
    height: '15px',
    perspective: '1000px',
    colors: ['#A4C451', '#2EFB99', '#F2AF2F', '#27CE88', '#B29500', '#EECF34', '#EECF34'],
  };
};

const MotionBox = chakra(motion.div, {
  shouldForwardProp: p => {
    if (isValidMotionProp(p)) {
      return true;
    }
    if (shouldForwardProp(p)) {
      return true;
    }
    return false;
  },
});
