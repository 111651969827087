import { Flex, Text, VStack } from '@chakra-ui/react';
import { forwardRef, PropsWithChildren } from 'react';

export const SmallFlashcard = forwardRef<
  HTMLParagraphElement,
  PropsWithChildren<{
    label: string;
    bg: string;
    gray?: boolean;
  }>
>(({ children, label, bg, gray }, ref) => {
  return (
    <VStack spacing={2} position="relative" h={32} w={24}>
      {gray && (
        // Could do this better...
        <Flex
          color={'#B5B5B5'}
          bg={'#F1F1F1'}
          justifyContent="center"
          alignItems="center"
          borderRadius="md"
          flexDirection="column"
          position="absolute"
          inset="0 0 0 0"
        >
          <Text fontWeight="bold" fontSize="lg" letterSpacing="-0.05rem">
            {label}
          </Text>
          <Text fontSize="4xl" fontWeight="bold">
            0
          </Text>
        </Flex>
      )}
      <Flex
        color={'white'}
        bg={bg}
        justifyContent="center"
        alignItems="center"
        borderRadius="md"
        flexDirection="column"
        position="absolute"
        inset="0 0 0 0"
        opacity={gray ? 0 : 1}
        transition={'opacity 0.3s'}
      >
        <Text fontWeight="bold" fontSize="lg" letterSpacing="-0.05rem">
          {label}
        </Text>
        <Text fontSize="4xl" fontWeight="bold" ref={ref}>
          {children}
        </Text>
      </Flex>
    </VStack>
  );
});
SmallFlashcard.displayName = 'SmallFlashcard';
