import { Button, GridItem, Stack, Text, useToast } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { HeaderField } from '../components/FormFields';
import { useUnlinkSSOAccount } from '../queries';
import { ContactEditModel } from '../utils';

export const UnlinkSSOAccountSection = () => {
  const { getValues, watch } = useFormContext<ContactEditModel>();
  const toast = useToast();
  const staffName = getValues('staffMember.name');
  const [givenName, familyName] = watch(['staffMember.givenName', 'staffMember.familyName']);
  const mutation = useUnlinkSSOAccount();

  const doUnlink = () =>
    mutation
      .mutateAsync(staffName)
      .then(() => {
        toast({
          title: 'Success',
          description: 'The SSO account for the staff member has been unlinked.',
          status: 'success',
          position: 'bottom-left',
          duration: 5000,
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Something went wrong whilst unlinking the staff members SSO account.',
          status: 'error',
          position: 'bottom-left',
          duration: 5000,
        });
      });

  return (
    <>
      <HeaderField name={`Unlink SSO account for ${givenName} ${familyName}`} />
      <GridItem colSpan={2}>
        <Text>
          Unlinking a staff member&apos;s SSO account will cause them to be re-matched on their next
          login. They will have to go through the email verification process again.
        </Text>
      </GridItem>
      <GridItem colSpan={2} display="flex">
        <Stack width="100%">
          <Button
            maxW={80}
            variant="outline"
            colorScheme="red"
            onClick={() => doUnlink()}
            isLoading={mutation.isLoading}
          >
            Unlink {givenName} {familyName}
          </Button>
        </Stack>
      </GridItem>
    </>
  );
};
