import { XpAward, XpAward_Reason } from '@sparx/api/apis/sparx/science/xp/v1/xp';
import { FeatureFlag, useBooleanFlag } from 'api/sessions';
import { useUserXpState } from 'api/xp';
import { LevelUpDialog } from 'components/xp/LevelUpDialog';
import { useIsLqd } from 'components/xp/utils';
import { XpContext } from 'components/xp/XpManager/context';
import { ReactNode, useCallback, useEffect, useState } from 'react';

export const XpManager = ({ children }: { children: ReactNode }) => {
  const xpEnabled = useBooleanFlag(FeatureFlag.Xp);

  // rqXpState is the xpState in the reactQueryCache. It is not necessarily up to date with the most recent award - we
  // update it manually as we progress through the end of task/package screens
  // default to level 0, although in practice this should never be undefined as the query is suspended
  const {
    refetch,
    status,
    fetchStatus,
    data: { level: rqLevel } = { level: undefined },
  } = useUserXpState();

  // awards is a list of all awards that have not yet been shown
  const [awards, setAwards] = useState<XpAward[]>([]);

  // shownLevel is the level that has been shown to the user in a level up dialog, or which was loaded at app initialization if
  // the user has not seen a level up dialog since refreshing
  const [shownLevel, setShownLevel] = useState(rqLevel);

  // if the query is disabled, but we have no data, force a refetch, and update the shown level
  useEffect(() => {
    if (xpEnabled && status === 'loading' && fetchStatus === 'idle') {
      refetch().then(r => setShownLevel(r.data?.level));
    }
  }, [fetchStatus, refetch, status, xpEnabled]);

  const addNewAwards = (newAwards: XpAward[]) => {
    setAwards(p => {
      return p.concat(newAwards);
    });
  };

  const getAwardsToShow = useCallback(
    (reason: XpAward_Reason) => {
      return awards.filter(a => a.reason === reason);
    },
    [awards],
  );

  const markAwardsAsShown = useCallback((reason: XpAward_Reason) => {
    setAwards(p => p.filter(a => a.reason !== reason));
  }, []);

  const isLqd = useIsLqd();

  //  if we aren't on an lqd path, mark all awards as shown
  useEffect(() => {
    if (!isLqd) {
      setAwards([]);
    }
  }, [isLqd]);

  // if we haven't seen a level, but its in react query state, show it
  const showLevelUp = shownLevel !== undefined && rqLevel !== undefined && rqLevel > shownLevel;

  // markLevelUpSeen marks a level as seen
  const markLevelUpSeen = (level: number) => {
    setShownLevel(level);
  };

  return (
    <XpContext.Provider
      value={{
        addNewAwards,
        getAwardsToShow,
        markAwardsAsShown,
        showLevelUp,
        level: rqLevel || 0,
        markLevelUpSeen,
      }}
    >
      <LevelUpDialog />
      {children}
    </XpContext.Provider>
  );
};
