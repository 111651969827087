import { useMediaQuery } from 'components/chakraExports';

// From https://www.joshwcomeau.com/shadow-palette/
export const cssShadowElevation = (elevation: 'low' | 'medium' | 'high', hslColour: string) => {
  switch (elevation) {
    case 'low':
      return `
        0px 0.7px 1px hsl(${hslColour} / 0),
        0px 1.3px 2px hsl(${hslColour} / 0.18),
        0px 2.5px 3.8px hsl(${hslColour} / 0.37)`;
    case 'high':
      return `
          0px 0.7px 1px hsl(${hslColour} / 0),
          0px 6.8px 10.2px hsl(${hslColour} / 0.09),
          0.1px 12.1px 18.2px hsl(${hslColour} / 0.18),
          0.1px 18.2px 27.3px hsl(${hslColour} / 0.26),
          0.1px 26.7px 40.1px hsl(${hslColour} / 0.35),
          0.2px 39px 58.5px hsl(${hslColour} / 0.44),
          0.3px 56.8px 85.2px hsl(${hslColour} / 0.53)`;
    case 'medium':
    default:
      return `
              0px 0.7px 1px hsl(${hslColour} / 0),
              0px 3.1px 4.7px hsl(${hslColour} / 0.15),
              0px 6.3px 9.5px hsl(${hslColour} / 0.29)`;
  }
};

// Returns true if the screen is bigger than 700px tall & 480px wide
export const useIsLargeScreen = () => {
  const [isLarge] = useMediaQuery('(min-height: 700px) and (min-width: 480px)');
  return isLarge;
};
