import { HintType } from '@sparx/api/apis/sparx/hints/v1/hints';

import { IElement, IGroupElement } from '../../question/types';
import { HintInfo } from './types';

// hintIsForGroup returns true if the passed hint refers to the passed group element
export const hintIsForGroup = (hintInfo: HintInfo | undefined, element: IElement) => {
  // check hint has refs
  const refs = hintInfo?.hint.inputRefs;
  if (!refs || refs.length === 0) {
    return false;
  }

  // check element is a group elements
  if (element.element !== 'group') {
    return false;
  }

  // check element is a fraction group if we're hinting to simplify a fraction
  if (hintInfo?.hint.hintType === HintType.HINT_SIMPLIFY_FRACTION && element.style !== 'fraction') {
    return false;
  }

  // check element contains all refs to which the hint refers
  if (!groupContainsAllNumberFieldRefs(element, refs)) {
    return false;
  }

  return true;
};

const groupContainsAllNumberFieldRefs = (group: IGroupElement, refs: string[]) => {
  const groupContainsRef = (ref: string) => {
    for (const element of group.content) {
      if (element.element === 'number-field' && element.ref === ref) {
        return true;
      }
    }
    return false;
  };
  return refs.every(ref => groupContainsRef(ref));
};
